import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import {
  Checks,
  BankTransfers,
  SavingsAccounts,
  FinancialCertificates,
  Expirations,
  Outlays,
  Cash,
} from "./Tables";
import { creditMethods } from "../../../utils/index";

import { AutocompleteField } from "../../SharedComponents/Fields";
import LoadingDialog from "../../../components/SharedComponents/LoadingScreen";
import {
  SuccessDialog,
  ErrorDialog,
  SimpleConfirmDialog,
} from "../../../components/SharedComponents/Dialogs";
import { errorHandling } from "../../../components/SharedComponents/CustomHooks";
import {
  fetchRohiSavingAccounts,
  fetchRohiFinancialCertificates,
} from "../../../utils/axios";

export default function Form(props) {
  // const rohiId = props.state.ticket.outlayInfo.clientIds.rohiId
  const rohiId = props.rohiId;
  const [loading, setLoading] = useState(false);
  const [simpleDialog, setSimpleDialog] = useState({
    name: "",
    data: null,
    callback: null,
  });

  const [difference, setDifference] = useState({ state: "", text: "" });
  const [expiration, setExpiration] = useState(props.state.expiration);
  const [outlay, setOutlay] = useState(props.state.outlay);
  const [savingsAccounts, setSavingsAccounts] = useState([]);
  const [financialCertificate, setFinancialCertificate] = useState([]);

  const financialCertificatesOptions = () => {
    const data = [];
    financialCertificate.forEach((opt) => {
      data.push({
        id: opt.numero_cuenta,
        balance_actual: opt.balance_actual,
        tipo_cuenta: opt.tipo_cuenta,
        fecha_apertura: opt.fecha_apertura,
      });
    });
    return data;
  };

  const savingsAccountOptions = () => {
    const data = [];
    savingsAccounts.forEach((opt) => {
      data.push({
        id: opt.numero_cuenta,
        balance_actual: opt.balance_actual,
        tipo_cuenta: opt.tipo_cuenta,
      });
    });
    return data;
  };

  const getSavingAccounts = () => {
    const endpoint = fetchRohiSavingAccounts({
      token: props.token,
      clientId: props.rohiId,
    });
    endpoint
      .then((response) => {
        setSavingsAccounts(response.data);
      })
      .catch((errors) => {
        errorHandling(errors, props.handleToggleSimpleDialog);
      });
  };

  const getFinancialCertificates = () => {
    const endpoint = fetchRohiFinancialCertificates({
      token: props.token,
      clientId: props.rohiId,
    });
    endpoint
      .then((response) => {
        setFinancialCertificate(response.data);
      })
      .catch((errors) => {
        errorHandling(errors, props.handleToggleSimpleDialog);
      });
  };

  const handleDataChange = (type, setUpdate, data) => {
    setUpdate(data);
    props.handleState(type, data);
  };

  const checkLength = (data) => {
    return Object.keys(data || {})?.length;
  }

  useEffect(() => {
    if (props.rohiId) {
      getSavingAccounts();
      getFinancialCertificates();
    }
  }, [props.rohiId]);

  useEffect(() => {
    // console.log(props.state.creditMethods);
    // console.log(creditMethods.filter(item => props.paymentTicket ? 
    //   !['expiration', 'outlay', 'financial_certificate'].includes(item.value) 
    //   : !['cash'].includes(item.value)));
    let list = [
      ...(props.state.checks || []),
      ...(props.state.bankTransfers || []),
      ...(props.state.savingsAccounts || []),
      ...(props.state.financialCertificates || []),
      ...(props.state.cash || []),
      { transferAmount: props.state.outlay.total },
      { transferAmount: props.state.expiration.total },
    ];
    validateAmount(list);
  }, [
    props.state.checks,
    props.state.bankTransfers,
    props.state.savingsAccounts,
    props.state.financialCertificates,
    props.state.cash,
    props.state.outlay,
    props.state.expiration,
  ]);
  useEffect(() => {
    // const outlayLength = Object.keys(props.state.outlay);
    // if(checkLength(props.state.outlay) checkLength(outlay))
    setExpiration(props.state.expiration);
    setOutlay(props.state.outlay);
  }, [props.state.outlay, props.state.expiration])

  const validateAmount = (newArr) => {
    const destinyConfig = newArr;

    const irregularAmount = props.state.requestAmount;

    const destinyTotal = destinyConfig.reduce((total, item) => {
      return (
        total + parseFloat(item.transferAmount === "" ? 0 : item.transferAmount)
      );
    }, 0);
    let difference = false;

    if (destinyConfig.length > 0) {
      difference =
        parseFloat(irregularAmount) < destinyTotal
          ? "exceed"
          : parseFloat(irregularAmount) > destinyTotal
          ? "lack"
          : false;
    }
    const text =
      difference === "exceed"
        ? "El total digitado excede el monto a cobrar"
        : difference === "lack"
        ? "El total digitado es inferior al monto a cobrar"
        : "";
    setDifference({ state: difference, text: text });
    return Boolean(difference);
  };

  const handleToggleSimpleDialog = (dialog, msg, data) => {
    const obj = { name: dialog ? dialog : "", msg: msg, data: data };
    setSimpleDialog({ ...obj });
  };
  return (
    <Grid container>
      <LoadingDialog open={loading} />
      <AutocompleteField
        xs={6}
        containerClass="field-container"
        fieldClass="fields"
        typeVariant="subtitle1"
        typeClass="field-label"
        fieldLabel="Método pago"
        fieldID="autocomplete-field-credit-methof-type"
        fieldVariant="outlined"
        fieldInnerLabel="Seleccione...."
        value={props.state.creditMethods}
        handleChange={(e, value) => props.handleState("creditMethods", value)}
        options={creditMethods.filter((item) =>
          props.paymentTicket
            ? !["expiration", "outlay", "financial_certificate"].includes(
                item.value
              )
            : !["cash"].includes(item.value)
        )}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => option.value === value.value}
        limitTags={3}
        fullWidth
        multiple={true}
        disabled={props.readOnly}
      />
      {props.state.creditMethods.find((x) => x.value === "check") ? (
        <Checks
          checks={props.state.checks || []}
          originAccounts={props.originAccounts}
          requestAmount={props.state.requestAmount}
          participantInfo={props.state.participantInfo}
          newClientFullName={props.state.newClientFullName}
          clientData={props.clientData}
          creditorAccounts={props.state.creditorAccounts}
          fromTicket={props.fromTicket}
          handleToggleSimpleDialog={handleToggleSimpleDialog}
          setLoading={setLoading}
          handleDelete={props.handleDelete}
          handleAdd={props.handleAdd}
          handleEdit={props.handleEdit}
          errorHandling={errorHandling}
          readOnly={props.readOnly}
          enableReference={props.enableReference}
          ticketCompleted={props.ticketCompleted}
          paymentTicket={props.paymentTicket}
        />
      ) : null}
      &nbsp;
      {props.state.creditMethods.find((x) => x.value === "internet_banking") ? (
        <BankTransfers
          bankTransfers={props.state.bankTransfers || []}
          handleDelete={props.handleDelete}
          handleAdd={props.handleAdd}
          handleEdit={props.handleEdit}
          destinyAccounts={props.destinyAccounts}
          originAccounts={props.originAccounts}
          clientAccounts={props.clientAccounts}
          readOnly={props.readOnly}
          enableReference={props.enableReference}
          ticketCompleted={props.ticketCompleted}
          paymentTicket={props.paymentTicket}
        />
      ) : null}
      &nbsp;
      {props.state.creditMethods.find((x) => x.value === "savings_account") ? (
        <SavingsAccounts
          savingsAccounts={savingsAccounts ? savingsAccountOptions() : []}
          currSavingsAccounts={props.state.savingsAccounts}
          handleDelete={props.handleDelete}
          handleReplace={props.handleReplace}
          handleEdit={props.handleEdit}
          readOnly={props.readOnly}
          ticketCompleted={props.ticketCompleted}
        />
      ) : null}
      &nbsp;
      {props.state.creditMethods.find(
        (x) => x.value === "financial_certificate"
      ) ? (
        <FinancialCertificates
          financialCertificates={
            financialCertificate ? financialCertificatesOptions() : []
          }
          currFinancialCertificates={props.state.financialCertificates}
          handleDelete={props.handleDelete}
          handleReplace={props.handleReplace}
          handleEdit={props.handleEdit}
          readOnly={props.readOnly}
          enableReference={props.enableReference}
          ticketCompleted={props.ticketCompleted}
        />
      ) : null}
      &nbsp;
      {(props.state.creditMethods.find((x) => x.value === "cash")) ? (
        <Cash
          cash={props.state.cash || []}
          handleDelete={props.handleDelete}
          handleAdd={props.handleAdd}
          handleEdit={props.handleEdit}
          readOnly={props.readOnly}
          enableReference={props.enableReference}
          ticketCompleted={props.ticketCompleted}
          paymentTicket={props.paymentTicket}
        />
      ) : null}
      &nbsp;
      {props.state.creditMethods.find((x) => x.value === "expiration") ? (
        <Expirations
          rohiId={rohiId || ""}
          uniqueId={props.clientId && props.clientId[0]}
          expiration={expiration}
          clientData={props.clientData}
          setExpiration={handleDataChange.bind(
            this,
            "expiration",
            setExpiration
          )}
          handleDelete={props.handleDelete}
          readOnly={props.readOnly}
          ticketCompleted={props.ticketCompleted}
          fromTicket={props.fromTicket}
        />
      ) : null}
      &nbsp;
      {props.state.creditMethods.find((x) => x.value === "outlay") ? (
        <Outlays
          rohiId={rohiId || ""}
          uniqueId={props.clientId && props.clientId[0]}
          outlay={outlay}
          clientData={props.clientData}
          setOutlay={handleDataChange.bind(this, "outlay", setOutlay)}
          handleDelete={props.handleDelete}
          readOnly={props.readOnly}
          ticketCompleted={props.ticketCompleted}
          fromTicket={props.fromTicket}
        />
      ) : null}
      {!rohiId ? (
        <p style={{ color: "#e74c3c" }}>
          El cliente no está registrado en Rohi
        </p>
      ) : null}
      &nbsp;
      {difference.state ? (
        <p style={{ color: "#e53935" }}>{difference.text}</p>
      ) : null}
      {simpleDialog.name === "simple-dialog" ? (
        <SimpleConfirmDialog
          open={true}
          handleToggle={handleToggleSimpleDialog}
          data={simpleDialog.msg}
        />
      ) : null}
      {simpleDialog.name === "success-dialog" ? (
        <SuccessDialog open={true} handleToggle={handleToggleSimpleDialog} />
      ) : null}
      {simpleDialog.name === "error-dialog" ? (
        <ErrorDialog
          open={true}
          handleToggle={handleToggleSimpleDialog}
          data={simpleDialog.msg}
        />
      ) : null}
    </Grid>
  );
}
