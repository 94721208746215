import { AxiosError } from "axios";
import * as React from "react";
import { connect } from "react-redux";
import AlertDialog from "src/components/AlertDialog";
import { catchError, successHandler, translations } from "src/utils";

import { Button } from "@material-ui/core";
import green from "@material-ui/core/colors/green";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AttachmentIcon from "@material-ui/icons/AttachFileOutlined";
import DeleteIcon from "@material-ui/icons/Delete";

import { deleteFileAttachment, fetchFileAttachments } from "../../../actions";
import {
  deleteFileAttachment as Delete,
  fetchFileAttachments as fetchFA,
  getFileAttachment,
} from "../../../utils/axios";
import FileUploadForm from "../FileUploadForm";
import { Visibility } from "@material-ui/icons";

const styles: any = createStyles({
  attachmentIcon: {
    color: "#4caf50",
    fontSize: 50,
    position: "absolute",
    transform: "rotate(180deg)",
  },
  attachmentIconButton: {
    height: 95,
    position: "absolute",
    right: 45,
    width: 10,
  },
  attachmentIconButtonTicket: {
    height: 95,
    position: "sticky",
    width: 10,
  },
  backButton: {
    "&:hover": {
      backgroundColor: green[200],
    },
    backgroundColor: green[500],
    color: "white",
    position: "absolute",
    right: 10,
    top: 10,
  },
  button: {
    "&:hover": {
      backgroundColor: green[200],
    },
    backgroundColor: green[500],
    color: "white",
  },
  label: {
    color: "#0000008a",
    position: "relative",
  },
  root: {
    marginBottom: 5,
    marginTop: 5,
  },
});

interface IFileAttachmentSelectInputProps {
  classes: {
    attachmentIcon: string;
    attachmentIconButton: string;
    attachmentIconButtonTicket: string;
    backButton: string;
    button: string;
    label: string;
    root: string;
  };
  contractId: string;
  description: string;

  url: string;
  user: IUser;
  deleteFileAttachment(uniqueId: string): IAction<IFileAttachment[]>;
  fetchFileAttachments(
    fileAttachments: IFileAttachment[]
  ): IAction<IFileAttachment[]>;
}

interface IFileAttachmentSelectInputState {
  alertDialog: boolean;
  dialogTitle: string;
  errorMessage: string;
  fileAttachments: IFileAttachment[];
  formDialog: boolean;
  isMounted: boolean;
  loading: boolean;
  open: boolean;
  uniqueId: string;
}

class FileAttachmentSelectInput extends React.Component<
  IFileAttachmentSelectInputProps,
  IFileAttachmentSelectInputState
> {
  public state: IFileAttachmentSelectInputState = {
    alertDialog: false,
    dialogTitle: "",
    errorMessage: "",
    fileAttachments: [],
    formDialog: false,
    isMounted: false,
    loading: false,
    open: false,
    uniqueId: "",
  };

  constructor(props: IFileAttachmentSelectInputProps) {
    super(props);

    this.findFiles = this.findFiles.bind(this);
    this.handleDeleteItem = this.handleDeleteItem.bind(this);
    this.switchDialogHandler = this.switchDialogHandler.bind(this);
    this.handleViewFile = this.handleViewFile.bind(this);
    this.switchFormDialog = this.switchFormDialog.bind(this);
    this.switchAlertDialogHandler = this.switchAlertDialogHandler.bind(this);
  }
  public switchAlertDialogHandler() {
    this.setState({ alertDialog: false });
  }

  public switchDialogHandler() {
    this.setState({ open: !this.state.open });
  }

  public switchFormDialog() {
    this.setState({ formDialog: !this.state.formDialog });
    this.componentDidMount();
  }

  public handleDeleteItem(id: string) {
    return (event: React.MouseEvent<HTMLElement>) => {
      Delete(this.props.user.token, id)
        .then((res) => {
          this.props.deleteFileAttachment(id);
          successHandler(res, this, translations.CONTRACT);
          this.componentDidMount();
        })
        .catch((err: AxiosError) => {
          catchError(err, this);
        });
    };
  }
  public handleGetFiles() {
    const params: IFetchFileAttachmentParams = {
      limit: 10,
      token: this.props.user.token,
    };
    if (this.props.contractId) {
      params["name"] = this.props.contractId;
    }
    fetchFA(params)
      .then((res) => {
        const data = res.data.results ? res.data.results : res.data;
        this.props.fetchFileAttachments(data);
        if (this.state.isMounted) {
          this.setState({
            fileAttachments: data as IFileAttachment[],
            loading: false,
          });
        }
      })
      .catch((err: AxiosError) => {
        if (this.state.isMounted) {
          catchError(err, this);
        }
      });
  }

  public componentWillUnmount() {
    this.setState({ isMounted: false });
  }
  public componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.contractId !== this.props.contractId) {
      this.handleGetFiles();
    }
  }

  public async componentDidMount() {
    this.setState({ loading: true, isMounted: true });
    this.handleGetFiles();
  }

  public handleViewFile(file: IFileAttachment) {
    if (file.uniqueId !== undefined) {
      const endpoint = getFileAttachment(this.props.user.token, file.uniqueId);
      endpoint
        .then((res) => {
          const dbuff = new Uint8Array(res.data.file).buffer;
          const data = new Blob([dbuff], { type: res.data.type });
          const fileURL = URL.createObjectURL(data);
          const NewWindow = window.open();
          NewWindow!.location.href = fileURL;
        })
        .catch((errors) => {
          console.log(errors);
        });
    }
  }

  public findFiles(uniqueId: string) {
    const curFile = this.state.fileAttachments?.filter(
      (files) =>
        (files.name ? files.name.includes(uniqueId) : undefined) &&
        (files.name ? files.name.includes(this.props.description) : undefined)
    );
    if (curFile) {
      return curFile.map((f, index) => {
        return (
          <TableRow key={index}>
            <TableCell>
              <p style={{ color: "#2a9c32" }}>
                {f.file ? f.file.split("/").pop() : ""}
              </p>
            </TableCell>
            <TableCell>
              <IconButton onClick={() => this.handleViewFile(f)}>
                <Visibility />
              </IconButton>
              <IconButton
                onClick={this.handleDeleteItem(f.uniqueId ? f.uniqueId : "")}
              >
                <DeleteIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        );
      });
    } else {
      return null;
    }
  }

  public render() {
    const { classes } = this.props;
    const { formDialog, open } = this.state;
    return (
      <div className={classes.root}>
        <AlertDialog
          open={this.state.alertDialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.switchAlertDialogHandler}
        />
        <Button
          className={
            this.props.description === "ticket-file"
              ? classes.attachmentIconButtonTicket
              : classes.attachmentIconButton
          }
          onClick={this.switchDialogHandler}
        >
          <AttachmentIcon className={classes.attachmentIcon} />
        </Button>
        <Dialog open={open} onClose={this.switchDialogHandler}>
          <DialogActions>
            <label className={classes.label}>Archivos</label>
            <IconButton
              className={classes.button}
              onClick={this.switchDialogHandler}
            >
              <ArrowBackIcon />
            </IconButton>
            <IconButton
              className={classes.button}
              onClick={this.switchFormDialog}
            >
              <AddIcon />
            </IconButton>
          </DialogActions>
          <Paper style={{ minWidth: 300 }}>
            <Table>
              <TableBody>{this.findFiles(this.props.contractId)}</TableBody>
            </Table>
          </Paper>
        </Dialog>
        <Dialog open={formDialog} onClose={this.switchFormDialog}>
          <DialogContent style={{ position: "relative", padding: 10 }}>
            <FileUploadForm
              contractId={this.props.contractId}
              url={this.props.url}
            />
          </DialogContent>
          <DialogActions style={{ margin: 0 }}>
            <IconButton
              className={classes.backButton}
              onClick={this.switchFormDialog}
            >
              <ArrowBackIcon />
            </IconButton>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, { deleteFileAttachment, fetchFileAttachments })(
    FileAttachmentSelectInput
  )
);
