import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { updateBlacklist } from "src/actions";
import Loading from "../../../components/SharedComponents/LoadingScreen";
import { errorHandling } from '../../../components/SharedComponents/CustomHooks';

import {
    fetchInternationalBlackLists,
    updateInternationalBlackList,
    deleteInternationalBlackList,
    uploadRecords,
    deleteFileAttachment
} from "src/utils/axios";

import { Paper, Typography, IconButton, Button, Toolbar, InputBase } from "@material-ui/core";

import { green } from "@material-ui/core/colors";
import {
    Visibility, AttachFile, Delete, Add, Replay, Edit
} from '@material-ui/icons';

import { GeneralTable } from './../../../components/SharedComponents/Table';
import TableContainer from "@material-ui/core/TableContainer";
import { Filters } from '../Filters'
import { PreviewDialog, ConfirmDialogComponent, EvidenceDialog, NationalityDialog, EditDialog } from '../Dialogs/index'
import { SuccessDialog, ErrorDialog, SimpleConfirmDialog } from '../../../components/SharedComponents/Dialogs';

function TableToolbar(props) {
    return (
        <Toolbar>
            <div style={{
                display: 'flex',
                alignItems: 'center'
            }}>
                <Typography
                    variant="subtitle1"
                    className="section-header"
                    style={{ fontWeight: 'bold', paddingLeft: 16 }}
                >
                    {(props.title || "Black list")}
                </Typography>
                <Button
                    onClick={props.refresh}
                    variant="contained"
                    style={{ backgroundColor: green[500], color: '#FFFFFF', fontSize: '12px', marginLeft: 10, display: 'inline-block' }}
                    disableElevation
                >
                    <Replay style={{ margin: ' 0 5 -4 -5', fontSize: '16px' }} />
                    Recargar tabla
                </Button>
            </div>
        </Toolbar>
    );
};

function InternationalBlackListView(props) {
    const [loading, setLoading] = useState(false);
    const [msg, setMsg] = useState('');
    const [dialog, setDialog] = useState({ name: '', data: null });
    const [simpleDialog, setSimpleDialog] = useState({ name: '', data: null, callback: null });
    const [file, setFile] = useState(null);
    const [blackListData, setblackListData] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState({});

    const handleToggleDialog = (dialog, data) => {
        const selectedDialog = dialog ? dialog : '';
        const dialogData = (data && (data.uniqueId || data.id)) ? data : null;
        const obj = { name: selectedDialog, data: dialogData };
        setDialog({ ...obj });
    }
    const handleToggleSimpleDialog = (dialog, msg, data) => {
        const obj = { name: dialog ? dialog : '', msg: msg, data: data };
        setSimpleDialog({ ...obj });
    }

    const evidenceActions = (row) => {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center'
            }}>
                <IconButton
                    disabled={row.newsEvidence ? false : true}
                    onClick={handleToggleDialog.bind(this, 'preview', row)}
                >
                    <Visibility style={{
                        fontSize: 22,
                        color: row.newsEvidence ? green[500] : '#616161'
                    }} />
                </IconButton>

                <IconButton
                    disabled={row.newsEvidence ? false : true}
                    onClick={
                        handleToggleSimpleDialog
                            .bind(this, 'confirm-dialog', 'proceed-delete', { callback: deleteEvidence.bind(this, row), row: row })
                    }                >
                    <Delete style={{
                        fontSize: 22,
                        color: row.newsEvidence ? green[500] : '#616161'
                    }} />
                </IconButton>

                <IconButton
                    disabled={row.newsEvidence ? true : false}
                    onClick={handleToggleDialog.bind(this, 'add-evidence', row)}
                >
                    <Add style={{
                        fontSize: 22,
                        color: row.newsEvidence ? '#616161' : green[500]
                    }} />
                </IconButton>
            </div>
        );
    }

    const removeFromList = (row) => {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center'
            }}>
                <IconButton
                    onClick={
                        handleToggleSimpleDialog
                            .bind(this, 'confirm-dialog', 'delete', { callback: deleteBL.bind(this, row), row: row })
                    }                >
                    <Delete style={{
                        fontSize: 22,
                        color: green[500]
                    }} />
                </IconButton>

                <IconButton
                    onClick={handleToggleDialog.bind(this, 'edit', row)}
                >
                    <Edit style={{
                        fontSize: 22,
                        color: green[500]
                    }} />
                </IconButton>
            </div>
        );
    }
    const columns = [
        {
            id: 'fullName',
            label: 'NOMBRE COMPLETO',
            minWidth: 200,
            align: 'center'
        },
        {
            id: 'identificationType',
            label: 'TIPO DE ID',
            minWidth: 160,
            align: 'center'
        },
        {
            id: 'identificationNumber',
            label: 'IDENTIFICACION',
            type: 'number',
            minWidth: 160,
            align: 'center'
        },
        {
            id: 'nationality',
            label: 'NACIONALIDAD',
            minWidth: 160,
            align: 'center'
        },
        {
            id: 'nickName',
            label: 'APODO',
            minWidth: 160,
            align: 'center'
        },
        {
            id: 'creationDate',
            label: 'FECHA DE CREACION',
            minWidth: 160,
            align: 'center'
        },
        {
            id: 'crimeCommitted',
            label: 'CRIMEN',
            minWidth: 200,
            align: 'center'
        },
        {
            id: 'newsLink',
            label: 'LINK',
            minWidth: 200,
            align: 'center'
        },
        {
            id: 'newsEvidence',
            label: 'EVIDENCIA',
            minWidth: 160,
            align: 'center',
            element: evidenceActions
        },
        {
            id: 'newsEvidence',
            label: 'ACCIONES',
            minWidth: 100,
            align: 'center',
            element: removeFromList
        },
    ];

    const getInternationalBlackListData = (data) => {
        setLoading(true);
        const endpoint = fetchInternationalBlackLists({ ...data, token: props.user.token })
        endpoint.then(response => {
            const blackListData = response.data.map(item => {
                return {
                    id: item.uniqueId,
                    fullName: item.fullName,
                    nickName: item.nickName ? item.nickName : '-',
                    identificationNumber: item.identificationNumber,
                    identificationType: item.identificationType === "national" ? 'Cédula'
                        : item.identificationType === "passport" ? 'Pasaporte' : item.identificationType,
                    nationality: item.nationality,
                    creationDate: item.creationDate,
                    referenceCode: item.referenceCode,
                    newsEvidence: item.newsEvidence,
                    crimeCommitted: item.crimeCommitted,
                    newsLink: item.newsLink ? item.newsLink : '-'
                }
            });
            setblackListData(blackListData)
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            console.error(err);
        });
    }

    const deleteBL = (IBL) => {
        setLoading(true);

        const endpoint = deleteInternationalBlackList(props.user.token, IBL.id);
        endpoint.then(() => {
            if (IBL.newsEvidence) {
                handleFileDelete(IBL.newsEvidence)
            }
            getInternationalBlackListData(selectedFilters);
            setLoading(false);
            handleToggleSimpleDialog('success-dialog');
        }).catch(errors => {
            setLoading(false);
            errorHandling(errors, handleToggleSimpleDialog);
        });
    }

    const handleFileDelete = (fileId) => {
        const endpoint = deleteFileAttachment(props.user.token, fileId);
        endpoint.then(() => {
            handleToggleSimpleDialog('success-dialog');
        }).catch(errors => {
            setLoading(false);
            errorHandling(errors, handleToggleSimpleDialog);
        });
    }

    const deleteEvidence = (IBL) => {
        setLoading(true);
        const newData = {
            newsEvidence: null
        }
        const endpoint = updateInternationalBlackList(props.user.token, IBL.id, newData);
        endpoint.then(() => {
            if (IBL.newsEvidence) {
                handleFileDelete(IBL.newsEvidence)
            }
            getInternationalBlackListData(selectedFilters);
            setLoading(false);
            
        }).catch(errors => {
            setLoading(false);
            errorHandling(errors, handleToggleSimpleDialog);
        });
    }

    const uploadExcel = (data) => {
        setLoading(true);
        let formData = new FormData();
        formData.append("file", data);
        const endpoint = uploadRecords
        endpoint(props.user.token, formData).then((response) => {
            getInternationalBlackListData(selectedFilters);
            setLoading(false);
            if (response.data.warning) {
                handleToggleSimpleDialog('simple-dialog', "El proceso fue ejecutado correctamente, pero: " + response.data.warning);
            } else {
                if (response.data.nationalityError) {
                    setMsg(response.data.nationalityError)
                    handleToggleSimpleDialog('confirm-dialog', 'add-nationality', { callback: handleToggleDialog.bind(this, 'add-nationality') })
                } else {
                    handleToggleSimpleDialog('success-dialog');
                }
            }
        }).catch(errors => {
            setLoading(false);
            errorHandling(errors, handleToggleSimpleDialog);
        });
    }

    useEffect(() => {
        getInternationalBlackListData()
    }, [])

    const applyFilters = (fields) => {
        let data = fields;
        getInternationalBlackListData(data);
        setSelectedFilters({ ...data });
    }

    const handleSaveBtn = () => {
        uploadExcel(file)
    }

    return (
        <div style={{ padding: '20ps, 20px,10px 20px' }}>
            <Paper style={{ padding: '20px' }}>
                <Typography variant="h3">BlackList nacional e internacional</Typography>
                <Loading open={loading} />
                <br />
                <br />
                <>
                    <Filters
                        applyFilters={applyFilters}
                    />
                    <div style={{ width: '55%', margin: 8 }}>
                        <input
                            type="file"
                            id='file'
                            style={{ display: 'none' }}
                            accept=".csv,.xlsx"
                            onChange={(event) => {
                                const file = event.target.files[0];
                                setFile(file)
                            }}
                        />
                        <div style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                            <div style={{ maxWidth: '65%', display: 'flex' }}>
                                <label htmlFor='file'>
                                    <Button
                                        variant="contained"
                                        style={{ backgroundColor: '#43A047', color: '#FFFFFF', fontSize: '12px', display: 'inline-block' }}
                                        component="span"
                                        disableElevation
                                    >
                                        <AttachFile style={{ margin: ' 0 5 -4 -5', fontSize: '16px' }} />
                                        Elegir
                                    </Button>
                                </label>

                                <InputBase
                                    value={file ? file.name : 'Seleccione un archivo'}
                                    style={{ color: '#616161', paddingLeft: 5, display: 'inline-block', width: '100%' }}
                                />
                                <div style={{ maxWidth: '35%', float: 'right' }}>
                                    <Button
                                        onClick={handleSaveBtn}
                                        variant="contained"
                                        style={{ backgroundColor: file ? '#43A047' : '#bdbdbd', color: '#FFFFFF', fontSize: '12px', marginLeft: 10, display: 'inline-block' }}
                                        disableElevation
                                        disabled={!file}
                                    >
                                        <Add style={{ margin: ' 0 5 -4 -5', fontSize: '16px' }} />
                                        cargar
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div style={{ height: 700, width: '100%', marginTop: 60 }}>
                        <TableContainer style={{ width: '100%' }}>

                            <GeneralTable
                                columns={columns}
                                data={blackListData}
                                toolbar={
                                    <TableToolbar
                                        title={"Black list"}
                                        refresh={getInternationalBlackListData}
                                    />
                                }
                            />
                        </TableContainer>
                    </div>
                    {
                        dialog.name === 'preview' ?
                            <PreviewDialog
                                open={true}
                                handleToggleDialog={handleToggleDialog}
                                data={dialog.data}
                                token={props.user.token}
                            /> : null
                    }
                    {
                        dialog.name === 'add-evidence' ?
                            <EvidenceDialog
                                open={true}
                                handleToggleDialog={handleToggleDialog}
                                handleSimpleDialog={handleToggleSimpleDialog}
                                data={dialog.data}
                                token={props.user.token}
                                refresh={getInternationalBlackListData}
                            /> : null
                    }
                    {
                        dialog.name === 'add-nationality' ?
                            <NationalityDialog
                                open={true}
                                handleToggleDialog={handleToggleDialog}
                            /> : null
                    }
                     {
                        dialog.name === 'edit' ?
                            <EditDialog
                                open={true}
                                handleToggleDialog={handleToggleDialog}
                                data={dialog.data}
                                token={props.user.token}
                                refresh={getInternationalBlackListData}
                            /> : null
                    }
                    {
                        simpleDialog.name === 'confirm-dialog' ?
                            <ConfirmDialogComponent
                                open={true}
                                data={simpleDialog}
                                handleToggle={handleToggleSimpleDialog}
                                msg={msg}
                            /> : null
                    }
                    {
                        simpleDialog.name === 'simple-dialog' ?
                            <SimpleConfirmDialog
                                open={true}
                                handleToggle={handleToggleSimpleDialog}
                                data={simpleDialog.msg}
                            /> : null
                    }
                    {
                        simpleDialog.name === 'success-dialog' ?
                            <SuccessDialog
                                open={true}
                                handleToggle={handleToggleSimpleDialog}
                            /> : null
                    }
                    {
                        simpleDialog.name === 'error-dialog' ?
                            <ErrorDialog
                                open={true}
                                handleToggle={handleToggleSimpleDialog}
                                data={simpleDialog.msg}
                            /> : null
                    }
                </>
            </Paper>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        internationalBlackList: state.internationalBlackList,
        user: state.user,
    };
}

export default connect(mapStateToProps, { updateBlacklist, })(InternationalBlackListView)
