import React, { useState, useEffect } from 'react';
import {
    Grid, Dialog, DialogContent, Button, Typography, 
    DialogTitle, IconButton, InputBase, DialogActions
} from '@material-ui/core';
import { AttachFile, Save } from '@material-ui/icons';
import { ConfirmDialog, ErrorDialog } from './../../../components/SharedComponents/Dialogs';
import { grey, green } from "@material-ui/core/colors";
import { CloseOutlined } from '@material-ui/icons';
import { getFileAttachment, createFileAttachment, updatePepsList } from "src/utils/axios";
import { SuccessDialog } from '../../../components/SharedComponents/Dialogs';
import Loading from "../../../components/SharedComponents/LoadingScreen";
import { errorHandling } from '../../../components/SharedComponents/CustomHooks';


function ConfirmDialogComponent(props) {
    const data = props.data.data;
    let dialogConfirmContent = '';
    switch (props.data.msg) {
        case 'file-upload':
            dialogConfirmContent = '¿Desea subir el archivo seleccionado?';
            break;
        case 'proceed-payment':
            dialogConfirmContent = '¿Desea realizar el pago especificado?';
            break;
        case 'proceed-delete':
            dialogConfirmContent =
                '¿Esta seguro que desea eliminar este archivo?';
            break;
        case 'delete':
            dialogConfirmContent =
                '¿Esta seguro que desea eliminar este registro?';
            break;
        default:
            dialogConfirmContent = '...';
    }
    return (
        <ConfirmDialog
            open={props.open}
            handleToggle={props.handleToggle}
            confirmCallback={data.callback}
            data={dialogConfirmContent}
        />
    )
}

function PreviewDialog(props) {
    const [url, setUrl] = useState('');
    const [simpleDialog, setSimpleDialog] = useState({ name: '', data: null, callback: null });

    const handleToggleSimpleDialog = (dialog, msg, data) => {
        const obj = { name: dialog ? dialog : '', msg: msg, data: data };
        setSimpleDialog({ ...obj });
    }

    const handleViewFile = () => {
        if (props.data.fileAttachment) {
            const endpoint = getFileAttachment(props.token, props.data.fileAttachment);
            endpoint.then((res) => {
                const dbuff = new Uint8Array(res.data.file).buffer
                const data = new Blob([dbuff], { type: res.data.type });
                const fileURL = URL.createObjectURL(data);
                setUrl(fileURL)
            }).catch(errors => {
                errorHandling(errors, handleToggleSimpleDialog);
            });
        }
    }

    useEffect(() => {
        handleViewFile();
    }, []);

    return (
        <div>
            <Dialog                
                fullWidth
                maxWidth="md"
                onClose={
                    (e, reason) => reason === 'backdropClick' && e.preventDefault()
                }
                className="dialog-container"
                open={props.open}
            >
                <DialogTitle style={{ backgroundColor: green[500] }}>
                    <Grid container justify="space-between" alignItems="center">
                        <Typography component={'span'} variant="h5" className="dialog-title">
                            Archivo adjunto
                        </Typography>
                        <IconButton onClick={props.handleToggleDialog.bind(this, undefined)}>
                            <CloseOutlined className="dialog-header-close-btn" />
                        </IconButton>
                    </Grid>
                </DialogTitle>
                <DialogContent style={{
                    margin: '2% 0',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>

                    <div style={{
                        id: 'preview',
                        width: '47%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: grey[200],
                        borderRadius: 20,
                        overflow: 'hidden',
                        margin: '10% 0px 0px',
                        minHeight: 500
                    }}>
                        <img
                            alt="fileAttachment"
                            src={url}
                            style={{ objectFit: "cover", maxWidth: '100%', minWidth: '-webkit-fill-available' }}
                        />
                    </div>
                </DialogContent>
            </Dialog>
            {
                simpleDialog.name === 'success-dialog' ?
                <SuccessDialog
                        open={true}
                        handleToggle={handleToggleSimpleDialog}
                    /> : null
                }
            {
                simpleDialog.name === 'error-dialog' ?
                <ErrorDialog
                    open={true}
                    handleToggle={handleToggleSimpleDialog}
                    data={simpleDialog.msg}
                    /> : null
                }
        </div>
    );
}

function FileAttachmentDialog(props) {
    const [image, setImage] = useState(null);
    const [preview, setPreview] = useState('');
    const [loading, setLoading] = useState(false);
    const [simpleDialog, setSimpleDialog] = useState({ name: '', data: null, callback: null });

    const handleToggleSimpleDialog = (dialog, msg, data) => {
        const obj = { name: dialog ? dialog : '', msg: msg, data: data };
        setSimpleDialog({ ...obj });
    }

    const setEvidence = (id) => {
        const newData = {
            fileAttachment: id
        }
        const endpoint = updatePepsList(props.token, props.data.id, newData);
        endpoint.then(() => {
            handleToggleSimpleDialog('success-dialog');
        }).catch(errors => {
            setLoading(false);
            errorHandling(errors, handleToggleSimpleDialog);
        });
    }

    const uploadFile = () => {
        setLoading(true);
        let formData = new FormData();
        const name = props.data.id
        formData.append("file", image);
        formData.append("name", name);
        const endpoint = createFileAttachment(props.token, formData);
        endpoint.then((response) => {
            setEvidence(response.data.uniqueId)
            props.refresh();
            setLoading(false);
        }).catch(errors => {
            setLoading(false);
            errorHandling(errors, handleToggleSimpleDialog);
        });
    }

    useEffect(() => {
        if (image) {
            setImage(image)
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
            };
            reader.readAsDataURL(image);
        } else {
            setPreview(null);
        }
    }, [image]);

    return (
        <div>
            <Loading open={loading} />
            <Dialog
                
                fullWidth
                maxWidth="md"
                onClose={
                    (e, reason) => reason === 'backdropClick' && e.preventDefault()
                }
                className="dialog-container"
                open={props.open}
            >
                <DialogTitle style={{ backgroundColor: green[500] }}>
                    <Grid container justify="space-between" alignItems="center">
                        <Typography component={'span'} variant="h5" className="dialog-title">
                            Cargar archivo adjunto
                        </Typography>
                        <IconButton onClick={props.handleToggleDialog.bind(this, undefined)}>
                            <CloseOutlined className="dialog-header-close-btn" />
                        </IconButton>
                    </Grid>
                </DialogTitle>
                <DialogContent style={{ margin: '2% 0' }}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div style={{
                            id: 'preview',
                            width: '47%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: grey[200],
                            borderRadius: 20,
                            overflow: 'hidden',
                            minHeight: 500
                        }}>
                            <img
                                alt="preview"
                                src={preview}
                                style={{ objectFit: "cover", maxWidth: '100%', minWidth: '-webkit-fill-available' }}
                            />
                        </div>
                    </div>
                    <div style={{ width: '100%', margin: 8 }}>
                        <input
                            type="file"
                            id='file1'
                            style={{ display: 'none' }}
                            accept="image/*"
                            onChange={(event) => {
                                const file = event.target.files[0];
                                setImage(file)
                            }}
                        />
                        <div style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                            <div style={{ maxWidth: '65%', display: 'inline-block' }}>
                                <label htmlFor='file1'>
                                    <Button
                                        variant="contained"
                                        style={{ backgroundColor: '#43A047', color: '#FFFFFF', fontSize: '12px', display: 'inline-block' }}
                                        component="span"
                                        disableElevation
                                    >
                                        <AttachFile style={{ margin: ' 0 5 -4 -5', fontSize: '16px' }} />
                                        Elegir
                                    </Button>
                                </label>

                                <InputBase
                                    value={image ? image.name : 'Seleccione un archivo'}
                                    style={{ color: '#616161', paddingLeft: 5, display: 'inline-block', width: '100%' }}
                                />
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        onClick={uploadFile}
                        style={{ backgroundColor: green[500], color: "white", marginLeft: 10 }}
                    >
                        <Save />
                        &nbsp;
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog>
            {
                simpleDialog.name === 'success-dialog' ?
                    <SuccessDialog
                        open={true}
                        handleToggle={handleToggleSimpleDialog}
                    /> : null
            }
        </div>
    );
}

export { PreviewDialog, ConfirmDialogComponent, FileAttachmentDialog }