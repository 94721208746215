
import * as React from "react";
import { connect } from "react-redux";
import FileUploadSelectInput from "src/containers/FileUpload/FileUploadSelectInput";

import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import DocumentIcon from "@material-ui/icons/FileCopyOutlined";
import SaveIcon from "@material-ui/icons/Save";
import Grid from "@material-ui/core/Grid";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

import {
  deleteInputCertificationContract,
  fetchInputCertificationContract,
  updateInputCertificationContract,
} from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import {
  catchError,
  filesGeneration,
  resetError,
  successHandler,
  translations,
} from "../../../utils";
import {
  deleteInputCertificationContract as deleteMA,
  fetchInputCertificationContract as fetchMA,
  updateInputCertificationContract as updateMA,
} from "../../../utils/axios";
import ParticipantSelectInput from "../../Participant/ParticipantSelectInput";
import MoneyInput from "src/components/TextMaskInputComponents/moneyInput";
import { MenuItem } from "@material-ui/core";

const styles: any = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  deleteButton: {
    backgroundColor: red[500],
    color: "white",
  },
  errorInput: {
    color: "red",
  },
  formControl: {
    marginTop: 17,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  half: {
    width: "100%",
  },
  paper: {
    padding: 20,
  },
  root: {
    height: "90vh",
    overflowY: "auto",
    padding: 20,
  },
  textField: {
    display: "block",
    width: "50%",
  },
  title: {
    color: "#4caf50",
  },
});

interface IViewProps {
  inputCertificationContracts: IInputCertificationContract[];
  classes: { [key: string]: string };
  user: IUser;
  id: any;
  changeSection(sectionNumber: number): void;
  deleteInputCertificationContract(
    uniqueId: string
  ): IAction<IInputCertificationContract[]>;
  fetchInputCertificationContract(
    inputCertificationContract: IInputCertificationContract
  ): IAction<IInputCertificationContract[]>;
  updateInputCertificationContract(
    inputCertificationContract: IInputCertificationContract
  ): IAction<IInputCertificationContract[]>;
}
interface IViewState {
  [key: string]:
    | string
    | number
    | boolean
    | string[]
    | IBankAccount[]
    | undefined;

  status: string;

  MA: string;
  contractDate: string;
  contractDateError: string;

  dialog: boolean;
  dialogTitle: string;
  errorMessage: string;
  debtor: string[];
  debtorError: string;
  loading: boolean;
  certificateNumber: string;
  certificateNumberError: string;
  inputValue: number;
  inputAmount: number;
  inputAmountError: string;

  uniqueId: string;
}
class View extends React.Component<IViewProps, IViewState> {
  public state: IViewState = {
    contractDate: new Date().toISOString().split("T")[0],
    contractDateError: "",
    debtor: [],
    debtorError: "",
    dialog: false,
    dialogTitle: "",
    errorMessage: "",
    loading: false,
    MA: "",
    inputValue: 0.0,
    inputAmount: 0.0,
    inputAmountError: "",
    certificateNumber: "",
    certificateNumberError: "",
    uniqueId: "",
    status: "pending_signature",
  };
  constructor(props: IViewProps) {
    super(props);

    // client
    this.handleAddClient = this.handleAddClient.bind(this);
    this.handleDeleteClient = this.handleDeleteClient.bind(this);

    // core events
    this.generateFile = this.generateFile.bind(this);
    this.generateTemplate = this.generateTemplate.bind(this);
    this.changeStatus = this.changeStatus.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
  }
  public async generateTemplate() {
    this.setState({ loading: true });
    if (this.state.debtor) {
      const url = `/lending/input-certification-form/${this.state.uniqueId}/print/`;
      await filesGeneration(url, this.props.user.token);
      this.setState({ loading: false });
    }
  }
  public handleCurrencyInputChange(key: any) {
    return (event?: any, maskedValue?: any, floatValue?: any) => {
      this.setState({ [key]: floatValue || "" });
    };
  }
  public handleAddClient(id: any) {
    this.setState({ debtor: [id] });
  }
  public handleDeleteClient(id: any) {
    this.setState({ debtor: [] });
  }
  public async generateFile() {
    this.setState({ loading: true });
    if (this.state.debtor) {
      const url = `/lending/input-certification-form/${this.state.uniqueId}/print_request/`;
      await filesGeneration(url, this.props.user.token);
      this.setState({ loading: false });
    }
  }
  public changeStatus(newStatus: any) {
    return () => {
      this.setState({ newStatus });
    };
  }

  public componentDidMount() {
    fetchMA(this.props.user.token, this.props.id)
      .then((res) => {
        const contract = res.data;

        this.setState({
          contractDate: contract.contractDate || "",
          debtor: contract.member ? [contract.member] : [],
          dialog: false,
          dialogTitle: "",
          financialAccounts: contract.financialAccounts || [],
          loading: false,
          MA: `lending/input-certification-form/${contract.uniqueId}/upload_attachment/`,
          certificateNumber: contract.certificateNumber || "",
          inputAmount: contract.inputAmount || 0.0,
          inputValue: contract.inputValue || 0.0,
          uniqueId: contract.uniqueId || "",
          status: contract.status || "",
        });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }

  public handleChange(key: any) {
    return (event: any) => {
      this.setState({
        [key]: event.currentTarget.value,
        [key + "Error"]: "",
      });
    };
  }

  public handleSubmit(event: any) {
    event.preventDefault();
    resetError(this);
    const { state } = this;
    if (!state.certificateNumber && state.status !== "in_process") {
      this.setState({ certificateNumberError: "Este campo es requerido" });
    } else if (!this.state.debtor.length) {
      this.setState({
        dialog: true,
        dialogTitle: "Disculpe",
        errorMessage: "Debe de seleccionar un deudor",
      });
    } else if (!this.state.inputAmount) {
      this.setState({ inputAmountError: "Este campo es requerido" });
    } else {
      const contract: IInputCertificationContract = {
        contractDate: state.contractDate,
        member: state.debtor[0] || "",
        certificateNumber: state.certificateNumber,
        inputAmount: state.inputAmount || 0.0,
        status: state.status,
      };

      this.setState({ loading: true });

      updateMA(this.props.user.token, this.state.uniqueId, contract)
        .then((res) => {
          this.props.updateInputCertificationContract(
            res.data.results ? res.data.results : res.data
          );
          successHandler(res, this, "Solicitud de Certificado de Aportación");
        })
        .catch((err) => {
          catchError(err, this);
        });
    }
  }
  public componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.inputAmount !== this.state.inputAmount) {
      const inputTotal = this.state.inputAmount * this.state.inputValue;
      this.setState({
        inputValueTotal: inputTotal,
      });
    }
  }

  public handleDelete() {
    if (window.confirm(translations.ARE_YOU_SURE)) {
      this.setState({ loading: true });
      deleteMA(this.props.user.token, this.props.id)
        .then((res) => {
          this.props.changeSection(0);
          this.props.deleteInputCertificationContract(this.props.id);
        })
        .catch((err) => {
          catchError(err, this);
        });
    }
  }
  public handleDialogClose() {
    this.setState({ dialog: false });
  }
  public handleSelectChange(key: string) {
    return (event: React.ChangeEvent<HTMLSelectElement>) => {
      this.setState({
        [key]: event.target.value,
        [key + "Error"]: "",
      });
    };
  }
  public render() {
    const { classes } = this.props;

    return (
      <form
        onSubmit={this.handleSubmit}
        noValidate={true}
        className={classes.root}
      >
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <Paper className={classes.paper}>
          <FileUploadSelectInput
            contractId={this.state.uniqueId}
            url={this.state.MA}
            description="input-certification"
          />

          <Typography variant="h4" className={classes.title}>
            Solicitud de Certificado de Aportación
          </Typography>
          <br />
          <br />

          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <Grid container={true} spacing={2}>
                <Grid item xs>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="status">
                      {translations.STATUS}
                    </InputLabel>
                    <Select
                      value={this.state.status}
                      onChange={this.handleSelectChange("status")}
                      required={true}
                      inputProps={{
                        id: "status",
                        name: "status",
                      }}
                    >
                      <MenuItem value="in_process">
                        {translations.ON_PROCESS}
                      </MenuItem>
                      <MenuItem value="internal_signature_pending">
                        {translations.INTERNAL_SIGNATURE_PENDING}
                      </MenuItem>
                      <MenuItem value="completed">
                        {translations.COMPLETED}
                      </MenuItem>
                      <MenuItem value="archived">
                        {translations.ARCHIVED}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs>
                  <Button
                    className={classes.greenButton}
                    onClick={this.generateFile}
                    // style={{ marginRight: 10, marginTop: 25 }}
                  >
                    <ArrowDownward />
                    &nbsp;
                    {"Generar Solicitud"}
                  </Button>
                </Grid>
                <Grid item xs>
                  {this.state.status !== "in_process" &&
                  this.state.certificateNumber ? (
                    <Button
                      className={classes.greenButton}
                      onClick={this.generateTemplate}
                      //   style={{ marginRight: 10, marginTop: 25 }}
                    >
                      <DocumentIcon />
                      &nbsp;
                      {translations.GENERATE_DOCUMENT}
                    </Button>
                  ) : null}
                </Grid>
              </Grid>

              <Grid container={true} spacing={2}>
                <Grid item={true} xs={4}>
                  <TextField
                    style={{ width: "100%" }}
                    label={"Número de certificado"}
                    variant="outlined"
                    required={this.state.status === "in_process" ? false : true}
                    className={classes.textField}
                    value={this.state.certificateNumber}
                    onChange={this.handleChange("certificateNumber")}
                    fullWidth={true}
                    error={this.state.certificateNumberError !== ""}
                    helperText={this.state.certificateNumberError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
                <Grid item={true} xs={4}>
                  <TextField
                    label={"Cantidad de Aportaciones"}
                    style={{ width: "100%" }}
                    variant="outlined"
                    className={classes.textField}
                    value={this.state.inputAmount}
                    onChange={this.handleChange("inputAmount")}
                    fullWidth={true}
                    required={true}
                    type="number"
                    error={this.state.inputAmountError !== ""}
                    helperText={this.state.inputAmountError}
                    InputLabelProps={{
                      style: styles.checkboxLabel,
                      shrink: true,
                    }}
                    inputProps={{
                      min: 1,
                      step: 1,
                    }}
                  />
                </Grid>
                <Grid item={true} xs={4}>
                  <TextField
                    label={"Valor por Aportacion"}
                    style={{ width: "100%" }}
                    variant="outlined"
                    className={classes.textField}
                    value={this.state.inputValue}
                    fullWidth={true}
                    disabled={true}
                    InputProps={{
                      inputComponent: MoneyInput,
                    }}
                    error={this.state.inputValueError !== ""}
                    helperText={this.state.inputValueError}
                    InputLabelProps={{
                      style: styles.checkboxLabel,
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={6}>
                  <TextField
                    label={"Total de Aportaciones"}
                    style={{ width: "100%" }}
                    variant="outlined"
                    className={classes.textField}
                    value={this.state.inputValueTotal}
                    fullWidth={true}
                    disabled={true}
                    InputProps={{
                      inputComponent: MoneyInput,
                    }}
                    InputLabelProps={{
                      style: styles.checkboxLabel,
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item={true} xs={6}>
                  <TextField
                    label={translations.CONTRACT_DATE}
                    style={{ width: "100%" }}
                    variant="outlined"
                    className={classes.textField}
                    value={this.state.contractDate}
                    fullWidth={true}
                    type="date"
                    onChange={this.handleChange("contractDate")}
                    required={true}
                    error={this.state.contractDateError !== ""}
                    helperText={this.state.contractDateError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>
              </Grid>
              <br />
              <br />
              <div className={classes.half}>
                <Typography variant="h6" className={classes.checkboxLabel}>
                  Miembro
                </Typography>
                <ParticipantSelectInput
                  selectedParticipantId={this.state.debtor}
                  addParticipant={this.handleAddClient}
                  deleteParticipant={this.handleDeleteClient}
                />
                <p className={classes.errorInput}>{this.state.debtorError}</p>
              </div>
              <br />
              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleDelete}
                  className={classes.deleteButton}
                >
                  <DeleteIcon />
                  &nbsp;
                  {translations.DELETE}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
              </div>
            </div>
          )}
        </Paper>
      </form>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    inputCertificationContracts: state.inputCertificationContracts,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, {
    deleteInputCertificationContract,
    fetchInputCertificationContract,
    updateInputCertificationContract,
  })(View)
);
