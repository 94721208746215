import {
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  Divider,
  Paper,
  Box,
  Tab,
} from "@material-ui/core";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { Add, Delete, FiberManualRecord, Settings } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { TicketTypeTranslations } from "../../../components/SharedComponents/DataListTypes";
import {
  AutocompleteField,
  NumericTextField,
  TextFieldComponent,
  CheckboxField,
} from "./../../../components/SharedComponents/Fields";
import NumberFormat from "./../../../components/SharedComponents/NumberFormat";
import { GeneralTable } from "./../../../components/SharedComponents/Table";
import { ticketStates } from "../TicketGeneralMethods/consts";
import { blue, green, orange, red } from "@material-ui/core/colors";
import {
  useHandleSelectAllRows,
  useHandleSelectRow,
  useIsSelected,
} from "../../../components/SharedComponents/CustomHooks";
import { paymentTypes } from "../../../utils/";
import {
  ticketStepsLabels,
  CustomTooltip,
  handleModal,
} from "../TicketGeneralMethods";
import { Atrasos, Financiamientos } from "../Dialogs";

function TableToolbar(props) {
  return (
    <Toolbar>
      <Grid
        container
        spacing={2}
        direction="row"
        style={{
          paddingTop: "15px",
        }}
      >
        <Grid
          container
          spacing={2}
          direction="row"
          style={{ alignItems: "center" }}
        >
          <Grid item xs={props.rowsPerPage ? 6 : 12}>
            <Typography
              variant="h6"
              className="section-header"
              style={{
                fontWeight: "bold",
                paddingLeft: 16,
                width: "inherit",
              }}
            >
              {props.title}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Toolbar>
  );
}

export function TicketTable(props) {
  const priorityColors = [
    { bgColor: "#1ea0f7", color: "#fff", priority: "low", name: "BAJA" },
    { bgColor: "#ffeb3b", color: "#424242", priority: "mid", name: "MEDIA" },
    { bgColor: "#ff5d52", color: "#fff", priority: "high", name: "ALTA" },
  ];

  const isCashier = (row) => {
    const verifySavingAccount = () => {
      let methodTable = null;
      let hasSavingAccount = false;
      ["eval_forms_settlement", "credit_formalization"].forEach((step) => {
        methodTable = row.methodTablesSteps
          .find((item) => item.step === step)
          .requirements.find((item) => item.name === "creditMethodTables")
          .value;
        if (!hasSavingAccount && methodTable?.savingsAccounts?.length) {
          hasSavingAccount = true;
        }
      });
      return hasSavingAccount;
    };

    return (
      <span>
        {row.requestType === "outlay"
          ? verifySavingAccount()
            ? "SI"
            : "NO"
          : "---"}
      </span>
    );
  };

  const priority = (row) => {
    const coloredPriority = priorityColors.find(
      (item) => item.priority === row.priority
    );
    return (
      <Button
        variant="contained"
        disableElevation
        style={{
          minWidth: 72,
          height: 25,
          borderRadius: 20,
          backgroundColor: coloredPriority.bgColor,
          color: coloredPriority.color,
          fontSize: 12,
        }}
      >
        {coloredPriority.name}
      </Button>
    );
  };

  const status = (row) => {
    const statusColors = ticketStates;
    // const translate = TicketStatusTranslations().find(item => item.value === row.status).name;
    const coloredLabel = statusColors.find((item) => item.state === row.status);
    return (
      <Button
        variant="contained"
        disableElevation
        style={{
          minWidth: 120,
          height: 25,
          borderRadius: 20,
          backgroundColor: coloredLabel?.bgColor || "#C9C9C9",
          color: coloredLabel?.color || "#424242",
          fontSize: 12,
        }}
      >
        {coloredLabel?.name || "---"}
      </Button>
    );
  };

  const step = (row) => {
    // const currStep = row.steps.filter(item => item.step).find(item => item.status === 'in_process' || (item.status === '' && item.order === 0));
    const step = row?.currentStep || "";
    const stepLabel = ticketStepsLabels().find((item) => item.value === step);
    return (
      <span style={{ color: stepLabel ? "#424242" : "#43A047" }}>
        {stepLabel ? stepLabel.name.toUpperCase() : "PASOS COMPLETADOS"}
      </span>
    );
  };

  const ticketType = (row) => {
    const translate = TicketTypeTranslations().find(
      (item) => item.value === row.requestType
    ).name;
    return <span>{translate}</span>;
  };

  const date = (row) => {
    const options = {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hours: "numeric",
      minutes: "numeric",
    };
    return (
      <span>
        {row.creationDate
          ? new Date(row.creationDate).toLocaleTimeString("es-DO", options)
          : "---"}
      </span>
    );
  };

  const participant = (row) => {
    const participant = row.customer ? row.fullName : "----";
    return (
      <div>
        <span style={{ display: "flex", alignItems: "center" }}>
          {row.affiliatedInstitution ? (
            <FiberManualRecord style={{ color: blue[500] }} />
          ) : null}
          {participant}
        </span>
      </div>
    );
  };

  const columns = [
    { id: "referenceCode", label: "CÓDIGO", minWidth: 120 },
    { id: "customer", label: "CLIENTE", minWidth: 150, element: participant },
    { id: "created", label: "FECHA CREACIÓN", minWidth: 150, element: date },
    {
      id: "requestType",
      label: "TIPO DE TICKET",
      minWidth: 180,
      element: ticketType,
    },
    { id: "step", label: "PASO", minWidth: 190, element: step },
    {
      id: "step",
      label: "DESEMBOLSO POR CAJA",
      align: "center",
      minWidth: 190,
      element: isCashier,
    },
    {
      id: "priority",
      label: "PRIORIDAD",
      align: "center",
      minWidth: 150,
      element: priority,
    },
    {
      id: "status",
      label: "ESTADO",
      align: "center",
      minWidth: 190,
      element: status,
    },
  ];

  var byName = props.data.slice(0);

  byName.forEach((item) => {
    if (item.priority === "high") {
      item.order = 1;
    }
    if (item.priority === "mid") {
      item.order = 2;
    }
    if (item.priority === "low") {
      item.order = 3;
    }
  });

  byName.sort(function(a, b) {
    var x = a.order;
    var y = b.order;
    return x < y ? -1 : x > y ? 1 : 0;
  });

  return (
    <GeneralTable
      columns={columns}
      data={byName}
      rowClick={props.handleSelectTicket}
    />
  );
}

export function EvalFormsSettlementTable(props) {
  const [columns, setColumns] = useState([]);
  const [tableData, setTableData] = useState(props.data);
  const amount = (fix, type, row, value) => {
    return (
      <NumberFormat
        prefix={type === "prefix" && fix}
        suffix={type === "suffix" && fix}
        value={value}
      />
    );
  };
  const date = (row) => {
    return (
      <span>
        {row.fecha_transaccion
          ? row.fecha_transaccion.substring(
              0,
              row.fecha_transaccion.indexOf("T")
            )
          : "---"}
      </span>
    );
  };
  const regularAmount = [
    { id: "id_desembolso", label: "ID DESEMBOLSO", minWidth: 120 },
    { id: "fecha_transaccion", label: "FECHA", minWidth: 150, element: date },
    { id: "tipo_producto", label: "TIPO PRODUCTO", minWidth: 150 },
    {
      id: "tasa_interes",
      label: "TASA INTERÉS",
      minWidth: 150,
      element: amount.bind(this, "%", "suffix"),
    },
    {
      id: "monto_financiado",
      label: "MONTO FINANCIADO",
      minWidth: 150,
      element: amount.bind(this, "$", "prefix"),
    },
  ];
  const overdueTotal = [
    { id: "id_desembolso", label: "ID DESEMBOLSO", minWidth: 120 },
    {
      id: "cantidad_cuotas",
      label: "CANTIDAD DE CUOTAS EN ATRASO",
      minWidth: 190,
    },
    {
      id: "total_atrasado",
      label: "TOTAL EN ATRASO",
      minWidth: 150,
      element: amount.bind(this, "$", "prefix"),
    },
  ];
  useEffect(() => {
    let selectedColumn = [];
    if (["regularLoans", "advancePurchases"].includes(props.amountType)) {
      selectedColumn = regularAmount;
    } else {
      selectedColumn = overdueTotal;
    }
    setColumns(selectedColumn);
  }, [props.amountType]);

  useEffect(() => {
    setTableData(props.data);
  }, [columns, props.data]);

  return <GeneralTable columns={columns} data={tableData} />;
}

export function SettlementFormsTable(props) {
  const amount = (column, row) => {
    return <NumberFormat value={row[column]} />;
  };
  const date = (row) => {
    return (
      <span>
        {/* {row.created.substring(0, row.created.indexOf('T'))} */}
        {"RANDOM DATE"}
      </span>
    );
  };

  const columns = [
    { id: "disbursementId", label: "ID DESEMBOLSO", minWidth: 120 },
    { id: "date", label: "FECHA", minWidth: 150, element: date },
    { id: "productType", label: "TIPO PRODUCTO", minWidth: 150 },
    { id: "interestRate", label: "TASA INTERÉS", minWidth: 150 },
    {
      id: "financedAmount",
      label: "MONTO FINANCIADO",
      minWidth: 150,
      element: amount,
    },
  ];

  return <GeneralTable columns={columns} data={props.data} />;
}

export function DebtCapacityTable({
  data,
  debtCapacity,
  suggested,
  quotationData,
  quotationFee,
  ticketStep,
  settlementData,
}) {
  // const [projectedAmount, setProjectedAmount] = useState(0);

  const getProjectedAmount = (mas) => {
    let amount = {};
    let transformedData = {};
    amount.saldo = Number(mas);
    if (quotationData?.uniqueId) {
      const regularLoans = [
        "regular_loan",
        "simple_loan",
        "constant_fees",
        "decreasing_fees",
        "salary_advance",
      ];
      amount.quotation = regularLoans.includes(quotationData.productType)
        ? Number(quotationData.originalAmount)
        : Number(quotationFee || 0);
    }
    // TEMPORAL FIX UNTIL WE DOCUMENT THE CORRECT FORMULA FOR THIS VALUE

    let hasDisbursementPayment =
      Object.keys(settlementData?.outlay || {}).length ||
      Object.keys(settlementData?.expiration || {}).length;
    if (!hasDisbursementPayment) {
      amount.proyected = Number(mas) + amount?.quotation;
    }
    for (const key in amount) {
      if (Object.hasOwnProperty.call(amount, key)) {
        transformedData[key] = amount[key].toLocaleString("en-US", {
          style: "currency",
          currency: "DOP",
        });
      }
    }
    return transformedData;
  };

  const getBgColor = (row) => {
    const colors = {
      bis: { bg: "#fafafa", color: "#424242" },
      mlc: { bg: "#fafafa", color: "#424242" },
      bia: { bg: "#fafafa", color: "#424242" },
    };
    const mas = row.capacidad_endeudamiento;
    const mlc = debtCapacity[0]?.approvedAmount;
    const bis = suggested;
    const bia = debtCapacity[0]?.applicableAmount;

    if (mas > bis || mlc > bis) {
      colors.bis.bg = red[500];
      colors.bis.color = "#fafafa";
    }
    if (mas < bia && mas > mlc) {
      colors.mlc.bg = orange[500];
      colors.mlc.color = "#fafafa";
    }
    if (mas > bia && mas < bis) {
      if (bia < mlc) {
        colors.mlc.bg = orange[500];
        colors.bia.bg = red[500];
      }
      if (bia > mlc) {
        colors.mlc.bg = red[500];
        colors.bia.bg = orange[500];
      }
      colors.bia.color = "#fafafa";
      colors.mlc.color = "#fafafa";
    }
    return colors;
  };
  const amount = (fix, type, row, value) => {
    return (
      <NumberFormat
        prefix={type === "prefix" && fix}
        suffix={type === "suffix" && fix}
        value={value}
      />
    );
  };

  const columns = [
    {
      id: "Monto Linea de Credito",
      label: "Monto Linea de Credito".toUpperCase(),
      minWidth: 160,
      align: "center",
      element: (row) =>
        row.capacidad_endeudamiento < debtCapacity[0]?.applicableAmount ||
        row.capacidad_endeudamiento > debtCapacity[0]?.approvedAmount ? (
          <Button
            variant="contained"
            disableElevation
            style={{
              minWidth: 120,
              height: 25,
              borderRadius: 20,
              backgroundColor: getBgColor(row)?.mlc?.bg,
              color: getBgColor(row)?.mlc?.color,
              fontSize: 12,
            }}
          >
            {Number(debtCapacity[0]?.approvedAmount || 0).toLocaleString(
              "en-US",
              {
                style: "currency",
                currency: "DOP",
              }
            )}
          </Button>
        ) : (
          Number(debtCapacity[0]?.approvedAmount || 0).toLocaleString("en-US", {
            style: "currency",
            currency: "DOP",
          })
        ),
    },
    {
      id: "Base Imponible Sugerida",
      label: "Base Imponible Sugerida".toUpperCase(),
      minWidth: 160,
      align: "center",
      element: (row) =>
        row.capacidad_endeudamiento > suggested ? (
          <Button
            variant="contained"
            disableElevation
            style={{
              minWidth: 120,
              height: 25,
              borderRadius: 20,
              backgroundColor: getBgColor(row)?.bis?.bg,
              color: getBgColor(row)?.bis?.color,
              fontSize: 12,
            }}
          >
            {Number(suggested || 0).toLocaleString("en-US", {
              style: "currency",
              currency: "DOP",
            })}
          </Button>
        ) : (
          Number(suggested || 0).toLocaleString("en-US", {
            style: "currency",
            currency: "DOP",
          })
        ),
    },
    {
      id: "Base Imponible Aprobada",
      label: "Base Imponible Aprobada".toUpperCase(),
      minWidth: 160,
      align: "center",
      element: (row) =>
        debtCapacity.length > 0 ? (
          <Button
            variant="contained"
            disableElevation
            style={{
              minWidth: 120,
              height: 25,
              borderRadius: 20,
              backgroundColor: getBgColor(row)?.bia?.bg,
              color: getBgColor(row)?.bia?.color,
              fontSize: 13,
            }}
          >
            {Number(debtCapacity[0]?.applicableAmount).toLocaleString("en-US", {
              style: "currency",
              currency: "DOP",
            })}
          </Button>
        ) : (
          "---"
        ),
    },
    {
      id: "Monto Adeudado Socio",
      tooltip:
        ticketStep === "credit_analysis"
          ? "Este valor puede no ser preciso, calculo del valor pendiente de verificacion"
          : null,
      label: (ticketStep === "credit_analysis"
        ? "Monto Proyectado"
        : "Saldo adeudado"
      ).toUpperCase(),
      minWidth: 160,
      align: "center",
      element: (row) => {
        const newRow = getProjectedAmount(row.capacidad_endeudamiento);

        return debtCapacity.length > 0 ? (
          ticketStep === "credit_analysis" ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CustomTooltip
                title={
                  <Card style={{ marginTop: "3%", width: 350 }}>
                    <CardContent>
                      <div>
                        <div>
                          Saldo Adedudado: <NumberFormat value={newRow.saldo} />
                        </div>
                        <Divider />
                        <div>
                          Monto a Financiar:{" "}
                          <NumberFormat value={newRow.quotation} />
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                }
              >
                <Button
                  variant="contained"
                  disableElevation
                  style={{
                    minWidth: 120,
                    height: 25,
                    borderRadius: 20,
                    color: "black",
                    backgroundColor: "#fafafa",
                    fontSize: 12,
                  }}
                >
                  {newRow.proyected || "---"}
                </Button>
              </CustomTooltip>
            </div>
          ) : (
            newRow?.saldo
          )
        ) : (
          "---"
        );
      },
    },
    {
      id: "Potencial Disponible",
      label: "Potencial Disponible".toUpperCase(),
      minWidth: 160,
      align: "center",
      element: (row) =>
        debtCapacity.length > 0 ? (
          debtCapacity[0]?.applicableAmount - row.capacidad_endeudamiento <
          0 ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CustomTooltip
                title={
                  <Card style={{ marginTop: "3%", width: 350 }}>
                    <CardContent>
                      <div>
                        <div>
                          BIS: <NumberFormat value={suggested || 0} />
                        </div>
                        <Divider />
                        <div>
                          MAS:{" "}
                          <NumberFormat
                            value={row.capacidad_endeudamiento || 0}
                          />
                        </div>
                        <Divider />
                        <div>
                          DIF:{" "}
                          <NumberFormat
                            value={
                              (suggested || 0) -
                              (row.capacidad_endeudamiento || 0)
                            }
                          />
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                }
              >
                <Button
                  variant="contained"
                  disableElevation
                  style={{
                    minWidth: 120,
                    height: 25,
                    borderRadius: 20,
                    backgroundColor:
                      suggested - row.capacidad_endeudamiento < 0
                        ? red[500]
                        : debtCapacity[0]?.applicableAmount -
                            row.capacidad_endeudamiento <
                            0 && orange[500],
                    color: "white",
                    fontSize: 12,
                  }}
                >
                  {Number(
                    debtCapacity[0]?.applicableAmount -
                      row.capacidad_endeudamiento
                  ).toLocaleString("en-US", {
                    style: "currency",
                    currency: "DOP",
                  }) || "---"}
                </Button>
              </CustomTooltip>
            </div>
          ) : (
            Number(
              debtCapacity[0]?.applicableAmount - row.capacidad_endeudamiento
            ).toLocaleString("en-US", {
              style: "currency",
              currency: "DOP",
            })
          )
        ) : (
          "---"
        ),
    },
  ];

  // useEffect(() => {
  //     setProjectedAmount(amount);
  // }, [quotationData, quotationFee])

  return (
    <GeneralTable
      columns={columns}
      data={data}
      toolbar={<TableToolbar title={"Capacidad de endeudamiento"} />}
    />
  );
}

export function PayCapacityTable(props) {
  const getProjectedAmount = (totalCapacity) => {
    const totalCP = Number(totalCapacity || 0);
    const fee = Number(props.quotationFee || 0);
    const amount = (totalCP + fee).toLocaleString("en-US", {
      style: "currency",
      currency: "DOP",
    });
    return amount;
  };

  const amount = (fix, type, row, value) => {
    return (
      <NumberFormat
        prefix={type === "prefix" && fix}
        suffix={type === "suffix" && fix}
        value={value}
      />
    );
  };

  const columns = [
    {
      label: "Tipo Producto".toUpperCase(),
      minWidth: 160,
      align: "center",
      element: (row) => row.tipoCapacidadPago,
    },
    {
      id: "Base Imponible",
      label: "Base Imponible".toUpperCase(),
      minWidth: 160,
      align: "center",
      element: (row) =>
        Number(row.capacidad).toLocaleString("en-US", {
          style: "currency",
          currency: "DOP",
        }),
    },
    {
      id: "Monto Utilizado",
      label: "Monto Utilizado".toUpperCase(),
      minWidth: 160,
      align: "center",
      element: (row) => (
        <CustomTooltip
          title={
            <Card style={{ marginTop: "3%", width: 450 }}>
              <CardContent>
                <div>
                  <div>
                    TOTAL AHORRO: <NumberFormat value={row.totalAhorros || 0} />
                  </div>
                  <Divider />
                  <div>
                    TOTAL PRESTAMO:{" "}
                    <NumberFormat value={row.totalPrestamos || 0} />
                  </div>
                </div>
              </CardContent>
            </Card>
          }
        >
          <Button
            variant="contained"
            disableElevation
            style={{
              minWidth: 120,
              height: 25,
              borderRadius: 20,
              backgroundColor: "#e0e0e0",
              color: "#424242",
              fontSize: 12,
            }}
          >
            {Number(row.totalCapacidadPagos).toLocaleString("en-US", {
              style: "currency",
              currency: "DOP",
            })}
          </Button>
        </CustomTooltip>
      ),
    },
    {
      id: "Monto Utilizado proyectado",
      label: "Monto Utilizado Proyectado".toUpperCase(),
      minWidth: 160,
      align: "center",
      element: (row) => (
        <CustomTooltip
          title={
            <Card style={{ marginTop: "3%", width: 450 }}>
              <CardContent>
                <div>
                  <div>
                    TOTAL AHORRO: <NumberFormat value={row.totalAhorros || 0} />
                  </div>
                  <Divider />
                  <div>
                    TOTAL PRESTAMO:{" "}
                    <NumberFormat value={row.totalPrestamos || 0} />
                  </div>
                  <Divider />
                  <div>
                    CUOTA: <NumberFormat value={props.quotationFee || 0} />
                  </div>
                </div>
              </CardContent>
            </Card>
          }
        >
          <Button
            variant="contained"
            disableElevation
            style={{
              minWidth: 120,
              height: 25,
              borderRadius: 20,
              backgroundColor: "#e0e0e0",
              color: "#424242",
              fontSize: 12,
            }}
          >
            {getProjectedAmount(row.totalCapacidadPagos)}
          </Button>
        </CustomTooltip>
      ),
    },
    {
      id: "Monto Disponible",
      label: "Monto Disponible".toUpperCase(),
      minWidth: 160,
      align: "center",
      element: (row) =>
        row.disponible < 0 ? (
          <Button
            variant="contained"
            disableElevation
            style={{
              minWidth: 120,
              height: 25,
              borderRadius: 20,
              backgroundColor: row.disponible < 0 && red[500],
              color: row.disponible < 0 ? "white" : "black",
              fontSize: 12,
            }}
          >
            {Number(row.disponible).toLocaleString("en-US", {
              style: "currency",
              currency: "DOP",
            })}
          </Button>
        ) : (
          Number(row.disponible).toLocaleString("en-US", {
            style: "currency",
            currency: "DOP",
          })
        ),
    },
  ].filter((item) =>
    props.ticketStep === "credit_analysis"
      ? item
      : item.id !== "Monto Utilizado proyectado"
  );

  return (
    <GeneralTable
      columns={columns}
      data={props.data}
      toolbar={<TableToolbar title={"Capacidad de pago"} />}
    />
  );
}

export function FinancingTable(props) {
  const handleFinancingChange = () => {
    const filteredData = props.data
      .filter((item) =>
        props.financingAmountCheck
          ? Number(item.saldo_actual) <= 0
          : Number(item.saldo_actual) > 0
      )
      .sort((a, b) =>
        a.saldo_actual > b.saldo_actual
          ? -1
          : a.saldo_actual < b.saldo_actual
          ? 1
          : a.fecha_desembolso > b.fecha_desembolso
          ? -1
          : a.fecha_desembolso < b.fecha_desembolso
          ? 1
          : a.tipo_producto > b.tipo_producto
          ? 1
          : a.tipo_producto < b.tipo_producto
          ? -1
          : 1
      );
    return filteredData;
  };

  const actions = (row, key) => {
    return (
      <>
        <div style={{ alignItems: "center" }}>
          <IconButton
            onClick={() => handleModal(row, key, props.handleToggleDialog)}
          >
            <Settings
              style={{
                fontSize: 22,
                color: green[500],
              }}
            />
          </IconButton>
        </div>
      </>
    );
  };
  const columns = [
    {
      id: "ID Desembolso",
      label: "ID Desembolso".toUpperCase(),
      minWidth: 160,
      align: "center",
      element: (row) => row.numero_prestamo,
    },
    {
      label: "Tipo Producto".toUpperCase(),
      minWidth: 160,
      align: "center",
      element: (row) => row.tipo_producto || "",
    },
    {
      id: "Fecha Desembolso",
      label: "Fecha Desembolso".toUpperCase(),
      minWidth: 160,
      align: "center",
      element: (row) => row.fecha_desembolso || "",
    },
    {
      id: "Saldo Actual",
      label: "Saldo Actual".toUpperCase(),
      minWidth: 160,
      align: "center",
      element: (row) =>
        Number(row.saldo_actual).toLocaleString("en-US", {
          style: "currency",
          currency: "DOP",
        }) || "",
    },
    {
      id: "action",
      label: "ACCIÓN",
      align: "center",
      element: (row) => actions(row, "financiamientos"),
    },
  ];
  return (
    <GeneralTable
      columns={columns}
      data={handleFinancingChange()}
      toolbar={<TableToolbar title={"Financiamientos"} />}
    />
  );
}

export function ArrearsTable(props) {
  const actions = (row, key) => {
    return (
      <>
        <div style={{ alignItems: "center" }}>
          <IconButton
            onClick={() => handleModal(row, key, props.handleToggleDialog)}
          >
            <Settings
              style={{
                fontSize: 22,
                color: green[500],
              }}
            />
          </IconButton>
        </div>
      </>
    );
  };
  const columns = [
    {
      id: "ID Desembolso",
      label: "ID Desembolso".toUpperCase(),
      minWidth: 160,
      align: "center",
      element: (row) => row.numero_prestamo,
    },
    {
      label: "Tipo Producto".toUpperCase(),
      minWidth: 160,
      align: "center",
      element: (row) => row.tipo_producto,
    },
    {
      id: "Fecha Vencimiento",
      label: "Fecha Vencimiento".toUpperCase(),
      minWidth: 160,
      align: "center",
      element: (row) => row.fecha_vencimiento,
    },
    {
      id: "Total Atrasado",
      label: "Total Atrasado".toUpperCase(),
      minWidth: 160,
      align: "center",
      element: (row) =>
        Number(row.total_atrasado).toLocaleString("en-US", {
          style: "currency",
          currency: "DOP",
        }),
    },
    {
      id: "action",
      label: "ACCIÓN",
      align: "center",
      element: (row) => actions(row, "atrasos"),
    },
  ];

  return (
    <GeneralTable
      columns={columns}
      data={props.data}
      toolbar={<TableToolbar title={"Atrasos"} />}
    />
  );
}

export function CreditMethodTable(props) {
  const columns = [
    {
      id: "lastDepositsAverage",
      label: "PROMEDIO TRES DEPOSITOS",
      minWidth: 120,
    },
    {
      id: "regularFeesSummation",
      label: "SUMATORIA CUOTAS PRESTAMOS",
      minWidth: 150,
    },
    { id: "otherExpenses", label: "OTROS GASTOS", minWidth: 150 },
    { id: "taxBasePercentage", label: "% BASE IMPONIBLE", minWidth: 150 },
  ];

  return <GeneralTable columns={columns} data={props.data} />;
}

export function DisbursementTable(props) {
  const amount = (row, value) => {
    return <NumberFormat value={value} />;
  };
  const date = (row) => {
    return (
      <span>
        {row.fecha_desembolso
          ? row.fecha_desembolso.substring(0, row.fecha_desembolso.indexOf("T"))
          : "---"}
      </span>
    );
  };

  const columns = [
    { id: "checkbox", label: "SELECCIÓN", minWidth: 120 },
    { id: "id_desembolso", label: "ID DESEMBOLSO", minWidth: 150 },
    { id: "estado", label: "ESTADO", minWidth: 120 },
    {
      id: "fecha_desembolso",
      label: "FECHA DESEMBOLSO",
      minWidth: 150,
      element: date,
    },
    {
      id: "monto_desembolso",
      label: "MONTO DESEMBOLSO",
      minWidth: 150,
      element: amount,
    },
    { id: "tipo_producto", label: "TIPO PRODUCTO", minWidth: 150 },
    // {id:'action', label: 'ACCIÓN', align: 'center', minWidth: 170, element: actions},
  ];

  return (
    <GeneralTable
      columns={columns}
      data={props.data}
      selected={props.selected}
      setSelected={props.setSelected}
      handleSelectAllRows={useHandleSelectAllRows}
      handleSelectRow={useHandleSelectRow}
      isSelected={useIsSelected}
      removeSelectAll={true}
      limitSelection={1}
      checkbox={true}
    />
  );
}

export function PaymentMethodsTable(props) {
  // const [tableData, setTableData] = useState([]);

  const paymentMethods = [
    { name: "Transferencia", value: "transfer" },
    { name: "Cheque", value: "check" },
    { name: "Descuento por nómina", value: "payroll" },
    { name: "Efectivo", value: "cash" },
  ];

  const defaultPaymentMethod = () => {
    return {
      id: Date.now(),
      paymentType: null,
      amount: "",
      referenceNumber: "",
      account: "",
    };
  };

  const handleAddMethod = () => {
    const currData = [...props.data];
    const newData = defaultPaymentMethod();
    props.setTableData(currData.concat(newData));
  };

  const handleChange = (row, type, event, value) => {
    const data = value
      ? value
      : event.target
      ? event.target.value
      : event.value;
    row[type] = data;
    props.setTableData([...props.data]);
  };

  const handleChangeNumbers = (row, type, event) => {
    const data = event.target ? event.target.value : event.value;
    row[type] = data;
    props.setTableData([...props.data]);
  };

  const handleRemoveRow = (row) => {
    const data = [...props.data];
    const index = data.findIndex((item) => item.id === row.id);
    if (index > -1) {
      data.splice(index, 1);
    }
    props.setTableData([...data]);
  };

  const paymentTypeField = (type, row) => {
    return (
      <AutocompleteField
        xs={12}
        fieldID={`paymet-methods-select-${type}`}
        fieldClass="fields"
        fieldInnerLabel="Tipo de pago"
        fieldVariant="outlined"
        value={row[type] || null}
        handleChange={handleChange.bind(this, row, type)}
        options={paymentMethods}
        getOptionLabel={(option) => option.name}
        getOptionSelected={(option, val) => option.value === val.value}
        multiple={false}
        disabled={props.disabled}
      />
    );
  };
  const bankAccountField = (type, row) => {
    return row.paymentType && row.paymentType.value === "transfer" ? (
      <AutocompleteField
        xs={12}
        fieldID={`bank-accounts-select-${type}`}
        fieldClass="fields"
        fieldInnerLabel="Cuenta de banco"
        fieldVariant="outlined"
        value={row[type] || null}
        handleChange={handleChange.bind(this, row, type)}
        options={props.bankAccounts}
        getOptionLabel={(option) => option.name}
        getOptionSelected={(option, val) => option.value === val.value}
        multiple={false}
        disabled={props.disabled}
      />
    ) : (
      <span>---</span>
    );
  };
  const textField = (type, row) => {
    return (
      <TextFieldComponent
        xs={12}
        typeVariant="subtitle1"
        typeClass="field-label"
        fieldID={`paymet-methods-text-${type}`}
        fieldClass="fields"
        fieldVariant="outlined"
        placeholder="Digite..."
        margin="dense"
        fullWidth
        value={row[type]}
        onChange={handleChange.bind(this, row, type)}
        disabled={props.disableFields || props.disabled}
      />
    );
  };

  const numericField = (type, row) => {
    return (
      <NumericTextField
        xs={12}
        typeVariant="subtitle1"
        typeClass="field-label"
        fieldID={`paymet-methods-text-${type}`}
        fieldClass="fields"
        fieldVariant="outlined"
        placeholder="Ej: $5,000.00"
        margin="dense"
        fullWidth
        prefix="$"
        thousandSeparator={true}
        decimalScale={2}
        fixedDecimalScale={true}
        onChange={handleChangeNumbers.bind(this, row, type)}
        value={row[type]}
        disabled={props.disabled}
      />
    );
  };

  const actions = (row) => {
    return (
      <Grid container direciton="row" justifyContent="center">
        <IconButton
          onClick={handleRemoveRow.bind(this, row)}
          disabled={props.disabled}
        >
          <Delete style={{ fontSize: 22, color: "#616161" }} />
        </IconButton>
      </Grid>
    );
  };

  const columns = [
    {
      id: "paymentType",
      label: "TIPO DE PAGO",
      minWidth: 150,
      element: paymentTypeField.bind(this, "paymentType"),
    },
    {
      id: "amount",
      label: "MONTO",
      minWidth: 120,
      element: numericField.bind(this, "amount"),
    },
    {
      id: "referenceNumber",
      label: "NÚMERO DE REFERENCIA",
      minWidth: 150,
      element: textField.bind(this, "referenceNumber"),
    },
    {
      id: "account",
      label: "CUENTA BANCO",
      minWidth: 200,
      element: bankAccountField.bind(this, "account"),
    },
    {
      id: "action",
      label: "ACCIÓN",
      align: "center",
      minWidth: 100,
      element: actions,
    },
  ];

  useEffect(() => {
    if (props.data.length < 1) {
      const newData = [defaultPaymentMethod()];
      // setTableData(newData);
      props.setTableData(newData);
      return;
    }
    // setTableData(props.data);
  }, [props.data]);

  return (
    <GeneralTable
      columns={columns}
      data={props.data}
      // data={tableData}
      noPagination={true}
      toolbar={
        <Toolbar>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              variant="subtitle1"
              className="section-header"
              style={{ fontWeight: "bold", paddingLeft: 16 }}
            >
              MÉTODOS DE PAGO
            </Typography>
            <Button
              variant="outlined"
              disableElevation
              style={{ borderRadius: 3, color: "#424242", fontSize: 12 }}
              onClick={handleAddMethod}
              disabled={props.disabled}
            >
              <Add
                style={{ fontSize: 17, color: "#424242", marginRight: ".5rem" }}
              />
              agregar método
            </Button>
          </Grid>
        </Toolbar>
      }
    />
  );
}

export function PaymentNegotiationTable(props) {
  const amount = (row, value) => {
    return <NumberFormat prefix={"$"} value={value} />;
  };
  const date = (row) => {
    return (
      <span>
        {row.created
          ? row.created.substring(0, row.created.indexOf("T"))
          : "---"}
        {/* {row.date.substring(0, row.date.indexOf('T'))} */}
        {/* {row.date} */}
      </span>
    );
  };
  const type = (row) => {
    const type = paymentTypes.find((item) => item.value === row.paymentType);
    const pType = type ? type.label : "---";
    return <span>{pType}</span>;
  };

  const actions = (row) => {
    return (
      <Grid
        container
        item
        direciton="row"
        justifyContent="space-evenly"
        spacing={5}
      >
        <IconButton
          onClick={props.handleToggleDialog.bind(this, "payment-calculator", {
            id: true,
            row: row,
            participant: props.participant,
            paymentType: props.paymentType && props.paymentType.value,
          })}
        >
          <Settings style={{ fontSize: 22, color: "#616161" }} />
        </IconButton>
        {!props.disabled && (
          <IconButton
            onClick={props.handleToggleSimpleDialog.bind(
              this,
              "confirm-dialog",
              "confirm-delete-payment",
              { callback: () => props.deletePayment(row.uniqueId) }
            )}
          >
            <Delete style={{ fontSize: 22, color: "#616161" }} />
          </IconButton>
        )}
      </Grid>
    );
  };

  const columns = [
    { id: "referenceCode", label: "REFERENCIA", minWidth: 150 },
    { id: "created", label: "FECHA", minWidth: 150, element: date },
    {
      id: "totalToPay",
      label: "MONTO A PAGAR",
      minWidth: 120,
      element: amount,
    },
    {
      id: "paymentType",
      label: "METODOLOGÍA DE PAGO",
      minWidth: 190,
      element: type,
    },
    {
      id: "action",
      label: "ACCIÓN",
      align: "center",
      minWidth: 200,
      element: actions,
    },
  ];

  return (
    <GeneralTable
      columns={columns}
      data={props.data}
      toolbar={
        <Toolbar>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              variant="subtitle1"
              className="section-header"
              style={{ fontWeight: "bold", paddingLeft: 16 }}
            >
              PAGOS
            </Typography>
            <Button
              variant="outlined"
              disableElevation
              style={{
                borderRadius: 3,
                color: props.paymentType ? "#424242" : "#CCCCCC",
                fontSize: 12,
              }}
              onClick={props.handleToggleDialog.bind(
                this,
                "payment-calculator",
                {
                  id: true,
                  participant: props.participant,
                  callback: props.setPaymentInfo,
                  paymentType: props.paymentType && props.paymentType.value,
                }
              )}
              disabled={
                !props.paymentType || props.disabled || props.data.length > 1
              }
            >
              <Add style={{ fontSize: 17, marginRight: ".5rem" }} />
              agregar negociación
            </Button>
          </Grid>
        </Toolbar>
      }
    />
  );
}

export function FundsVerificationTable(props) {
  const amount = (row, value) => {
    return <NumberFormat value={value} prefix={"$"} />;
  };
  const date = (row) => {
    return (
      <span>
        {row.fechaTrans
          ? row.fechaTrans.substring(0, row.fechaTrans.indexOf("T"))
          : "---"}
      </span>
    );
  };

  const columns = [
    { id: "checkbox", label: "SELECCIÓN", minWidth: 120 },
    { id: "codigoDbs", label: "ID DESEMBOLSO", minWidth: 150 },
    { id: "noAcientoContable", label: "ASIENTO CONTABLE", minWidth: 150 },
    {
      id: "fechaTrans",
      label: "FECHA TRANSACCIÓN",
      minWidth: 190,
      element: date,
    },
    {
      id: "montoPagado",
      label: "MONTO PAGADO",
      minWidth: 180,
      element: amount,
    },
    { id: "tipoPrestamo", label: "TIPO DE PRESTAMO", minWidth: 150 },
  ].filter((item) => (props.selected ? item : item.id !== "checkbox"));

  return (
    <GeneralTable
      columns={columns}
      data={props.data}
      selected={props.selected}
      setSelected={props.setSelected}
      handleSelected={props.handleSelected}
    />
  );
}

export function LoanTransactionTable(props) {
  const [value, setValue] = useState("1");
  const [dialog, setDialog] = useState({ name: "", data: null });
  const [financingAmountCheck, setFinancingAmountCheck] = useState(false);
  const handleChangeValue = (event, newValue) => {
    setValue(newValue);
  };
  const handleFinancingAmountCheck = () => {
    setFinancingAmountCheck(!financingAmountCheck);
  };
  const handleToggleDialog = (dialog, data) => {
    const selectedDialog = dialog ? dialog : "";
    const dialogData = data;
    const obj = { name: selectedDialog, data: dialogData };
    setDialog({ ...obj });
  };
  return (
    <>
      <Paper>
        <div style={{ width: "100%" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChangeValue}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: green[500],
                  },
                }}
              >
                <Tab label="Financiamientos" value="1" />
                <Tab label="Atrasos" value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Grid container style={{ margin: ".5rem 0 1rem 0" }}>
                <CheckboxField
                  xs={12}
                  alignItems="flex-end"
                  check={financingAmountCheck}
                  handleChange={handleFinancingAmountCheck}
                  color="primary"
                  textColor="#43A047"
                  label="VISUALIZAR TRANSACCIONES CON SALDO EN 0.00"
                />
              </Grid>
              <FinancingTable
                data={
                  props.ticketInfoData.length > 0
                    ? props.ticketInfoData[0].detalle_financiamientos
                      ? props.ticketInfoData[0].detalle_financiamientos
                      : []
                    : []
                }
                financingAmountCheck={financingAmountCheck}
                handleToggleDialog={handleToggleDialog}
              />
            </TabPanel>
            <TabPanel value="2">
              <ArrearsTable
                data={
                  props.ticketInfoData.length > 0
                    ? props.ticketInfoData[0].detalle_atrasos
                      ? props.ticketInfoData[0].detalle_atrasos
                      : []
                    : []
                }
                handleToggleDialog={handleToggleDialog}
              />
            </TabPanel>
          </TabContext>
        </div>
      </Paper>
      {dialog.name === "financiamientos" ? (
        <Financiamientos
          open={true}
          data={dialog.data}
          token={props.token}
          handleToggleDialog={handleToggleDialog}
          handleToggleComponent={props.handleToggleComponent}
        />
      ) : null}
      {dialog.name === "atrasos" ? (
        <Atrasos
          open={true}
          data={dialog.data}
          token={props.token}
          handleToggleDialog={handleToggleDialog}
          handleToggleComponent={props.handleToggleComponent}
        />
      ) : null}
    </>
  );
}
