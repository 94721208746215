import React from "react";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from "@material-ui/core";
import InlineCreateForm from "../../components/Ticket/ExtraPayTable/InlineCreateForm"
import InlineEditForm from "../../components/Ticket/ExtraPayTable/InlineEditForm";

const style = {
  TableCell: { padding: "8px", textAlignLast: "center" },
};

export default function InterestTable(props) {
  const { actions, create, tableLimit, header, data } = props;

  const rows = (x, i, iProps) => {
    const isEditing = iProps.editIdx === i;

    return isEditing ? (
      <TableRow key={`tr-${i}`} style={{ textAlignLast: "center" }}>
        <InlineEditForm table={iProps} x={x} i={i} style={style} />
      </TableRow>
    ) : (
      <TableRow key={`tr-${i}`}>
        <TableCell style={style.TableCell}>{i + 1}</TableCell>
        {iProps.header.map((y, k) => {
          let cellValue = x[y.prop];
          if (y.type === "select") {
            const option = y.options.find(opt => opt.value === cellValue);
            cellValue = option ? (option.label || option.name) : cellValue;
          }
          return (
            <TableCell style={style.TableCell} key={`tc-${k}`}>
              {cellValue.toLocaleString("en-US")}
            </TableCell>
          );
        })}
        {actions ? (
          <TableCell style={style.TableCell}>
            <IconButton onClick={() => iProps.handleStartEdit(i)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => iProps.handleRemove(i)}>
              <DeleteIcon />
            </IconButton>
          </TableCell>
        ) : null}
      </TableRow>
    );
  };

  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow style={{ textAlignLast: "center" }}>
            <TableCell style={style.TableCell}>No.</TableCell>
            {header.map((x, i) => (
              <TableCell style={style.TableCell} key={`thc-${i}`}>
                {x.name}
              </TableCell>
            ))}
            {actions ? (
              <TableCell style={style.TableCell}>Acciones</TableCell>
            ) : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {data ? data.map((x, i) => rows(x, i, props)) : null}
        </TableBody>
        <TableFooter>
          {create ? (
            data.length < tableLimit ? (
              <InlineCreateForm table={props} style={style} />
            ) : null
          ) : null}
        </TableFooter>
      </Table>
    </Paper>
  );
}
