import React from "react";

import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import moneyInput from "../../TextMaskInputComponents/moneyInput";
import moneyInputCustom from "../../TextMaskInputComponents/moneyInputCustom";
import { TableCell, Select } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

class InlineEditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.x,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleAutocompleteChange = this.handleAutocompleteChange.bind(this);
    this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  handleCurrencyChange(key) {
    return (event, maskedValue, floatValue) => {
      this.setState({ [key]: floatValue || "" });
    };
  }

  handleAutocompleteChange(key, obj) {
    /* Handle the state of Autocomplete components */
    if (obj) {
      this.setState({
        [key]: obj.value,
      });
    }
  }

  handleChange(key) {
    return (event) => {
      this.setState({
        [key]: event.currentTarget.value,
      });
    };
  }

  handleSelectChange(key) {
    return (event) => {
      this.setState({
        [key]: event.target.value,
      });
    };
  }

  onSubmit = (e) => {
    e.preventDefault();

    let nextState = { ...this.state };
    const from = this.state.from;
    const to = this.state.to
    const interestRate = this.state.interestRate;

    if(this.props.table.calculatorType === "service_order" && nextState.interestRate !== interestRate) {
      if (from === "" && to === 3) {
        nextState = { ...nextState, from: 0, to: 3, interestRate: 1 }
      }

      if (from === 4 && to === 6) {
        nextState = { ...nextState, from: 4, to: 6, interestRate: 2 }
      }

      if (from === 7 && to === 12) {
        nextState = { ...nextState, from: 7, to: 12, interestRate: 3 }
      }

      if (from === 13 && to === 18) {
        nextState = { ...nextState, from: 13, to: 18, interestRate: 4 }
      }

      if (from === 19 && to === 24) {
        nextState = { ...nextState, from: 19, to: 24, interestRate: 5 }
      }

      this.setState(nextState);
      this.props.table.handleChange(this.props.i, nextState);
    } else {
      this.props.table.handleChange(this.props.i, this.state);
    }
  };

  render() {
    const pProps = this.props.table;
    const { i, style } = this.props;
    return [
      <TableCell style={style.TableCell} key="index-cell">
        {i + 1}
      </TableCell>,
      pProps.header.map((y, k) => (
        <TableCell style={style.TableCell} key={`tc-${k}`}>
          {y.type === "select" ? (
            <Select
              disabled={y.disabled}
              onChange={this.handleSelectChange(y.prop)}
              value={this.state[y.prop]}
              native={true}
              inputProps={{
                id: y.prop,
                name: y.prop,
              }}
            >
              <option defaultValue="">---Elija una opcion---</option>
              {y.options.map((opt, indx) => {
                return (
                  <option key={indx} value={opt.value}>
                    {opt.label || opt.name || opt.fullName}
                  </option>
                );
              })}
            </Select>
          ) : y.type === "numberOnly" ? (
            <TextField
              disabled={y.disabled}
              placeholder={y.name}
              name={y.prop}
              type="number"
              onChange={this.handleChange(y.prop)}
              value={this.state[y.prop]}
            />
          ) : y.type === "number" ? (
            <TextField
              disabled={y.disabled}
              placeholder={y.name}
              name={y.prop}
              onChange={this.handleCurrencyChange(y.prop)}
              value={this.state[y.prop]}
              InputProps={ this.props.table.calculatorType === "service_order" && k !== 2 ? { inputComponent: moneyInputCustom } : { inputComponent: moneyInput } }
            />
          ) : y.type === "auto" ? (
            <Autocomplete
              id={y.prop}
              options={y.options}
              getOptionLabel={(options) => options.fullName}
              onChange={(e, value) =>
                this.handleAutocompleteChange(y.prop, value)
              }
              renderInput={(params) => (
                <TextField {...params} value={this.state[y.prop]} />
              )}
            />
          ) : (
            <TextField
              disabled={y.disabled}
              placeholder={y.name}
              type={y.type}
              name={y.prop}
              onChange={this.handleChange(y.prop)}
              value={this.state[y.prop]}
            />
          )}
        </TableCell>
      )),
      <TableCell style={style.TableCell} key="icon-cell">
        <IconButton onClick={this.onSubmit}>
          <CheckIcon />
        </IconButton>
        <IconButton onClick={pProps.handleStopEdit}>
          <CloseIcon />
        </IconButton>
      </TableCell>,
    ];
  }
}

export default InlineEditForm;
