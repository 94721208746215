import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { ToolbarComponent } from "./../../../components/SharedComponents/Toolbar";
import { LeadsTable } from "./../Tables";
import {
  LeadsDialog,
  ConfirmDialogComponent,
  SelectSavingsAccDialog,
} from "./../Dialogs";
import {
  SuccessDialog,
  ErrorDialog,
  SimpleConfirmDialog,
} from "../../../components/SharedComponents/Dialogs";
import { Filters } from "./../Filters";
import {
  fetchAssignees,
  fetchLeads,
  createLead,
  updateLead,
  deleteLead,
  fetchNationalities,
  fetchRegions,
  fetchStates,
  fetchCities,
  fetchProfessions,
  fetchInstitutions,
  complianceStateLead,
  hrStateLead,
  businessStateLead,
  cancelStateLead,
  uploadFilesLead,
  completeLead,
  fetchDepartments,
  fetchPositions,
  fetchInterests,
  createQuotation,
  createRohiLead,
  createOutlayTicket,
  createOutlayLeads,
} from "./../../../utils/axios";
import LoadingDialog from "../../../components/SharedComponents/LoadingScreen";
import {
  errorHandling,
  handleToggleDialog as handleDialog,
  handleToggleSimpleDialog as handleSimpleDialog,
} from "../../../components/SharedComponents/CustomHooks";
import {
  leadStatusOrders,
  formattedFieldsData,
  fieldsTranslations,
  getInitialStates,
  createStateObject,
} from "./../GeneralMethods";
import axios from "axios";
import jwtDecode from "jwt-decode";
import "./../main.css";
import { Prompt } from "react-router";
import {
  fractionationType,
  getFunctionalDiscount,
  rohiAccountsType,
} from "src/utils";

function LeadsView(props) {
  const ignoreFlattenFields = [
    "financialCertificate",
    "addressFile",
    "identificationFile",
    "blacklist",
  ];
  const limitRequest = { limit: 25 };
  const dialogTypes = ["leads"];
  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState({});
  const [simpleDialog, setSimpleDialog] = useState({
    name: "",
    data: null,
    callback: null,
  });
  const [leads, setLeads] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selected, setSelected] = useState([]);
  const [generalData, setGeneralData] = useState({});
  const [roles, setRoles] = useState([]);
  const [selectedLeadData, setSelectedLeadData] = useState(null);
  const [isSuperUser, setIsSuperUser] = useState(false);
  const [loggedUser, setLoggedUser] = useState("");
  const [savingsDialog, setSavingsDialog] = useState({});
  const [approval, setApproval] = useState([]);
  const handleToggleDialog = handleDialog.bind(this, setDialog, dialog);
  const handleToggleSimpleDialog = handleSimpleDialog.bind(
    this,
    setSimpleDialog
  );

  const deepFindErrs = (errs, newResult, parentKey) => {
    let result = newResult || {};
    for (const property in errs) {
      const isArr = Array.isArray(errs[property]);
      if (isArr) {
        const translation =
          `Campo <strong>${fieldsTranslations[property] ||
            property}:</strong>` || "";
        result[property] = `<span>${translation + " " + errs[property]}</span>`;
      } else {
        deepFindErrs(errs[property], result, property);
      }
    }
    return result;
  };

  const handleFieldErrors = (setErrors, errors) => {
    const errObj = errors?.response?.data;
    let formattedErrors = {};
    if (typeof errObj === "object") {
      formattedErrors = deepFindErrs(errObj);
    }
    let err = errObj || "";
    if (!Object.keys(formattedErrors || {}).length && !err) {
      err = {
        response: {
          data: "Un error técnico ha ocurrido.",
        },
      };
    } else {
      err = { response: { data: formattedErrors } };
    }
    if (!errObj) err = "";
    errorHandling(err, handleToggleSimpleDialog);
    if (setErrors && err) {
      setErrors((prevState) => ({ ...prevState, errors: errObj || {} }));
    }
  };

  const getInitData = () => {
    getLeads(limitRequest);
    getPersonalInfoData();
  };

  const getPersonalInfoData = () => {
    const token = { token: props.user.token };
    const nationalities = fetchNationalities(token);
    const regions = fetchRegions(token);
    const states = fetchStates(token);
    const cities = fetchCities(token);
    const professions = fetchProfessions(token);
    const departments = fetchDepartments(props.user.token);
    const positions = fetchPositions({ token: props.user.token });
    const financialInstitutions = fetchInstitutions({
      ...token,
      institutionType: "financial",
    });
    const endpoints = [
      nationalities,
      regions,
      states,
      cities,
      professions,
      financialInstitutions,
      departments,
      positions,
    ];
    axios
      .all(endpoints)
      .then(
        axios.spread((...responses) => {
          const data = {
            nationalities: responses[0].data,
            regions: responses[1].data,
            states: responses[2].data,
            cities: responses[3].data,
            professions: responses[4].data,
            institutions: responses[5].data.map((i) => ({
              fullName: `${i.abbreviation} - ${i.name}`,
              name: i.name,
              uniqueId: i.uniqueId,
            })),
            departments: responses[6].data,
            positions: responses[7].data,
          };
          setGeneralData({ ...data });
        })
      )
      .catch((errors) => {
        setLoading(false);
        errorHandling(errors, handleToggleSimpleDialog);
      });
  };

  const flattenData = (data, newResult, parentKey) => {
    let result = newResult || {};
    for (const property in data) {
      // FIRST CONDITION ONLY TO HANDLE REFERENCE FIELDS
      if (ignoreFlattenFields.includes(property)) {
        result[property] = data[property];
      } else {
        if (
          Array.isArray(data[property]) ||
          typeof data[property] !== "object" ||
          !data[property]
        ) {
          // HANDLE UNIQUEID
          if (parentKey && property === "uniqueId") {
            result[`${property}_${parentKey}`] = data[property];
          } else if (result.hasOwnProperty(property)) {
            result[`${parentKey || ""}_${property}`] = data[property];
          } else {
            let value = data[property];
            result[property] = value;
          }
        } else {
          flattenData(data[property], result, property);
        }
      }
    }
    return result;
  };

  const formatData = (response) => {
    const data = response.data.results ? response.data.results : response.data;
    let formattedData = [];
    data.forEach((item) => {
      const newData = flattenData(item);
      formattedData.push(newData);
    });
    return formattedData;
  };

  const getLeads = (data) => {
    setLoading(true);
    const endpoint = fetchLeads({ ...data, token: props.user.token });
    endpoint
      .then((response) => {
        const formattedData = formatData(response);
        setLeads(formattedData);
        setLoading(false);
      })
      .catch((errors) => {
        setLoading(false);
        errorHandling(errors, handleToggleSimpleDialog);
      });
  };

  const saveCreateLead = async (data, action, setSectionErrors) => {
    if (setSectionErrors) {
      setSectionErrors({ errors: null, formatted: null });
    }
    setLoading(true);
    let endpoint;
    if (action === "update") {
      endpoint = updateLead(props.user.token, data.uniqueId, data);
    } else {
      endpoint = createLead(props.user.token, data);
    }
    return await endpoint
      .then((response) => {
        getLeads(limitRequest);
        setLoading(false);
        handleToggleSimpleDialog("success-dialog");
        if (action === "create") handleToggleDialog("leads");
        if (action === "update") {
          const newData = flattenData(response.data);
          setSelectedLeadData({ ...newData });
        }
        return response.data.uniqueId;
      })
      .catch((errors) => {
        setLoading(false);
        if (setSectionErrors) {
          handleFieldErrors(setSectionErrors, errors);
        } else {
          errorHandling(errors, handleToggleSimpleDialog);
        }
        return false;
      });
  };
  const getUser = () => {
    const userId = jwtDecode(props.user.token).user_id;
    fetchAssignees({ token: props.user.token, id: userId }).then((response) => {
      const data = response.data[0];
      setLoggedUser(data.username);
      setIsSuperUser(data.isSuperuser);
      setRoles(data.groups);
    });
  };

  const getInterestApproval = async (data) => {
    try {
      const calculatorType = data.fromNominaAgil ? "ap_lvl_na" : "ap_lvl_sl";
      const response = await fetchInterests({
        token: props.user.token,
        calculatorType: calculatorType,
      });
      const descr = response.data[0]?.description;

      if (!descr || descr.length === 0) {
        console.error(
          "No se encontraron niveles de aprobacion, por favor crearlos en el pool de tasa."
        );
      } else {
        setApproval(descr);
      }
    } catch (err) {
      console.error("Error al obtener nivel de aprobacion: ", err);
    }
  };

  const AnalisisAproval = (data) => {
    if (approval.length > 0) {
      const amount = parseFloat(data.amount);
      const matchingLevel = approval.find(
        (item) => amount >= item.from && amount <= item.to
      );
      if (!matchingLevel) {
        console.error(
          "No se encontró un nivel de aprobación correspondiente para el monto especificado."
        );
        return false;
      }
      const hasRole = roles.includes(matchingLevel.level);
      return hasRole;
    } else {
      return false;
    }
  };

  // method to set all states and validations to show correctly.
  const leadStates = (data) => {
    const bl = data.blacklist;
    const isFlagged = bl
      ? Boolean(Object.keys(bl || {}).find((key) => bl[key] === true))
      : false;
    const initialStates = getInitialStates(data, roles);

    const analysisApp = AnalisisAproval(data);
    let newStateData = [];
    initialStates.forEach((state, index) => {
      const stateObj = createStateObject(data, state, roles, analysisApp);
      newStateData.push({
        ...stateObj,
        state,
        order: index + 1,
      });
    });

    if (!newStateData.some((state) => state.state === "canceled")) {
      const canceledState = createStateObject(data, "canceled", roles);
      newStateData.unshift(canceledState);
    }

    if (isFlagged) {
      const complianceIndex = newStateData.findIndex(
        (state) => state.state === "compliance_review_pending"
      );
      if (complianceIndex === -1) {
        const pendingEvaluationIndex = newStateData.findIndex(
          (state) => state.state === "pending_evaluation"
        );
        if (pendingEvaluationIndex !== -1) {
          newStateData.splice(
            pendingEvaluationIndex + 1,
            0,
            createStateObject("compliance_review_pending", roles)
          );
        }
      }
    }

    return newStateData;
  };

  // methods that fires when clicking state buttons:
  const handleStates = (leadData, leadInfo) => {
    let formattedLeadData = formattedFieldsData(leadData, leadData, {
      institution: leadInfo.institution,
    });
    const currState = formattedLeadData.state;
    let next = "";
    if (leadInfo.currBtnState === "canceled") {
      next = leadInfo.currBtnState;
    } else {
      next =
        currState === "signature_completed"
          ? "complete"
          : leadInfo.nextOrder || "signature_completed";
    }
    //create quotation if next is analysis pending
    if (next === "analisis_pending") {
      const formatted = {
        ...formattedLeadData,
        [formattedLeadData.hasOwnProperty("_state")
          ? "_state"
          : "state"]: "analisis_pending",
      };
      generateQuotation(
        leadData,
        formatted,
        leadInfo.institution,
        leadInfo.setSectionErrors
      );
      return;
    }
    // create participant and their documents if next state is pending signature
    if (next === "pending_signature") {
      handleCreatePartner({
        leadData: {
          ...formattedLeadData,
          [formattedLeadData.hasOwnProperty("_state")
            ? "_state"
            : "state"]: "pending_signature",
        },
        setSectionErrors: leadInfo.setSectionErrors,
      });
      return;
    }
    //send information to rohi if next state is registration
    if (next === "registration_pending") {
      handleRohiData({
        leadData: {
          ...formattedLeadData,
          [formattedLeadData.hasOwnProperty("_state")
            ? "_state"
            : "state"]: "registration_pending",
        },
        setSectionErrors: leadInfo.setSectionErrors,
      });
      return;
    }

    if (next === "complete" && formattedLeadData.quotation) {
      const formatted = {
        ...formattedLeadData,
        [formattedLeadData.hasOwnProperty("_state")
          ? "_state"
          : "state"]: "complete",
      };
      setSavingsDialog({
        formattedData: formatted,
        lead: leadData,
        sectionErrors: leadInfo.setSectionErrors,
      });
      handleToggleSimpleDialog("select-savingsAcc");
      return;
    }

    setLoading(true);
    const endpoint = updateLead(props.user.token, leadData?.uniqueId, {
      ...formattedLeadData,
      state: next,
    });
    endpoint
      .then((res) => {
        // refresh leads data behing.
        getLeads(limitRequest);
        handleToggleSimpleDialog("success-dialog");
        if (leadInfo.order) {
          setSelectedLeadData(() => {
            let data = flattenData(
              res.data.results ? res.data.results : res.data
            );
            if (data.hasOwnProperty("_state")) {
              data._state = next;
            } else {
              data.state = next;
            }
            return data;
          });
        } else {
          handleToggleDialog("leads");
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        // nullified due to object provided
        errorHandling(null, handleToggleSimpleDialog);
      });
  };

  const formatDate = (dateObj) => {
    // Agregar un 0 en caso de que mes o dia sean menor a 9
    const month =
      dateObj.getMonth() + 1 <= 9
        ? `0${dateObj.getMonth() + 1}`
        : dateObj.getMonth() + 1;
    const day =
      dateObj.getDate() <= 9 ? `0${dateObj.getDate()}` : dateObj.getDate();

    return `${dateObj.getFullYear()}-${month}-${day}`;
  };

  const generateQuotation = async (
    option,
    formattedData,
    institution,
    setSectionErrors
  ) => {
    let affiliationAmount = 0;
    let contributionAmount = 0;
    let savingsWarranty = 0;
    let paymentDay = "";
    const isAffiliate = institution.state === "affiliate";

    if (institution.paymentFrequency === "biweekly") {
      paymentDay = [
        institution.paymentDay || option.paymentDay,
        institution.secondPaymentDay || option.secondPaymentDay,
      ];
    } else {
      paymentDay = institution.paymentDay || option.paymentDay;
    }
    const response = await fetchInterests({
      calculatorType: "membership_breakddown",
      token: props.user.token,
    });
    const data = response?.data[0]?.description;
    if (isAffiliate) {
      contributionAmount = data.find((item) => item.reason === "contributions")
        .affiliateRohiQuota;
    } else {
      contributionAmount =
        data.find((item) => item.reason === "contributions")
          .notAffiliateRohiQuota || 0;
    }

    if (option.membershipFinanced) {
      if (isAffiliate) {
        affiliationAmount = data.reduce((total, item) => {
          return total + parseFloat(item.affiliated);
        }, 0);
      } else {
        affiliationAmount = data.reduce((total, item) => {
          return total + parseFloat(item.notaffiliated);
        }, 0);
      }
    }

    if (option.savingsFinanced) {
      savingsWarranty = parseFloat(option.amount) * 0.05;
    }

    let totalRequest =
      parseFloat(option.amount) + savingsWarranty + affiliationAmount;
    let result = totalRequest / (97 / 100);

    let originalAmount = Math.ceil(result / 5) * 5;

    let dt = new Date(new Date().toISOString().split("T")[0]);
    dt.setDate(paymentDay);
    dt.setUTCMonth(dt.getMonth() + 1);
    let firstInstallmentDate = formatDate(dt);

    const savingFees = await getFunctionalDiscount(
      originalAmount,
      props.user.token
    );

    //calcular fraccionamiento
    let today = new Date(new Date().toISOString().split("T")[0]);
    let nextPaymentDate = new Date(today);
    nextPaymentDate.setDate(paymentDay);
    if (nextPaymentDate <= today) {
      nextPaymentDate.setUTCMonth(nextPaymentDate.getMonth() + 1);
    }
    let differenceInDays = (nextPaymentDate - today) / (1000 * 60 * 60 * 24);

    let quotationInfo = {
      affiliationAmount: affiliationAmount,
      clientType: "new regular",
      commissionAmount: 0,
      company: "COOPERATIVA BARCELONA",
      administrativeExpensesAmount: originalAmount - totalRequest,
      administrativeExpensesRate: 3,
      administrativeExpensesType: "discounted",
      comissionRate: 0.1,
      emittedBy: loggedUser,
      financialConfig: "staggered",
      productType: "constant_fees",
      firstInstallmentDate: firstInstallmentDate,
      hasFractionation: true,
      fractionationType: differenceInDays <= 5 ? "condonated" : "conventional",
      hasSpp: false,
      insuranceAmount: 0.2,
      insuranceType: "prorated",
      interestRate:
        institution.institutionInterestsRate > 0
          ? institution.institutionInterestsRate
          : 2.55,
      loanType: "jointGuarantee",
      originalAmount: originalAmount,
      otherFees: 0,
      savingFees: savingFees,
      savingsWarranty: savingsWarranty,
      contributionFees: contributionAmount,
      outlayDate: formatDate(dt),
      quotationDate: formatDate(dt),
      paymentDay: paymentDay,
      periodicity: institution.paymentFrequency || "monthly",
      requestAmount: option.amount,
      timeLimit: option.term,
      transferAmount: option.amount,
      warrantyType: "solidarity",
      paymentMethod: isAffiliate ? "payroll_discount" : "automatic_debit",
      finality: "consumption",
      clientName: option.fullName,
      identificationNumber: option.identificationNumber,
      identificationType: option.identificationType,
    };
    if (!formattedData.quotation) {
      const response = await createQuotation(props.user.token, quotationInfo);
      saveCreateLead(
        { ...formattedData, quotation: response.data?.uniqueId },
        "update",
        setSectionErrors
      );
    } else {
      saveCreateLead(formattedData, "update", setSectionErrors);
    }
  };

  const handleCreatePartner = (options) => {
    options.setSectionErrors({ errors: null, formatted: null });
    setLoading(true);
    const endpoint = completeLead(props.user.token, options.leadData.uniqueId);
    endpoint
      .then(() => {
        // if everything is ok, update lead to complete state and finish ok.
        saveCreateLead(options.leadData, "update", options.setSectionErrors);
      })
      .catch((errors) => {
        handleFieldErrors(options.setSectionErrors, errors);
        setLoading(false);
      });
  };

  const handleRohiData = (options) => {
    options.setSectionErrors({ errors: null, formatted: null });
    setLoading(true);
    const endpoint = createRohiLead(
      props.user.token,
      options.leadData.uniqueId
    );
    endpoint
      .then(() => {
        saveCreateLead(options.leadData, "update", options.setSectionErrors);
      })
      .catch((errors) => {
        handleFieldErrors(options.setSectionErrors, errors);
        setLoading(false);
      });
  };

  const handleCreateTicket = (options) => {
    const { formattedData, lead, savingAccount, sectionErrors } = options;
    sectionErrors({ errors: null, formatted: null });
    setLoading(true);
    const savingAcc = {
      tipo_cuenta:
        rohiAccountsType.find((t) => t.value === savingAccount.type)?.label ||
        savingAccount.type,
      account_number: savingAccount.accountNumber,
      balance: 0,
    };
    const params = {
      is_lead: true,
      lead: lead.uniqueId,
      participant: lead.crmParticipant,
      quotation: lead.quotation,
      saving_acc: savingAcc,
    };
    if (!lead.ticket) {
      const endpoint = createOutlayTicket(props.user.token, params);
      endpoint
        .then((res) => {
          if (res.data?.ticket) {
            const opt = {
              participant: lead.crmParticipant,
              quotation: lead.quotation,
              ticket: res.data?.ticket,
            };
            handleCreateOutlay(opt, formattedData, sectionErrors);
          } else {
            handleFieldErrors(sectionErrors);
          }
        })
        .catch((errors) => {
          handleFieldErrors(sectionErrors, errors);
          setLoading(false);
        });
    } else {
      const opt = {
        participant: lead.crmParticipant,
        quotation: lead.quotation,
        ticket: lead.ticket,
      };
      handleCreateOutlay(opt, formattedData, sectionErrors);
    }
  };

  const handleCreateOutlay = (options, formattedData, sectionErrors) => {
    if(formattedData.creditForm?.settlementDisbursment !== "transfer"){
      setLoading(true);
      const endpoint = createOutlayLeads(props.user.token, options);
      endpoint
        .then(() => {
          saveCreateLead(formattedData, "update", sectionErrors);
        })
        .catch((errors) => {
          handleFieldErrors(sectionErrors, errors);
          setLoading(false);
        });
    } else{
      saveCreateLead(formattedData, "update", sectionErrors);
    }
  };

  const deleteSingleLead = (receipt) => {
    // setLoading(true);
    // const endpoint = deleteLead(props.user.token, receipt.uniqueId);
    // endpoint.then(() => {
    //     getLeads(selectedFilters);
    //     setLoading(false);
    //     handleToggleSimpleDialog('success-dialog');
    // }).catch(errors => {
    //     setLoading(false);
    //     errorHandling(errors, handleToggleSimpleDialog);
    // });
  };
  const handleDeleteMultipleLeads = () => {
    // const endpoints = selected.map(item => deleteLead(props.user.token, item.uniqueId));
    // axios.all(endpoints).then(() => {
    //     getLeads(selectedFilters);
    //     setSelected([]);
    //     setLoading(false);
    //     handleToggleSimpleDialog('success-dialog');
    // }).catch(errors => {
    //     setLoading(false);
    //     errorHandling(errors, handleToggleSimpleDialog);
    // });
  };
  const handleSelected = (selection) => {
    setSelected(selection);
  };

  const applyFilters = (fields) => {
    let data = fields;
    if (Object.keys(fields).length === 0) {
      data = limitRequest;
    }
    getLeads(data);
    setSelectedFilters({ ...data });
  };

  useEffect(() => {
    getInitData();
    getUser();
    // prevent page from leaving;
    window.onbeforeunload = function() {
      return "";
    };
  }, []);

  // useEffect(() => {
  //     // stop fetch dialog
  //     if(leads?.length && Object.keys(generalData).length) {
  //         setLoading(false);
  //     }
  // }, [leads, generalData])

  useEffect(() => {
    if (selectedLeadData) setSelectedLeadData(null);
    if (dialog["leads"]?.data?.amount) {
      getInterestApproval(dialog["leads"]?.data);
    }
  }, [dialog]);

  return (
    <div
      style={{
        padding: "20px 20px 10% 20px",
        maxWidth: "100rem",
        margin: "0 auto",
      }}
    >
      <Prompt
        when={Boolean(dialog["leads"])}
        message="¿Quiere irse de la página?"
      />
      <LoadingDialog open={loading} />
      <ToolbarComponent
        typeVariant="h6"
        typeClass="title-text"
        toolbarTitle="GESTIÓN DE LEADS"
        btnVariant="contained"
        toolbarActions={
          <Button
            variant="contained"
            className={"action-method-btn"}
            size="small"
            disableElevation
            onClick={() => handleToggleDialog("leads")}
          >
            nueva solicitud
          </Button>
        }
      />
      <div
        style={{
          padding: "1%",
          border: "1px solid #EEEEEE",
          borderTop: "none",
        }}
      >
        <Filters
          handleToggleSimpleDialog={handleToggleSimpleDialog}
          applyFilters={applyFilters}
        />
        <div style={{ margin: "10px 0" }}></div>
        <LeadsTable
          data={leads}
          user={props.user}
          handleToggleDialog={handleToggleDialog}
          handleToggleSimpleDialog={handleToggleSimpleDialog}
          handleDelete={deleteSingleLead}
          handleDeleteMultipleLeads={handleDeleteMultipleLeads}
          selected={selected}
          setSelected={setSelected}
          handleSelected={handleSelected}
          setLoading={setLoading}
        />
      </div>
      {dialog["leads"]?.open ? (
        <LeadsDialog
          open={true}
          handleToggleDialog={handleToggleDialog}
          handleSimpleDialog={handleToggleSimpleDialog}
          data={selectedLeadData || dialog["leads"].data}
          leadStates={leadStates}
          modify={dialog["leads"].data ? true : false}
          dialogTitle={
            dialog["leads"].data
              ? "Información de Solicitud"
              : "Nueva Solicitud"
          }
          generalData={generalData}
          saveCreateLead={saveCreateLead}
          setLoading={setLoading}
          token={props.user.token}
          isSuperUser={isSuperUser}
          handleStates={handleStates}
          getLeads={() => getLeads(limitRequest)}
          handleFieldErrors={handleFieldErrors}
        />
      ) : null}
      {simpleDialog.name === "confirm-dialog" ? (
        <ConfirmDialogComponent
          open={true}
          data={simpleDialog}
          handleToggle={handleToggleSimpleDialog}
        />
      ) : null}
      {simpleDialog.name === "simple-dialog" ? (
        <SimpleConfirmDialog
          open={true}
          handleToggle={handleToggleSimpleDialog}
          data={simpleDialog.msg}
        />
      ) : null}
      {simpleDialog.name === "success-dialog" ? (
        <SuccessDialog open={true} handleToggle={handleToggleSimpleDialog} />
      ) : null}
      {simpleDialog.name === "error-dialog" ? (
        <ErrorDialog
          open={true}
          handleToggle={handleToggleSimpleDialog}
          data={simpleDialog.msg}
        />
      ) : null}

      {simpleDialog.name === "select-savingsAcc" ? (
        <SelectSavingsAccDialog
          open={true}
          handleClose={handleToggleSimpleDialog}
          createTicket={handleCreateTicket}
          data={savingsDialog}
        />
      ) : null}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    selectedSection: state.selectedSection,
  };
}
export default connect(mapStateToProps)(LeadsView);
