import {
  Box, Button, Card, CardContent, Dialog, DialogActions, 
  DialogContent, Divider, Grid, IconButton, Paper, Tab, 
  TableContainer, Toolbar, Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  Loop, Settings, Visibility, Save
} from "@material-ui/icons";
import React, { useEffect, useState, useRef } from "react";
import { errorHandling } from "src/components/SharedComponents/CustomHooks";
import {
  CheckboxField, TextFieldComponent, RadioGroupComponent
} from "src/components/SharedComponents/Fields";
import NumberFormat from "src/components/SharedComponents/NumberFormat";
import SettlementFormsTable from "src/components/TicketWorkflows/Outlay/Form";
import QuotationSelectInput from "src/containers/QuotationFiles/QuotationSelectInput";
import WarrantyTable from "src/containers/Warranty/WarrantySelectInput/warrantyTable";
import { 
  fetchDebtCapacities, fetchParticipant, fetchPaymentCapacities, fetchPotentialMemberValidation, 
  fetchRohiClientLoansInfo, getCreditDebtCapacity, simpleLoanCalulator, ticketInfo, fetchTime, rohiLoans
} from "src/utils/axios";
import { transactionTypes } from "src/utils";
import {
  EvalFormsSettlementTable, DebtCapacityTable, PayCapacityTable, 
  FinancingTable, ArrearsTable, LoanTransactionTable
} from "./../../Tables";
import { 
  getClientBankAccounts, getQuotationData, 
  handleAddQuotation, handleDelete, handleManageChecks, handleModify, 
  handleNextStep, handleRemoveQuotation, handleState, handleViewQuotation, 
  StepStatus, transaction_types, validateCreditMethodTablesEmpty, 
  validateSettlementTotal, CustomTooltip, ticketHistoryGeneral, 
  DisbursementGeneralBadges, getBankAccounts, saveSettlementTable, 
  ViewQuotationText, useCheckStepStatus
} from "./../../TicketGeneralMethods";
import { settlementInit } from "./../../TicketGeneralMethods/consts";
import { green, orange, red } from "@material-ui/core/colors";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { GeneralTable } from "src/components/SharedComponents/Table";
import AccountStatus from "src/containers/AccountStatus";


function SignDecision(props) {

  const [clientSignObj, setClientSignObj] = useState({
    signDocuments: "",
    specialCase: false,
    specialCaseText: "",
  });

  const handleClientSignObj = (type, event, value) => {
    const val = event.target.value ? event.target.value : value;
    setClientSignObj({ ...clientSignObj, [type]: val });
  };

  useEffect(() => {
    props.currentTicketStep.forEach((r) => {
      if (clientSignObj.hasOwnProperty(r.name)) {
        const result = {...clientSignObj, [r.name]: r.value }
        setClientSignObj(prev => ({...prev, [r.name]: r.value}));
      }
    });
  }, [props.currentTicketStep]);

  useEffect(() => {
    props.clientSignObj.current = clientSignObj;
  }, [clientSignObj]);

  return(
    <div>
      <Typography
        variant={"subtitle1"}
        className={"section-header"}
        style={{
          fontWeight: "bold",
          color: "#424242",
          marginLeft: 5,
          marginTop: "2rem",
        }}
      >
        DECISIÓN DE FIRMA
      </Typography>
      <Divider style={{ marginBottom: "2rem" }} />
      <Grid
        container
        item
        xs={12}
        direction="column"
        justifyContent={"flex-start"}
        spacing={3}
      >
        <RadioGroupComponent
          typeVariant="subtitle1"
          typeClass="field-label"
          fieldLabel="Seleccione una opción"
          fieldID="radio-field-bot-init-option"
          fieldClass="fields"
          row={true}
          value={clientSignObj.signDocuments || ""}
          options={[
            { text: "SE LE ENVIARÁN LOS DOCUMENTOS", value: "sendDocuments" },
            { text: "FIRMA PRESENCIAL", value: "faceToFaceSignature" },
            { text: "PAGARÉ POR LÍNEA", value: "notarialPayEqualLoan" },
          ]}
          onChange={handleClientSignObj.bind(this, "signDocuments")}
          disabled={props.ticketCompleted}
        />
        <CheckboxField
          xs={12}
          alignItems="flex-end"
          check={clientSignObj.specialCase}
          handleChange={handleClientSignObj.bind(this, "specialCase")}
          color="primary"
          textColor="#43A047"
          label="¿HACER EXCEPCIÓN?"
          disabled={props.ticketCompleted}
        />
        {clientSignObj.specialCase && (
          <TextFieldComponent
            xs={6}
            typeVariant="subtitle1"
            typeClass="field-label"
            fieldLabel="Caso especial"
            fieldID="contract_validation_special_description"
            fieldClass="fields"
            fieldVariant="outlined"
            placeholder="Descripción caso especial..."
            margin="dense"
            fullWidth
            value={clientSignObj.specialCaseText || ""}
            onChange={handleClientSignObj.bind(this, "specialCaseText")}
            disabled={props.ticketCompleted}
            multiline={true}
            rows={6}
          />
        )}
      </Grid>
    </div>
  )
}

export default function EvalFormsSettlement(props) {

    const stepStatus = useCheckStepStatus(props.ticket.steps, props.currentStep);

    const [formIds, setFormIds] = useState({});
    const [hasWarranty, setHasWarranty] = useState(false);
    const [warrantyIndex, setWarrantyIndex] = useState([]);
    const [participantData, setParticipantData] = useState({});
    const [averageRate, setAverageRate] = useState("0");
    const [tableData, setTableData] = useState([]);
    const [settlementData, setSettlementData] = useState(null);
    const [settlementInfo, setSettlementInfo] = useState({});
    const [previousChecks, setPreviousChecks] = useState(null);
    const [creditor, setCreditor] = useState({});
    const [creditorAccounts, setCreditorAccounts] = useState([]);
    const [clientBankAccounts, setClientBankAccounts] = useState([]);
    const [quotationData, setQuotationData] = useState({});
    const [approvalObj, setApprovalObj] = useState({});
    const [ticketInfoData, setTicketInfoData] = useState([]);
    const [debtCapacity, setDebtCapacity] = useState([]);
    const [suggested, setSuggested] = useState(0);
    const [debtCapacities, setDebtCapacities] = useState([]);
    const [payCapacity, setPayCapacity] = useState([]);
    const [accountStatusDialog, setAccountStatusDialog] = useState(false);
    const [quota1, setQuota1] = useState(0);
    const [quota2, setQuota2] = useState(0);
    const [rohiLoansInfo, setRohiLoansInfo] = useState([]);
    const [payCapacityTableData, setPayCapacityTableData] = useState([]);
    const [currentTicketStep, setCurrentTicketStep] = useState([]);
    let clientSignObj = useRef({});

    useEffect(() => {
      initBankAccounts();
    }, [])

    useEffect(() => {
      getRohiData();
    }, [props.reloadTicket]);
  
    useEffect(() => {
      setApprovalObj(
        props.ticket.steps.find((item) => item.approvalObj).approvalObj
      );
      const currTicketStep = props.ticket.steps.find(
        (item) => item.step === props.currentStep
      ).requirements;
      setCurrentTicketStep(currTicketStep);
    }, [props.ticket, props.reloadTicket]);

    useEffect(() => {
      const data = getPayCapacityData();
      setPayCapacityTableData([...data]);
    }, [payCapacity, debtCapacities, ticketInfoData])
  
    useEffect(() => {
      getTicketRequirements();
    }, [creditorAccounts, props.ticket, props.reloadTicket]);
  
    useEffect(() => {
      fetchQuotationData();
    }, [formIds, props.reloadTicket]);
  
    useEffect(() => {
      if (formIds.warranty) {
        if (!hasWarranty) {
          handleWarrantyChange();
        }
        setWarrantyIndex(formIds.warranty);
      }
    }, [formIds.warranty, props.reloadTicket]);
  
    useEffect(() => {
      if (settlementData) {
        const quoteData = quotationData && quotationData.requestAmount;
        const data = validateSettlementTotal(quoteData || null, settlementData);
        setSettlementInfo({ ...data });
      }
    }, [settlementData, quotationData]);
  
    useEffect(() => {
      if (quotationData?.uniqueId) {
        getCalculatorData(quotationData?.uniqueId);
      }else {
        setQuota1(0);
        setQuota2(0);
      }
    }, [quotationData]);
  
    useEffect(() => {
      if (Object.keys(ticketInfoData).length !== 0) {
        props.getDebtData({
          capacidad_endeudamiento: ticketInfoData[0].capacidad_endeudamiento,
          suggested: suggested,
          approvedAmount: debtCapacity[0]?.approvedAmount,
          applicableAmount: debtCapacity[0]?.applicableAmount,
        });
      }
    }, [ticketInfoData, debtCapacity, suggested]);

    const initBankAccounts = async () => {
      const ba = await getBankAccounts(props.token);
      const cba = await getClientBankAccounts({token: props.token, owner: props.ticket.customer});
      setCreditor(ba.creditor);
      setCreditorAccounts(ba.creditorAccounts);
      setClientBankAccounts(cba.clientBankAccounts);
    }
  
    const openAccountStatus = () => {
      setAccountStatusDialog(true);
    };
    const closeAccountStatus = () => {
      setAccountStatusDialog(false);
    };
  
    const handleWarrantyChange = () => {
      setHasWarranty(!hasWarranty);
    };
  
    const getDebtCapacity = async () => {
      const endpoint = getCreditDebtCapacity({
        accessToken: props.token,
        clientUniqueId: props.ticket.customer,
      });
      await endpoint.then((response) => {
          setDebtCapacity(response.data);
      }).catch((errors) => {
        props.setLoading(false);
        errorHandling(errors, props.handleToggleSimpleDialog);
      });
    };
  
    const getDebtCapacities = async () => {
      const endpoint = fetchDebtCapacities({
        token: props.token,
        customer: props.ticket.customer,
      });
      await endpoint.then((response) => {
          let currSuggestion = 0;
          response.data.forEach(
            (item) => (currSuggestion += item.modifierData.suggested)
          );
          setSuggested(parseFloat(currSuggestion).toFixed(2));
          setDebtCapacities(response.data);
      }).catch((errors) => {
          props.setLoading(false);
          errorHandling(errors, props.handleToggleSimpleDialog);
        });
    };
  
    const getPayCapacity = async () => {
      const endpoint = fetchPaymentCapacities({
        token: props.token,
        customer: props.ticket.customer,
      });
      await endpoint.then((response) => {
          setPayCapacity(response.data);
      }).catch((errors) => {
          props.setLoading(false);
          errorHandling(errors, props.handleToggleSimpleDialog);
      });
    };

    const fetchQuotationData = async () => {
      let data = {}
      if (formIds.quotation) {
        data = await getQuotationData(props, formIds.quotation);
      }
      setQuotationData(data);
    }
  
    const getTicketRequirements = () => {
      let forms = {};
      props.ticket.steps
        .find((item) => item.step === props.currentStep)
        .requirements.filter((item) => item.type === "form")
        .forEach((item) => {
          forms = { ...forms, [item.name]: item.value };
        });
      setFormIds(forms);
      const creditMethodTableData = props.ticket.steps
        .find((item) => item.step === props.currentStep)
        .requirements.find((item) => item.name === "creditMethodTables").value;
      const data = creditMethodTableData || settlementInit;

      if(data && data.creditMethods) {
        // REMOVE CASH FROM CREDIT METHODS AND SAVED VALUES;
        data.creditMethods = data.creditMethods.filter(item => item.value !== "cash");
        delete data.cash;
      }
      data.creditorAccounts = creditorAccounts;
      setSettlementData(data);
      setPreviousChecks([...data.checks]);
    };

    const getTicketInfo = async (participant) => {
      const endpoint = ticketInfo({
        token: props.token,
        associateNumber: participant.rohiId,
      });
     await endpoint.then((response) => {
          const ticketInfoData = response.data.map((item) => {
            return {
              capacidad_endeudamiento: Number(item.capacidad_endeudamiento),
              capacidad_pagos_info: item.capacidad_pagos_info,
              detalle_atrasos: item.detalle_atrasos,
              detalle_financiamientos: item.detalle_financiamientos,
              informacion_atrasos: item.informacion_atrasos[0],
              nombre_socio: item.nombre_socio,
              numero_identificacion: item.numero_identificacion,
              total_atraso: item.total_atraso,
            };
          });
          setTicketInfoData(ticketInfoData);
      }).catch((errors) => {
        props.setLoading(false);
        errorHandling(errors, props.handleToggleSimpleDialog);
      });
    };

    const getPayCapacityData = () => {
      let capacidadPagoData =
        ticketInfoData.length > 0 ? ticketInfoData[0].capacidad_pagos_info : [];
    
      const debtCapacitiesClean = debtCapacities.filter(
        (item) => item.modifierData.productType !== "regular_loan"
      );
      
      let tableData = {};
      let debt = [];
      let pay = [];
    
      debtCapacitiesClean.forEach((item) => {
        debt.push(item);
      });
      payCapacity.forEach((item) => {
        pay.push(item);
      });
      tableData = {  
        rohi: capacidadPagoData,
        debt,
        pay
      };

      const result = [];

      const constructTable = (row, type) => {
          const debtPayData = type === 'debt' ? row.modifierData : row.data;
          const rohiProduct = tableData.rohi?.find(rc => rc.tipo_capacidad_pago === transaction_types[debtPayData.productType]);
          let data = {};
          if(rohiProduct) {
            data.tipoCapacidadPago = rohiProduct.tipo_capacidad_pago;
            data.totalCapacidadPagos = rohiProduct.total_capacidad_pagos;
            data.totalAhorros = rohiProduct.total_ahorros;
            data.totalPrestamos = rohiProduct.total_prestamos;
          }else {
            data.tipoCapacidadPago = transaction_types[debtPayData.productType]
            data.totalCapacidadPagos = 0;
            data.totalAhorros = 0;
            data.totalPrestamos = 0;
          }
          data.capacidad = type === 'debt' ? 
            Array.isArray(debtPayData.debtCapacity) ? debtPayData.debtCapacity[0] : debtPayData.debtCapacity
          :
            debtPayData.paymentCapacity;
          data.disponible = data.capacidad - data.totalCapacidadPagos;
          return data;
      }

      tableData.debt.forEach(item => {
        const newData = constructTable(item, 'debt');
        result.push(newData);
      });
      tableData.pay.forEach(item => {
        const newData = constructTable(item, 'pay');
        result.push(newData);
      });
      tableData.rohi.forEach(rd => {
        const foundRow = result.find(r => r.tipoCapacidadPago === rd.tipo_capacidad_pago);
        let data = {}
        if(!foundRow) {
          data = {
            tipoCapacidadPago: rd.tipo_capacidad_pago,
            totalCapacidadPagos: rd.total_capacidad_pagos,
            capacidad: 0,
            disponible: -(rd.total_capacidad_pagos),
            totalAhorros: rd.total_ahorros,
            totalPrestamos: rd.total_prestamos,
          }
          result.push(data);
        }
      })
      return result;
    }

    const getRohiLoansInfo = async (participant) => {
      const endpoint = rohiLoans({
        token: props.token,
        associateNumber: participant.rohiId,
      });
      await endpoint.then((response) => {
          setRohiLoansInfo(response.data);
      }).catch((errors) => {
        props.setLoading(false);
        errorHandling(errors, props.handleToggleSimpleDialog);
      });
    }
  
    const getRohiData = () => {
      props.setLoading(true);
      const endpoint = fetchParticipant(props.token, props.ticket.customer);
      endpoint.then( async (response) => {
        await Promise.all([
          getDebtCapacities(),
          getPayCapacity(),
          getDebtCapacity(),
          getRohiLoansInfo(response.data),
          getTicketInfo(response.data),
        ]).then();
        setParticipantData(response.data);
        props.setLoading(false);
      }).catch((errors) => {
        props.setLoading(false);
        errorHandling(errors, props.handleToggleSimpleDialog);
      });
    };
    const getCalculatorData = (id) => {
      const endpoint = simpleLoanCalulator(
        { quotation_id: id },
        props.token
      );
      endpoint.then((response) => {
        const quota1 = response.data[0] ? response.data[0][3] : 0;
        const quota2 = response.data[1] ? response.data[1][3] : 0;
  
        setQuota1(quota1);
        setQuota2(quota2);
      });
    };

    const getYear = (date) => {
      if(date) {
        const formattedTime = date.substring(0, date.indexOf('T')) || date;
        const newDate = new Date(formattedTime);
        const currYear = newDate.getFullYear();
        return currYear;
      }
      return new Date();
    }

    const validateAvailabilityQuotation = async () => {
      if(debtCapacities.length === 0) {
        props.handleToggleSimpleDialog(
          "simple-dialog",
          `El socio no posee disponibilidad.`
        );
        return false;
      }
      const regularLoans = ["simple_loan", "constant_fees", "decreasing_fees", "salary_advance"];
      const date = await fetchTime().then(response => response.data);
      const currYear = getYear(date);
      
      const isRegular = regularLoans.includes(quotationData.productType);
      const hasPcp = quotationData?.pcpData?.length;

      const checkAvailability = (productType, date) => {
        let availability = debtCapacities.filter(dc => dc.modifierData?.productType === (isRegular && !date ? "regular_loan" : productType));
        if(!date) {
          if(availability?.length > 1) {
            return availability = availability.find(dc => getYear(dc.modifierData?.expirationDate) === currYear);
          }else if(availability?.length === 1){
            return availability = availability[0];
          }
        }else {
          availability = availability.find(dc => dc.modifierData?.expirationDate === date);
          return availability || null;
        }
        return null;
      }

      if(isRegular && !hasPcp) {
        if(checkAvailability(quotationData.productType)) {
          return true;
        }else {
          props.handleToggleSimpleDialog(
            "simple-dialog",
            `No posee disponibilidad para el producto de la cotización.`
          );
          return false;
        }
      }
 
      if(isRegular && hasPcp) {
        let pcpNoAvailability = [];
        const quotationAvailable = checkAvailability(quotationData.productType);
        quotationData.pcpData.forEach(pd => {
          if (getYear(pd.pcpDate) === getYear(new Date().toISOString())){
            if(!checkAvailability(pd.sppTransactionType, pd.pcpDate)) {
              pcpNoAvailability.push(pd.sppTransactionType);
            }
          }
        });
        if(pcpNoAvailability.length) {
          props.handleToggleSimpleDialog(
            "simple-dialog",
            `No posee disponibilidad para los PCP: ${pcpNoAvailability.join(",")}, con la fecha establecida.`
          );
          return false;
        }
        if(!pcpNoAvailability.length && quotationAvailable) {
          return true;
        }
      }

      if(!isRegular && !checkAvailability(quotationData.productType, quotationData.paymentDay)) {
        props.handleToggleSimpleDialog(
          "simple-dialog",
          `No posee disponibilidad para el tipo de producto y fecha de la cotización.`
        );
        return false;
      }
      return true;
    }

    const markStepChecks = async (signDecision) => {
      // MARK CREDIT FORMALIZATION STEP CHECKS "Documentación firmada/verificada"
      const checkValObj = props.ticket.steps
        .find(item => item.step === "credit_formalization")
        .requirements
        .find(item => item.name === "checkValidation")?.value;

      ["signDocs", "verifiedDocs"].forEach(key => {
          const value = (signDecision === "notarialPayEqualLoan");
          if (checkValObj) {
              checkValObj[key] = value;
          } else {
              const addCheckValObj = {
                  name: 'checkValidation',
                  value: {
                      [key]: value,
                  },
              };
              props.ticket.steps
              .find(item => item.step === props.currentStep)
              .requirements.push(addCheckValObj);
          }
      });
    }
  
    const handleCompleteStep = async () => {
      // verify the requirements
      let proceed = true;
      const ticketReqs = props.ticket.steps.find((item) => item.step === props.currentStep).requirements;
      const tableEmpty = await validateCreditMethodTablesEmpty(settlementData);
      const quotationWrong = quotationData.participantName !== participantData.fullName;
      if (tableEmpty) {
        props.handleToggleSimpleDialog(
          "simple-dialog",
          `Todas las tablas de formas de liquidación seleccionadas deben tener al menos 1 registro.`
        );
        return;
      }
      if(quotationWrong){
        props.handleToggleSimpleDialog(
          "simple-dialog",
          `La cotizacion seleccionada no pertenece a este socio.`
        );
        return;
      }
      await handleManageChecks(
        creditor,
        props,
        settlementData.checks,
        previousChecks,
        creditorAccounts,
        participantData
      );
      ticketReqs.forEach((item) => {
          switch (item.name) {
            case "quotation":
              item.value = formIds[`quotation`];
              break;
            case "warranty":
              item.value = formIds[`warranty`];
              break;
            case "creditMethodTables":
              item.value = settlementData;
              break;
            default:
              break;
          }
      });
      const signDecision = clientSignObj.current.signDocuments;
      const specialCase = clientSignObj.current.specialCase;
      if (!signDecision) {
        props.handleToggleSimpleDialog(
          "simple-dialog",
          `Debe marcar una opción en DECISIÓN DE FIRMA.`
        );
        return;
      }
      // CHECK IF SIGN DOCUMENTS OBJECTS EXISTS IN CURRENT TICKET
      Object.keys(clientSignObj.current).forEach(key => {
        const foundReq = ticketReqs.find(item => item.name === key);
        if(foundReq) {
          foundReq.value = clientSignObj.current[key];
        }else {
          const data = {name: key, value: clientSignObj.current[key]}
          ticketReqs.push(data);
        }
      });
      // const differenceText = validateSettlementTotal(quotationData.requestAmount, settlementData).text;
      // const requirementsState = props.ticket.steps
      //   .find((item) => item.step === props.currentStep)
      //   .requirements.find((item) => item.required && !item.value);
  
      // if (requirementsState || settlementInfo.text === "no_data") {
      //   proceed = false;
      // } else {
      //   proceed = true;
      // }
      if (settlementInfo.text && settlementInfo.text !== "no_data") {
        props.handleToggleSimpleDialog(
          "simple-dialog",
          `El total de formas de liquidación ${settlementInfo.text} al monto solicitado.`
        );
        return;
      }

      if(settlementInfo.text === "no_data") {
        props.handleToggleSimpleDialog(
          "simple-dialog",
          `No hay información concreta en la tabla de formas de liquidación.`
        );
        return;
      }
      if(!formIds[`quotation`]) {
        props.handleToggleSimpleDialog(
          "simple-dialog",
          `No hay una cotización seleccionada.`
        );
        return;
      }
      const validateAvailability = await validateAvailabilityQuotation();
      if(!validateAvailability) return;

      await markStepChecks(signDecision)
      
      props.ticket.status = "document_pending";
      const statusLabel = "Pendiente de Documentación"
      const historyDescription = `Se ha modificado el estado del ticket a: ${statusLabel}`;
      const history = await ticketHistoryGeneral(props, historyDescription).then((response) => response);
      handleNextStep(proceed, props, props.ticket.steps, undefined, false, history);
    };
  
    const handleChecked = (indx, warranty, warrantyType) => {
      if (warrantyType === "property_warranty") {
        let warranties = warrantyIndex;
        if (warranties.every((val) => val.slice(0, -1) === warranty)) {
          if (warranties.includes(indx)) {
            warranties = warranties.filter((wId) => wId !== indx);
          } else {
            warranties = warranties.concat(indx);
          }
        } else {
          warranties = [];
          warranties = warranties.concat(indx);
        }
        setFormIds((prevState) => ({
          ...prevState,
          warranty: warranties,
        }));
      } else {
        setFormIds((prevState) => ({
          ...prevState,
          warranty: [indx],
        }));
      }
    };
  
    return (
      <div style={{ padding: "16px" }}>
        <Grid
          container
          item
          xs={12}
          justifyContent="space-between"
          direction="column"
          style={{ margin: "1rem 0" }}
        >
          <StepStatus
            completed={stepStatus}
            handleNextStep={handleCompleteStep}
            ticketCompleted={props.ticketCompleted}
            roles={props.roles}
          />
          <div style={{ display: "flex" }}>
            <ViewQuotationText 
                {...props}
                handleViewQuotation={handleViewQuotation}
                quotationData={quotationData}
                setFormIds={setFormIds}
            />
            <Grid item xs={6}>
              <Button
                variant="outlined"
                disableElevation
                style={{
                  borderRadius: 3,
                  borderColor: "#43A047",
                  color: "#43A047",
                  fontSize: 12,
                  marginTop: 37,
                  marginLeft: 15,
                  height: 46,
                }}
                onClick={() => openAccountStatus()}
              >
                ESTADO DE CUENTA
                <Loop style={{ fontSize: 17, marginLeft: ".5rem" }} />
              </Button>
            </Grid>
          </div>
          <SignDecision 
            currentTicketStep={currentTicketStep}
            clientSignObj={clientSignObj}
            ticketCompleted={props.ticketCompleted}
          />
          <Divider style={{ margin: "2rem 0" }} />
          <DisbursementGeneralBadges 
            ticketInfoData={ticketInfoData || []}
            customer={props.ticket?.customer}
            token={props.token}
          />
        </Grid>
        <DebtCapacityTable 
          data={ticketInfoData}
          debtCapacity={debtCapacity}
          suggested={Number(suggested)}
        />
        <div style={{margin: '3rem 0'}}>
          <PayCapacityTable
            // data={data}
            data={payCapacityTableData}
          />
        </div>
        <LoanTransactionTable
          ticketInfoData={ticketInfoData}
          token={props.token}
          handleToggleComponent={props.handleToggleComponent}
        />
        <Grid
          item
          container
          xs={12}
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Typography
            variant={"subtitle1"}
            className={"section-header"}
            style={{
              fontWeight: "bold",
              color: "#424242",
              marginLeft: 5,
              marginTop: "2rem",
            }}
          >
            TABLA DE FORMAS DE LIQUIDACIÓN
          </Typography>
          <Typography
            variant="subtitle1"
            style={{
              color: "#616161",
              fontSize: "14px",
              fontWeight: "bold",
              marginRight: ".5rem",
            }}
          >
            TOTAL A TRANSFERIR:
            <span style={{ fontSize: 18, marginLeft: 10 }}>
              <NumberFormat prefix={"$"} value={settlementInfo.transferTotal || 0} />
            </span>
          </Typography>
          <Typography
              variant="subtitle1"
              style={{
                color: "#616161",
                fontSize: "14px",
                fontWeight: "bold",
                marginRight: ".5rem",
              }}
          >
            TOTAL: 
            <span style={{ fontSize: 18, marginLeft: 10 }}>
              <NumberFormat
              prefix={"$"}
              value={settlementInfo.total || 0}
              />
            </span>
          </Typography>
        </Grid>
        <Divider style={{ margin: ".5rem 0 2rem 0" }} />
        <Button
            variant="contained"
            className={
              // Boolean(validateCreditMethodTablesEmpty(settlementData || {})) || 
              stepStatus || 
              props.ticketCompleted
                ? "action-method-btn disabled"
                : "action-method-btn"
            }
            disableElevation
            style={{ height: 28, margin: "0 0 1rem 8px" }}
            disabled={
              // Boolean(validateCreditMethodTablesEmpty(settlementData || {})) || 
              stepStatus || 
              props.ticketCompleted
            }
            onClick={() => saveSettlementTable({
              ticket: props.ticket,
              step: "eval_forms_settlement",
              settlementData: settlementData,
              modifyTicketStep: props.modifyTicketStep,
            })}
          >
            <Save style={{ marginRight: 8, fontSize: 14 }} />
            GUARDAR
        </Button>
        {settlementData && (
          <SettlementFormsTable
            token={props.token}
            handleToggleSimpleDialog={props.handleToggleSimpleDialog}
            rohiId={participantData.rohiId || null}
            state={settlementData}
            clientData={`${props.ticket.participantDetail.fullName ||
              "---"} | ${props.ticket.participantDetail.identificationNumber ||
              "---"}`}
            clientId={[props.ticket.customer]}
            originAccounts={
              creditorAccounts.length > 0
                ? creditorAccounts.map((item) => {
                    return {
                      label: item.name,
                      value: item.number,
                      type: item.accountType,
                    };
                  })
                : []
            }
            clientAccounts={clientBankAccounts}
            destinyAccounts={
              clientBankAccounts?.length > 0
                ? clientBankAccounts.map((item) => {
                    return {
                      name: item.name,
                      value: item.number,
                    };
                  })
                : []
            }
            fromTicket={true}
            readOnly={props.ticketCompleted || stepStatus}
            handleState={handleState.bind(
              this,
              setSettlementData,
              settlementData
            )}
            handleAdd={handleModify.bind(this, setSettlementData, settlementData)}
            handleReplace={handleModify.bind(
              this,
              setSettlementData,
              settlementData
            )}
            handleEdit={handleModify.bind(
              this,
              setSettlementData,
              settlementData
            )}
            handleDelete={handleDelete.bind(
              this,
              setSettlementData,
              settlementData
            )}
          />
        )}
        <br />
        <br />
        <Typography
          variant={"subtitle1"}
          className={"section-header"}
          style={{
            fontWeight: "bold",
            color: "#424242",
            marginLeft: 5,
            marginTop: "2rem",
          }}
        >
          COTIZACIÓN
        </Typography>
        <Divider style={{ margin: ".5rem 0 2rem 0" }} />
        <div style={{ display: "flex" }}>
          <Grid item xs={6}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p>MONTO PRIMERA CUOTA:</p>
              <Button
                variant="contained"
                disableElevation
                style={{
                  minWidth: 130,
                  height: 30,
                  borderRadius: 20,
                  fontSize: 12,
                  marginBottom: 5,
                  marginLeft: 5,
                  backgroundColor: green[500],
                  color: "white",
                }}
              >
                {quota1}
              </Button>
            </div>
          </Grid>
          <Grid item xs={6}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p>MONTO SEGUNDA CUOTA:</p>
              <Button
                variant="contained"
                disableElevation
                style={{
                  minWidth: 130,
                  height: 30,
                  borderRadius: 20,
                  fontSize: 12,
                  marginBottom: 5,
                  marginLeft: 5,
                  backgroundColor: green[500],
                  color: "white",
                }}
              >
                {quota2}
              </Button>
            </div>
          </Grid>
        </div>
        <br />
        <QuotationSelectInput
          client={props.ticket.customer}
          ticketApprovedQuotation={
            approvalObj.status === "approved" || props.ticketCompleted
          }
          ticketSettlementStep={true}
          selectedQuotationsId={
            formIds[`quotation`] ? [formIds[`quotation`]] : []
          }
          quotationData={quotationData}
          addQuotation={handleAddQuotation.bind(this, setFormIds)}
          removeQuotation={handleRemoveQuotation.bind(this, setFormIds)}
        />
        <Typography
          variant={"subtitle1"}
          className={"section-header"}
          style={{
            fontWeight: "bold",
            color: "#424242",
            marginLeft: 5,
            marginTop: "2rem",
          }}
        >
          GARANTÍA
        </Typography>
        <Divider style={{ margin: ".5rem 0 2rem 0" }} />
        <CheckboxField
          xs={12}
          alignItems="flex-end"
          check={hasWarranty}
          handleChange={handleWarrantyChange}
          color="primary"
          textColor="#43A047"
          label="¿Tiene garantía?"
          disabled={props.ticketCompleted}
        />
        {hasWarranty && (
          <WarrantyTable
            checked={warrantyIndex}
            handleChecked={handleChecked.bind(this)}
            debtors={[props.ticket.customer]}
          />
        )}
  
        <Dialog
          open={accountStatusDialog}
          onClose={closeAccountStatus}
          fullScreen={true}
        >
          <DialogActions>
            <IconButton onClick={closeAccountStatus}>
              <ArrowBackIcon />
            </IconButton>
          </DialogActions>
          <DialogContent style={{ position: "relative" }}>
            <AccountStatus data={participantData} />
          </DialogContent>
        </Dialog>
      </div>
    );
}