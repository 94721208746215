import { fetchTime } from "../../../utils/axios";
import {
  createFileAttachment,
  fetchFileAttachments,
  fetchFileAttachment,
} from "src/utils/axios";

export const statusColors = [
  {
    bgColor: "#4688e3",
    color: "#FFFFFF",
    state: "pending_evaluation",
    name: "P. Oficial",
    fullName: "Pendiente Oficial",
    order: 0,
  },
  {
    bgColor: "#ed9e4a",
    color: "#424242",
    state: "hr_confirmation_pending",
    name: "P. HHRR",
    fullName: "Pendiente RRHH",
    order: 1,
  },
  {
    bgColor: "#4e21bb",
    color: "#FFFFFF",
    state: "analisis_pending",
    name: "P. Crédito",
    fullName: "Pendiente Análisis de Crédito",
  },
  {
    bgColor: "#f0c756",
    color: "#424242",
    state: "compliance_review_pending",
    name: "P. Cumplimiento",
    fullName: "Pendiente Cumplimiento",
    order: 9,
  },
  {
    bgColor: "#3ac5c7",
    color: "#FFFFFF",
    state: "pending_signature",
    name: "P. Firma",
    fullName: "Pendiente Firma",
    order: 3,
  },
  {
    bgColor: "#227daa",
    color: "#FFFFFF",
    state: "revision_pending",
    name: "P. Revisión",
    fullName: "Pendiente de Revisión",
    order: 4,
  },
  {
    bgColor: "#17b38c",
    color: "#FFF",
    state: "conciliation_pending",
    name: "P. Conciliación",
    fullName: "Pendiente Conciliación",
    order: 5,
  },
  {
    bgColor: "#334a23",
    color: "#FFFFFF",
    state: "registration_pending",
    name: "P. Registro",
    fullName: "Pendiente de Registro",
    order: 6,
  },
  {
    bgColor: "#515cc2",
    color: "#FFF",
    state: "signature_completed",
    name: "Firma Completada",
    fullName: "Firma Completada",
    order: 7,
  },
  {
    bgColor: "#43a047",
    color: "#FFFFFF",
    state: "complete",
    name: "Completado",
    fullName: "Completado",
    order: 8,
  },
  {
    bgColor: "#debb7a",
    color: "#FFF",
    state: "insufficient_data",
    name: "Info. Insuficiente",
    fullName: "Información Insuficiente",
  },
  {
    bgColor: "#f24e4e",
    color: "#FFFFFF",
    state: "canceled",
    name: "Cancelada",
    fullName: "Cancelada",
  },
  {
    bgColor: "#dedcdc",
    color: "#424242",
    state: "default",
    name: "- - - - -",
    fullName: "",
  },
];

export const getInitialStates = (data) => {
  const affiliatePurposeStates = data.accountPurpose?.includes("financing")
    ? ["analisis_pending", "pending_evaluation"]
    : ["pending_evaluation"];

  const commonStates = [
    "pending_signature",
    "revision_pending",
    "registration_pending",
    "signature_completed",
  ];

  if (data.institutionType === "affiliate") {
    return [
      "hr_confirmation_pending",
      ...affiliatePurposeStates,
      ...commonStates,
    ];
  }

  const nonAffiliatePurposeStates = data.accountPurpose?.includes("financing")
    ? ["analisis_pending", "compliance_review_pending"]
    : ["compliance_review_pending"];
  return ["pending_evaluation", ...nonAffiliatePurposeStates, ...commonStates];
};

// Función para crear el objeto estado con propiedades adicionales
export const createStateObject = (data, state, roles, analysisAprov) => {
  const states = {
    hr_confirmation_pending: {
      label: "Aut. RRHH",
      hasPermission: roles.includes("rh empresa afiliada"),
      color: "#ed9e4a",
    },
    analisis_pending: {
      label: "Aut. Análisis",
      hasPermission: analysisAprov,
      color: "#4e24bb",
    },
    pending_evaluation: {
      label: "Aut. Oficial",
      hasPermission: roles.includes("oficial de negocio"),
      color: "#4688e3",
    },
    compliance_review_pending: {
      label: "Aut. Cumplimiento",
      hasPermission: roles.includes("cumplimiento"),
      color: "#edb03e",
    },
    revision_pending: {
      label: "Aut. Revisión",
      hasPermission: roles.includes("oficial de negocio"),
      color: "#227daa",
    },
    registration_pending: {
      label: "Aut. Registro",
      hasPermission: roles.includes("lead firma"),
      color: "#334a23",
    },
    conciliation_pending: {
      label: "Aut. Conciliación",
      hasPermission: roles.includes("lead conciliacion"),
      color: "#17b38c",
    },
    pending_signature: {
      label: "Aut. Firma",
      hasPermission: roles.includes("lead firma"),
      color: "#3ac5c7",
    },
    signature_completed: {
      label: "Completar Lead",
      hasPermission: roles.includes("lead firma"),
      color: "#43a047",
      show: (data?._state || data?.state) === "signature_completed",
    },
    canceled: {
      label: "Cancelar",
      hasPermission: true,
      color: "#f24e4e",
      show: (data?._state || data?.state) !== "canceled",
      state: 'canceled'
    },
  };

  const stateObject = states[state] || {};
  stateObject.show = stateObject.show
    ? stateObject.show
    : (data?._state || data?.state) === state;
  return stateObject;
};

const checkIfArrayObject = (data) => {
  const check = data.find((item) => typeof item === "object");
  return Boolean(check);
};

const handleInstitutionTotalTime = (entryDate) => {
  const newTime = new Date();
  const newDate = new Date(entryDate);
  const diffTime = Math.abs(newDate - newTime);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return Math.round(diffDays / 30);
};

export const handleUploadFileEndpoint = (options) => {
  let formData = new FormData();
  formData.append("file", options?.file);
  formData.append("name", `address-file-${options?.leadUniqueId}`);
  return createFileAttachment(options?.token, formData);
};
// method not used since we pass the file directly to the property.
// keeping it here for later use.
export const getPossibleFileAttachment = async (options) => {
  if (!options?.file) {
    let currSavedFile = null;
    const name = `${options?.field}-file-${options?.leadUniqueId}`;
    const endpoint = fetchFileAttachments({ token: options?.token, name });
    await endpoint.then((res) => {
      currSavedFile = res.data?.length ? res.data[res.data.length - 1] : null;
    });
    if (currSavedFile) {
      return currSavedFile;
      // onChange('any', options?.field, currSavedFile);
    }
  }
};

export const setFileObj = (file) =>
  typeof file === "string" ? file : file?.uniqueId || null;

export const formattedFieldsData = (fields, oldFieldData, extraFields) => {
  const isEmptyValue = (value) => {
    return value === null || value === undefined || value === 0;
  };

  const BankAccount = () => {
    if(fields.settlementDisbursment === "transfer" || fields.payType === "liquidable" || fields.financialCertificate?.paymentType === "liquidable"){
      return {
        bank: extraFields?.bankInfo?.financialInstitution?.name || fields.bank,
        accountType: extraFields?.bankInfo?.accountType || fields.accountType || "saving",
        number: extraFields?.bankInfo?.bankAccount || fields.number,
      }
    } else {
      return null
    }
  }

  // Function to recursively remove empty fields from an object
  const removeEmptyFields = (obj) => {
    const result = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (Array.isArray(value)) {
          // Clean objects within the array but do not filter out empty values
          const cleanedArray = value.map((item) =>
            typeof item === "object" ? removeEmptyFields(item) : item
          );
          result[key] = cleanedArray; // Retain the array as is
        } else if (typeof value === "object" && value !== null) {
          const cleanedValue = removeEmptyFields(value);
          if (Object.keys(cleanedValue).length > 0) {
            result[key] = cleanedValue;
          }
        } else if (!isEmptyValue(value)) {
          result[key] = value;
        }
      }
    }
    return result;
  };

  const setFinancialCertificateData = () => {
    let financialCertificate = {}
    let transferType = "";
    if(fields.payType === "liquidable" || fields.financialCertificate?.paymentType === "liquidable"){
      transferType = "wire_transfer"
    }

    if(fields.financialCertificate){
      financialCertificate = {
        mainMember: fields.financialCertificate.mainMember || null,
        status: fields.financialCertificate.status || null,
        term: fields.certTerm || fields.financialCertificate.term || 0,
        signType: fields.financialCertificate.signType || null,
        paymentType: fields.payType || fields.financialCertificate.paymentType || null,
        transferType: transferType || null,
        amount: fields.certAmount || fields.financialCertificate.amount || 0,
        certificateNumber: fields.financialCertificate.certificateNumber || null,
    }
    } else {
      financialCertificate = {
        mainMember: fields.mainMember || null,
        amount: fields.certAmount || 0,
        term: fields.certTerm || 0,
        interestRate: fields.interest || 0,
        paymentType: fields.payType || null,
        transferType: transferType || null,
      }
    }
    if( fields.accountPurpose?.includes("financial_certificate") ||
    fields.accountPurpose?.some(
      (purpose) => purpose.value === "financial_certificate"
    )){
      return financialCertificate;
    } else {
      return null;
    }
  }

  const setSavingsAccForm = () => {
    let savingsAccForm = [];
    const infant = fields.savingsAccountForm?.find(item => item.type === "youth")

    if (fields.infantSavingQuota || infant?.quota) {
      savingsAccForm.push({
        type: "youth",
        childInfo: {
          name: fields.infantName || infant.childInfo?.name,
          age: fields.infantAge || infant.childInfo?.age,
        },
        quota: fields.infantSavingQuota || infant.quota,
      });
    }
    if (fields.plannedQuota && fields.plannedTerm) {
      savingsAccForm.push({
        type: "planned",
        quota: fields.plannedQuota,
        term: fields.plannedTerm,
        goal: fields.plannedGoal,
        periodicity: fields.plannedFrequency?.value,
        paymentDay: fields.plannedPaymentDay || null,
        secondPaymentDay: fields.scndPlannedPaymentDay || null,
      });
    }
    if(fields.cuotaOnSight > 0){
      savingsAccForm.push({
        type: "in_sight",
        quota: fields.cuotaOnSight,
      })
    }

    return savingsAccForm.length > 0 ? savingsAccForm : null;
  };

  const insitutionTime = fields.entryDate
    ? handleInstitutionTotalTime(fields.entryDate)
    : 0;
  const formattedData = {
    owner: {
      address: {
        country: fields.country?.name || fields.country || null,
        state: fields.province?.name || fields.province || null,
        city: fields.city?.name || fields.city || null,
        region: fields.region?.name || fields.region || null,
        reference: fields.reference?.value || fields.reference || null,
        sector: fields.sector?.value || fields.sector || null,
      },
      workprofile: {
        uniqueId: oldFieldData?.[`uniqueId_workprofile`],
        institution: extraFields?.institution?.name || null,
        laboralStatus:
          fields.laboralStatus?.value || fields.laboralStatus || null,
        position:
          fields.salaried === false
            ? "NO APLICA"
            : fields.position?.name || fields.position || null,
        department:
          fields.salaried === false
            ? "NO APLICA"
            : fields.department?.name || fields.department || null,
        entryDate: fields.entryDate || null,
        salary: fields.salary || 0,
        averageIncome: fields.averageIncome || 0,
        economicSector:
          fields.economicSector?.value || fields.economicSector || null,
        commercialActivity: fields.commercialActivity || null,
        laboralCode: fields.laboralCode || null,
        workType: fields.workType?.value || fields.workType || null,
        email: fields.workEmail || null,
        paymentFrequency: fields.paymentFrequency?.value || null,
        paymentType: fields.paymentType?.value || fields.paymentType || null,
        sessionType: fields.sessionType?.value || fields.sessionType || null,
        employmentLength: insitutionTime,
      },
      bankaccount:
        (extraFields?.bankInfo?.financialInstitution || fields.uniqueId_bankaccount)
          ? BankAccount()
          : null,
      nationality: fields.nationality?.name || fields.nationality || null,
      fullName: fields.fullName || null,
      identificationType:
        fields.identificationType?.value || fields.identificationType || null,
      identificationNumber: fields.identificationNumber || null,
      gender: fields.gender?.value || fields.gender || null,
      birthDate: fields.birthDate || null,
      profession: fields.profession?.name || fields.profession || null,
      cellPhone: fields.cellPhone || null,
      homePhone: fields.homePhone || null,
      workPhone: fields.workPhone
        ? fields.workPhone.replace(/[()-\s]/g, "")
        : null,
      additionalPhone: fields.additionalPhone || null,
      email: fields.owner_email || null,
      houseInfo: fields.houseInfo?.value || fields.houseInfo || null,
      educationLevel:
        fields.educationLevel?.value || fields.educationLevel || null,
      numberOfDependents:
        fields.numberOfDependents?.value || fields.numberOfDependents || null,
      addressFile: setFileObj(fields.addressFile),
      identificationFile: setFileObj(fields.identificationFile),
      files: [],
      transactionalBehavior:
        fields.transactionalBehavior?.value ||
        fields.transactionalBehavior ||
        null,
      personKind: fields.personKind?.value || fields.personKind || null,
      category: fields.category?.value || fields.category || null,
      pepPosition: fields.PepPosition || null,
      pepOcupatonDate: fields.PepDate || null,
      pepWorkInstitution: fields.PepInstitution || null,
      pepRelation: fields.PepRelation?.value || fields.PepRelation || null,
      affidavitSources: fields.affidavitSources || null,
      uniqueId: oldFieldData?.[`uniqueId_owner`],
    },
    accountPurpose: fields.accountPurpose?.length
      ? fields.accountPurpose?.map((item) => item.value || item)
      : null,
    discountObligations: fields.discountObligations || false,
    savingsAccountForm: setSavingsAccForm(),
    financialCertificate: setFinancialCertificateData(),
    inputCertQuota: fields.cuotaContributions || 0,
    creditForm:
      fields.accountPurpose?.includes("financing") ||
      fields.accountPurpose?.some((purpose) => purpose.value === "financing")
        ? {
            amount: fields.amount,
            term: fields.term,
            settlementDisbursment: fields.settlementDisbursment,
            periodicity: fields.periodicity?.value,
            paymentDay: fields.paymentDay,
            secondPaymentDay: fields.secondPaymentDay,
            creditType: fields.creditType?.value,
            precalification: fields.precalification,
            membershipFinanced: fields.membershipFinanced,
            savingsFinanced: fields.savingsFinanced,
          }
        : null,
    amountToSave: fields.amountToSave || null,
    wayToPay: extraFields?.institution?.state === "affiliate" ? "payroll_deduction" : fields.wayToPay?.value || fields.wayToPay || null,
    certificatePaymentMethod: fields.certWayToPay || fields.certificatePaymentMethod || null, 
    numberOfInstallments: fields.numberOfInstallments || 0,
    typeOfInstallments:
      fields.typeOfInstallments?.value || fields.typeOfInstallments || null,
    uniqueId: fields.uniqueId || null,
    state: fields._state
      ? fields._state
      : oldFieldData?._state || oldFieldData?.state || null,
    blacklist: {},
    disbursementWays:
      fields.disbursementWays?.value || fields.disbursementWays || null,
    comment: fields.comment || "",
    initialDeposit: fields.initialDeposit || 0,
    initialSaving: fields.initialAccountSaving || 0,
    quotation: fields.quotation || null,
  };

  const cleanedData = removeEmptyFields(formattedData);
  const updatedData = { 
    ...cleanedData, 
    documents: fields.documents || null,
    financialCertificate: cleanedData.financialCertificate 
      ? { ...cleanedData.financialCertificate, mainMember: fields.mainMember || null } 
      : null
  };
  return updatedData;
};

export const convertSavingsFormToField = (savingForm, fields) => {
  savingForm.forEach((sf) => {
    switch (sf.type) {
      case "in_sight":
        fields.sightSavingFee = sf.quota;
        break;
      default:
        break;
    }
  });
};

export const convertFinancialCertToField = (financialCert, fields) => {
  fields.settlementWays = financialCert.paymentType;
  fields.certificateAmount = financialCert.amount;
  fields.certificateTerm = financialCert.term;
  fields.certificateRate = financialCert.interestRate;
  fields.transferType = financialCert.transferType;
  fields.savingsAccount = financialCert.savingsAccount;
};

export const fieldsTranslations = {
  affidavitSources: "Procedencia de fondos",
  country: "País",
  state: "Estado",
  city: "Ciudad",
  region: "Región",
  reference: "Dirección",
  sector: "Sector",
  institution: "Institución",
  laboralStatus: "Estado laboral",
  position: "Posición",
  department: "Departamento",
  entryDate: "Fecha de entrada",
  salary: "Salario",
  averageIncome: "Ingreso promedio",
  economicSector: "Sector económico",
  commercialActivity: "Actividad comercial",
  incomeVoucher: "Comprobante de ingreso",
  laboralCode: "Código laboral",
  workType: "Tipo de trabajo",
  email: "Correo",
  paymentType: "Tipo de pago",
  sessionType: "Tipo de Jornada",
  accountPurpose: "Propósito de afiliación",
  hasDebtorAnotherCitizenship: "Tiene otra nacionalidad",
  secondCitizenshipCountry: "Segunda Nacionalidad",
  economicActivityInfo: "Actividad Económica",
  primaryIncomeMonthlyActivity: "Ingresos Actividad Económica",
  secondaryIncome: "Tiene ingreso secundario",
  secondaryIncomeMonthlyActivity: "Actividad Económica Secundaria",
  secondaryIncomeActivity: "Ingresos Actividad Económica Secundaria",
  approximateAmountOfTransactionsOnTheCooperative:
    "Monto Transacciones Mensuales que realizará en la Cooperativa",
  willDebtorReceive15kOrMoreInTheAccount:
    "¿Recibirá el socio mas de $15,000 en la cuenta?",
  hasDebtorPossessedPublicPower:
    "¿Persona políticamente expuesta y/o figura pública? ",
  pepMemberPosition: "Cargo, rango o posición",
  pepMemberEntryDate: "Fecha de ocupación cargo, rango o posición",
  pepMemberInstitution: "Institución a la que pertenece",
  nationality: "Nacionalidad",
  prefix: "Título",
  fullName: "Nombre completo",
  identificationType: "Tipo de identificación",
  identificationNumber: "Identificación",
  gender: "Sexo",
  birthDate: "Fecha nacimiento",
  civilStatus: "Estado civil",
  profession: "Profesión",
  birthplace: "Lugar de nacimiento",
  cellPhone: "Celular",
  homePhone: "Teléfono casa",
  workPhone: "Teléfono trabajo",
  additionalPhone: "Teléfono adicional",
  educationLevel: "Nivel educación",
  houseMonthlyPayment: "Pago Mensual Vivienda",
  addressFile: "Archivo de dirección",
  identificationFile: "Archivo de identificación",
  savingsAccountForm: "Formulario de cuentas de ahorro",
  inputCertificate: "Certificado financiero",
  inputAmount: "Cuotas de participación",
  pastDuedebt: "¿Deudas Atrasadas?",
  pastDuedebtAmount: "Monto Deudas Atrasadas",
  amountToSave: "Monto ahorro",
  wayToPay: "Forma de pago",
  numberOfInstallments: "Número de cuotas",
  typeOfInstallments: "Frecuencia de pago cuotas",
  blacklist: "Blacklist",
  comment: "Comentario",
  initialDeposit: "Depósito inicial",
  initialSaving: "Ahorro inicial",
  transactionalBehavior: "Comportamiento Transaccional",
  personKind: "Tipo de persona",
  category: "Categoría",
  numberOfDependents: "Cantidad de dependientes",
};
