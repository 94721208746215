import * as React from "react";
import { connect } from "react-redux";
import FileUploadSelectInput from "src/containers/FileUpload/FileUploadSelectInput";

import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";

import {
  deleteCompanyRepresentative,
  fetchCompanyRepresentative,
  updateCompanyRepresentative,
} from "../../../actions";
import AlertDialog from "../../../components/AlertDialog";
import Loading from "../../../components/Loading";
import attorneyNumberMaskInput from "../../../components/TextMaskInputComponents/attorneyNumberMaskInput";
import idNumberMaskInput from "../../../components/TextMaskInputComponents/idNumberMaskInput";
import passportMaskInput from "../../../components/TextMaskInputComponents/passportMaskInput";
import phoneMaskInput from "../../../components/TextMaskInputComponents/phoneWithOneMaskInput";
import rncMaskInput from "../../../components/TextMaskInputComponents/rncMaskInput";
import {
  affiliateState,
  affiliateType,
  catchError,
  civilStatus,
  dwellingTypes,
  educationLevel,
  gender,
  IDENTIFICATION_TYPE,
  nationalitiesToOptions,
  prefix,
  resetError,
  successHandler,
  titleCaseHandler,
  translations,
} from "../../../utils";
import {
  deleteParticipant,
  fetchNationalities,
  fetchParticipant,
  updateParticipant,
  fetchAssignees,
  fetchParticipantHistory,
  resetSecurityQuestions,
} from "../../../utils/axios";
import FinancialProfile from "../../FinancialProfile/index";
import WorkProfile from "../../WorkProfile";
import Address from "../../../components/Address/Address";
import ProfessionSelectInput from "../../Profession/ProfessionSelectInput";
import { Checkbox, FormControlLabel, Grid, MenuItem } from "@material-ui/core";
// import MoneyInput from "src/components/TextMaskInputComponents/moneyInput";
import ClientDocuments from "../../../components/ClientDocuments";
import JwtDecode from "jwt-decode";
import CheckBox from "@material-ui/core/Checkbox";
import { Timeline } from "@material-ui/icons";
import ParticipantActivityHistory from "src/containers/Participant/ParticipantActivityHistory";
import { Restore } from "@material-ui/icons";
import { orange } from "@material-ui/core/colors";

const styles = createStyles({
  attachmentIcon: {
    color: green[500],
    fontSize: 50,
    position: "absolute",
    right: 25,
    top: 25,
    transform: "rotate(90deg)",
  },
  checkboxLabel: {
    color: green[500],
  },
  checkboxLabelReactSelect: {
    color: green[500],
    fontSize: "small",
  },
  controls: {
    display: "flex",
    justifyContent: "center",
    marginTop: 20,
  },
  deleteButton: {
    backgroundColor: red[500],
    color: "white",
  },
  fileText: {
    fontSize: 14,
    position: "absolute",
    right: 32,
  },
  formControl: {
    marginTop: 20,
    minWidth: 300,
    width: 300,
  },
  greenButton: {
    backgroundColor: green[500],
    color: "white",
  },
  yellowButton: {
    backgroundColor: orange[500],
    color: "white",
  },
  paper: {
    padding: 20,
  },
  root: {
    overflowY: "auto",
    padding: 20,
    paddingBottom: 64,
  },
  textField: {
    display: "block",
    marginTop: 20,
    width: "50%",
  },
});

// interface ICompanyRepresentativeViewProps {
//   companyRepresentatives: IParticipant[];
//   companyRepresentativeId;
//   classes: { [key] };

//   user: IUser;
//   changeSection(sectionNumber: number): void;
//   deleteCompanyRepresentative(uniqueId): IAction<IParticipant[]>;
//   fetchCompanyRepresentative(
//     companyRepresentative: IParticipant
//   ): IAction<IParticipant[]>;
//   updateCompanyRepresentative(
//     companyRepresentative: IParticipant
//   ): IAction<IParticipant[]>;
// }

class CompanyRepresentativeView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      additionalPhone: "",
      additionalPhoneError: "",
      affiliateState: "not_affiliate",
      affiliateStateError: "",
      affiliateType: "service_user_affiliate",
      affiliateTypeError: "",
      birthDate: "",
      birthDateError: "",
      cellPhone: "",
      cellPhoneError: "",
      city: "",
      cityError: "",
      civilStatus: "single",
      civilStatusError: "",
      clientCF: "",
      clientIF: "",
      clientW: "",
      country: "República Dominicana",
      countryError: "",
      dialog: false,
      dialogTitle: "",
      educationLevel: "none",
      educationLevelError: "",
      email: "",
      emailError: "",
      errorMessage: "",
      fullName: "",
      fullNameError: "",
      gender: "m",
      genderError: "",
      historyDialog: false,
      homePhone: "",
      homePhoneError: "",
      identificationNumber: "",
      identificationNumberError: "",
      identificationType: "national",
      identificationTypeError: "",
      identificationExpirationDate: "",
      identificationExpirationDateError: "",
      cooperativeEntryDate: new Date().toISOString().split("T")[0],
      cooperativeEntryDateError: "",
      loading: false,
      nationality: { label: "", value: "" },
      nationalityError: "",
      nationalityOptions: [],
      nickName: "",
      nickNameError: "",
      participantHistory: [],
      prefix: "mister",
      prefixError: "",
      profession: [],
      reference: "",
      referenceError: "",
      region: "",
      regionError: "",
      sector: "",
      sectorError: "",
      state: "",
      stateError: "",
      uniqueId: "",
      workPhone: "",
      workPhoneError: "",
      workPhoneExtension: "",
      workPhoneExtensionError: "",
      vehicleColor: "",
      vehicleColorError: "",
      vehicleBrand: "",
      vehicleBrandError: "",
      vehicleModel: "",
      vehicleModelError: "",
      vehicleYear: 0,
      vehicleYearError: "",
      hasDebtorAVehicle: false,
      hasDebtorAVehicleError: "",
      vehicleFinancialStatus: "financed",
      vehicleFinancialStatusError: "",
      // houseMonthlyPaymentError: "",
      // houseMonthlyPayment: 0,
      // houseMonthlyPaymentCurrency: "DOP",
      // houseMonthlyPaymentCurrencyError: "",
      dwellingType: "",
      rohiId: 0,
      rohiIeId: 0,
      rohiMfsId: 0,
      currentUser: {},
      is_administration: false,
      hasAccessToCoopvirtual: false,
      password: "",
      passwordConfirm: "",
      passwordError: "",
      passwordConfirmError: "",
      currEmail: "",
    };

    this.fetchCompanyRepresentative = this.fetchCompanyRepresentative.bind(
      this
    );
    this.getIdentificationInput = this.getIdentificationInput.bind(this);
    this.handleAddProfession = this.handleAddProfession.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteProfession = this.handleDeleteProfession.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleHistoryDialogClose = this.handleHistoryDialogClose.bind(this);
    this.handleReactSelectChange = this.handleReactSelectChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    this.handleCurrencyInputChange = this.handleCurrencyInputChange.bind(this);
    this.openLoading = this.openLoading.bind(this);
    this.openHistory = this.openHistory.bind(this);
    this.getParticipantHistory = this.getParticipantHistory.bind(this);
  }

  getParticipantHistory =  async  () => {
    try {
      const res = await fetchParticipantHistory(this.props.user.token, this.props.companyRepresentativeId);
      const history = res.data;
      this.setState({participantHistory: history.historical_records})
    } catch (error) {
      console.error('Error al obtener el historial del participante', error);
    }
  }

  openLoading() {
    this.setState({ loading: true });
  }

  handleAddProfession(id) {
    this.setState({ profession: [id] });
  }
  handleDeleteProfession() {
    this.setState({ profession: [] });
  }

  handleReactSelectChange(nationality) {
    this.setState({ nationality });
  }
  handleCheckBoxChange(event, key) {
    this.setState({ [key]: event.target.checked });
  }
  handleCurrencyInputChange(key) {
    return (event, maskedValue, floatValue) => {
      this.setState({ [key]: floatValue || 0 });
    };
  }
  componentDidMount() {
    this.setState({ loading: true });
    fetchNationalities({ token: this.props.user.token })
      .then((res) => {
        this.setState(
          {
            nationalityOptions: nationalitiesToOptions(
              res.data.results ? res.data.results : res.data
            ),
          },
          () => {
            this.fetchCompanyRepresentative();
          }
        );
      })
      .catch((err) => {
        catchError(err, this);
      });

    const userId = JwtDecode(this.props.user.token).user_id;
    fetchAssignees({ token: this.props.user.token, id: userId })
      .then((res) => {
        this.setState({
          currentUser: res.data[0],
        });
        this.setState({
          is_administration: this.state.currentUser.groups.includes(
            "administration"
          ),
        });
      })
      .catch((err) => {
        catchError(err, this);
      });

      this.getParticipantHistory()
  }

  fetchCompanyRepresentative() {
    fetchParticipant(this.props.user.token, this.props.companyRepresentativeId)
      .then((res) => {
        // currrent company representative
        const currClient = res.data;

        this.setState((prevState) => ({
          additionalPhone: currClient.additionalPhone || "",
          affiliateState: currClient.affiliateState || "",
          affiliateType: currClient.affiliateType || "",
          birthDate: currClient.birthDate || "",
          cellPhone: currClient.cellPhone || "",
          city: currClient.address !== undefined ? currClient.address.city : "",
          civilStatus: currClient.civilStatus || "single",
          clientCF: `crm/participant/${currClient.uniqueId}/upload_credit_file/`,
          clientIF: `crm/participant/${currClient.uniqueId}/upload_identification_file/`,
          clientW: `crm/participant/${currClient.uniqueId}/upload_warranty_file/`,
          country:
            currClient.address !== undefined
              ? currClient.address.country
              : "República Dominicana",
          educationLevel: currClient.educationLevel || "",
          nickName: currClient.nickName || "",
          email: currClient.email || "",
          fullName: currClient.fullName || "",
          gender: currClient.gender || "m",
          cooperativeEntryDate: currClient.cooperativeEntryDate || "",
          homePhone: currClient.homePhone || "",
          identificationNumber: currClient.identificationNumber || "",
          identificationType: currClient.identificationType || "national",
          identificationExpirationDate:
            currClient.identificationExpirationDate || "",
          loading: false,
          nationality:
            prevState.nationalityOptions.find(
              (n) => n.value === currClient.nationality
            ) || prevState.nationalityOptions[0],
          prefix: currClient.prefix || "mister",
          profession: [currClient.profession || ""],
          reference:
            currClient.address !== undefined
              ? currClient.address.reference
              : "",
          region:
            currClient.address !== undefined ? currClient.address.region : "",
          sector:
            currClient.address !== undefined ? currClient.address.sector : "",
          state:
            currClient.address !== undefined ? currClient.address.state : "",
          uniqueId: currClient.uniqueId || "",
          workPhone: currClient.workPhone || "",
          workPhoneExtension: currClient.workPhoneExtension || "",
          dwellingType: currClient.houseInfo,
          // houseMonthlyPayment: currClient.houseMonthlyPayment || 0,
          rohiId: currClient.rohiId || 0,
          rohiIeId: currClient.rohiIeId || 0,
          rohiMfsId: currClient.rohiMfsId || 0,
          hasAccessToCoopvirtual: currClient.hasAccessToCoopvirtual,
          currEmail: currClient.email,
        }));
      })
      .catch((err) => {
        catchError(err, this);
      });
  }

  handleResetQuestions = () => {
    resetSecurityQuestions(this.props.user.token, this.state.uniqueId)
      .then((res) => {
        this.setState({
          dialog: true,
          dialogTitle: 'Éxito',
          errorMessage: res.data.message
        });
      })
      .catch((error) => {
        // Verifica si hay una respuesta en el error
        if (error.response && error.response.data) {
          const { message } = error.response.data;
          console.error("Error al resetear las preguntas:", message);
          this.setState({
            dialog: true,
            dialogTitle: 'Error',
            errorMessage: message
          });
        } else {
          // Si no hay respuesta, muestra un mensaje genérico
          console.error("Error al resetear las preguntas:", error.message);
          this.setState({
            dialog: true,
            dialogTitle: 'Error',
            errorMessage: 'Hubo un problema al intentar reiniciar las preguntas de seguridad.'
          });
        }
      });
  }

  handleChange(event, key) {
    if (key === "password" || key === "passwordConfirm") {
      if (/^(\d{4}|\d{6})$/.test(event.target.value)) {
        this.setState({
          [key]: event.target.value,
          [key + "Error"]: "",
        });
      } else {
        this.setState({
          [key]: event.target.value,
          [key +
          "Error"]: "El pin debe ser numerico y de cuatro (4) caracteres.",
        });
      }
      return false;
    }

    this.setState({
      [key]: event.currentTarget.value,
      [key + "Error"]: "",
    });
  }

  handleSelectChange(event, key) {
    if (key === "password" || key === "passwordConfirm") {
      if (/^(\d{4}|\d{6})$/.test(event.target.value)) {
        this.setState({
          [key]: event.target.value,
          [key + "Error"]: "",
        });
      } else {
        this.setState({
          [key]: event.target.value,
          [key +
          "Error"]: "El pin debe ser numerico y de cuatro (4) caracteres.",
        });
      }
      return false;
    }

    this.setState({
      [key]: event.target.value,
      [key + "Error"]: "",
    });
  }

  handleSelect(key, value) {
    this.setState({
      [key]: value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    resetError(this);
    const companyRepresentative = {
      additionalPhone: this.state.additionalPhone,
      address: {
        city: this.state.city,
        country: this.state.country,
        state: this.state.state,
        region: this.state.region,
        reference: this.state.reference,
        sector: titleCaseHandler(this.state.sector),
      },
      affiliateState: this.state.affiliateState,
      affiliateType: this.state.affiliateType,
      birthDate: this.state.birthDate,
      cellPhone: this.state.cellPhone,
      cooperativeEntryDate: this.state.cooperativeEntryDate,
      houseInfo: this.state.dwellingType,
      // houseMonthlyPayment: this.state.houseMonthlyPayment,
      civilStatus: this.state.civilStatus,
      educationLevel: this.state.educationLevel,
      email: this.state.email,
      fullName: this.state.fullName,
      gender: this.state.gender,
      homePhone: this.state.homePhone,
      identificationNumber: this.state.identificationNumber.replace(/-/g, ""),
      identificationType: this.state.identificationType,
      identificationExpirationDate:
        this.state.identificationExpirationDate || null,
      nationality: this.state.nationality.value,
      nickName: this.state.nickName,
      participantType: "company representative",
      prefix: this.state.prefix,
      profession: this.state.profession[0],
      workPhone: this.state.workPhone,
      workPhoneExtension: this.state.workPhoneExtension,
      hasAccessToCoopvirtual: this.state.hasAccessToCoopvirtual,
      password:
        this.state.password.length > 0 ? this.state.password : undefined,
      currEmail: this.state.currEmail,
    };

    this.setState({ loading: true });

    updateParticipant(
      this.props.user.token,
      this.state.uniqueId,
      companyRepresentative
    )
      .then((res) => {
        this.props.updateCompanyRepresentative(
          res.data.results ? res.data.results : res.data
        );
        this.getParticipantHistory()
        successHandler(res, this, translations.COMPANY_REPRESENTATIVE);
        this.setState({
          password: "",
          passwordConfirm: "",
          currEmail: this.state.email,
        });
      })
      .catch((err) => {
        catchError(err, this);
      });
  }
  handleDelete() {
    if (Window.confirm(translations.ARE_YOU_SURE)) {
      this.setState({ loading: true });

      deleteParticipant(
        this.props.user.token,
        this.props.companyRepresentativeId
      )
        .then((res) => {
          this.props.changeSection(0);
          this.props.deleteCompanyRepresentative(
            this.props.companyRepresentativeId
          );
        })
        .catch((err) => {
          catchError(err, this);
        });
    }
  }
  handleDialogClose() {
    this.setState({ dialog: false });
  }
  getIdentificationInput(identificationType) {
    switch (identificationType) {
      case "national":
        return {
          inputComponent: idNumberMaskInput,
        };
      case "RNC":
        return {
          inputComponent: rncMaskInput,
        };
      case "passport":
        return {
          inputComponent: passportMaskInput,
        };
      default:
        return {
          title: "",
        };
    }
  }

  openHistory(){
    this.setState({historyDialog: true})
  }
  handleHistoryDialogClose(){
    this.setState({ historyDialog: false})
  }

  render() {
    const { classes } = this.props;
    return (
      <form onSubmit={this.handleSubmit} className={classes.root}>
        <AlertDialog
          open={this.state.dialog}
          message={this.state.errorMessage}
          title={this.state.dialogTitle}
          handleClose={this.handleDialogClose}
        />
        <ParticipantActivityHistory 
          open={this.state.historyDialog}
          participantHistory={this.state.participantHistory}
          handleClose={this.handleHistoryDialogClose}
          token={this.props.user.token}
        />
        <Paper className={classes.paper}>
          <Typography variant="h3">
            {translations.COMPANY_REPRESENTATIVE}
          </Typography>
          <Button
              variant="outlined"
              disableElevation
              style={{
                borderRadius: 3,
                borderColor: green[500],
                color: green[500],
                fontSize: 12,
                marginBottom: "1rem",
              }}
              onClick={this.openHistory} 
            >
              REGISTRO DE ACTIVIDAD
              <Timeline style={{ fontSize: 17, marginLeft: ".5rem" }} />
            </Button>
          <Grid item={true} xs={4}>
            <Typography className={classes.fileText}>
              Historial Crediticio
            </Typography>
            <FileUploadSelectInput
              contractId={this.state.uniqueId}
              url={this.state.clientCF}
              description="credit-file"
            />
          </Grid>

          {this.state.loading ? (
            <Loading message={translations.LOADING} />
          ) : (
            <div>
              <Grid container spacing={2}>
                <Grid item={true} xs={4}>
                  <TextField
                    label={"Rohi ID Barcelona"}
                    className={classes.textField}
                    value={this.state.rohiId}
                    disabled={true}
                  />
                </Grid>
                <Grid item={true} xs={4}>
                  <TextField
                    label={"Rohi ID IE"}
                    className={classes.textField}
                    value={this.state.rohiIeId}
                    disabled={true}
                  />
                </Grid>
                <Grid item={true} xs={4}>
                  <TextField
                    label={"Rohi ID MFS"}
                    className={classes.textField}
                    value={this.state.rohiMfsId}
                    disabled={true}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={5}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={translations.COOPERATIVE_ENTRY_DATE}
                    value={this.state.cooperativeEntryDate}
                    type="date"
                    onChange={(event) =>
                      this.handleChange(event, "cooperativeEntryDate")
                    }
                    error={this.state.cooperativeEntryDateError !== ""}
                    helperText={this.state.cooperativeEntryDateError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                    select
                    fullWidth
                    variant="outlined"
                    label={translations.IDENTIFICATION_TYPE}
                    value={this.state.identificationType}
                    onChange={(event) =>
                      this.handleSelectChange(event, "identificationType")
                    }
                    required={true}
                    error={this.state.identificationTypeError !== ""}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                    inputProps={{
                      id: "identificationType",
                      name: "identificationType",
                    }}
                  >
                    {IDENTIFICATION_TYPE.map((opt, indx) => (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography className={classes.fileText}>
                    Identificación
                  </Typography>
                  <FileUploadSelectInput
                    contractId={this.state.uniqueId}
                    url={this.state.clientIF}
                    description="identification-file"
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={5}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    label={translations.IDENTIFICATION}
                    value={this.state.identificationNumber}
                    onChange={(event) =>
                      this.handleChange(event, "identificationNumber")
                    }
                    error={this.state.identificationNumberError !== ""}
                    helperText={this.state.identificationNumberError}
                    InputProps={this.getIdentificationInput(
                      this.state.identificationType
                    )}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={translations.IDENTIFICATION_EXPIRATION_DATE}
                    value={this.state.identificationExpirationDate}
                    type="date"
                    onChange={(event) =>
                      this.handleChange(event, "identificationExpirationDate")
                    }
                    error={this.state.identificationExpirationDate !== ""}
                    helperText={this.state.identificationExpirationDateError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography className={classes.fileText}>Garantia</Typography>
                  <FileUploadSelectInput
                    contractId={this.state.uniqueId}
                    url={this.state.clientW}
                    description="warranty-file"
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={5}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    label={translations.NAME}
                    value={this.state.fullName}
                    onChange={(event) => this.handleChange(event, "fullName")}
                    error={this.state.fullNameError !== ""}
                    helperText={this.state.fullNameError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={translations.NICKNAME}
                    value={this.state.nickName}
                    onChange={(event) => this.handleChange(event, "nickName")}
                    error={this.state.nickNameError !== ""}
                    helperText={this.state.nickNameError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    fullWidth
                    select
                    variant="outlined"
                    label={translations.GENDER}
                    value={this.state.gender}
                    onChange={(event) =>
                      this.handleSelectChange(event, "gender")
                    }
                    error={this.state.genderError !== ""}
                    inputProps={{
                      id: "gender",
                      name: "gender",
                    }}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  >
                    {gender.map((opt, indx) => (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    label={translations.EMAIL}
                    placeholder="mycompany@email.com"
                    type="email"
                    value={this.state.email}
                    onChange={(event) => this.handleChange(event, "email")}
                    error={this.state.emailError !== ""}
                    helperText={this.state.emailError}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                    disabled={!this.state.is_administration}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    select
                    fullWidth
                    variant="outlined"
                    label={translations.CIVIL_STATUS}
                    value={this.state.civilStatus}
                    onChange={(event) =>
                      this.handleSelectChange(event, "civilStatus")
                    }
                    error={this.state.civilStatusError !== ""}
                    inputProps={{
                      id: "civilStatus",
                      name: "civilStatus",
                    }}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  >
                    {civilStatus.map((opt, indx) => (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    select
                    fullWidth
                    variant="outlined"
                    label={translations.EDUCATION_LEVEL}
                    value={this.state.educationLevel}
                    onChange={(event) =>
                      this.handleSelectChange(event, "educationLevel")
                    }
                    error={this.state.educationLevelError !== ""}
                    inputProps={{
                      id: "educationLevel",
                      name: "educationLevel",
                    }}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  >
                    {educationLevel.map((opt, indx) => (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    select
                    fullWidth
                    required
                    variant="outlined"
                    label={translations.AFFILIATE_STATE}
                    value={this.state.affiliateState}
                    onChange={(event) =>
                      this.handleSelectChange(event, "affiliateState")
                    }
                    error={this.state.affiliateStateError !== ""}
                    inputProps={{
                      id: "affiliateState",
                      name: "affiliateState",
                    }}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                    disabled={!this.state.is_administration}
                  >
                    {affiliateState.map((opt, indx) => (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    select
                    fullWidth
                    required
                    variant="outlined"
                    label={translations.AFFILIATE_TYPE}
                    value={this.state.affiliateType}
                    onChange={(event) =>
                      this.handleSelectChange(event, "affiliateType")
                    }
                    error={this.state.affiliateTypeError !== ""}
                    inputProps={{
                      id: "affiliateType",
                      name: "affiliateType",
                    }}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  >
                    {affiliateType.map((opt, indx) => (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    select
                    fullWidth
                    variant="outlined"
                    label={translations.TITLE}
                    value={this.state.prefix}
                    onChange={(event) =>
                      this.handleSelectChange(event, "prefix")
                    }
                    error={this.state.prefixError !== ""}
                    inputProps={{
                      id: "prefix",
                      name: "prefix",
                    }}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  >
                    {prefix.map((opt, indx) => {
                      return (
                        <MenuItem value={opt.value} key={indx}>
                          {opt.label}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    select
                    fullWidth
                    value={this.state.nationality}
                    onChange={(event) =>
                      this.handleSelectChange(event, "nationality")
                    }
                    variant="outlined"
                    label={translations.NATIONALITY}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                    inputProps={{
                      id: "nationality",
                      name: "nationality",
                    }}
                  >
                    {this.state.nationalityOptions.map((opt, indx) => (
                      <MenuItem value={opt} key={indx}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    required
                    fullWidth
                    variant="outlined"
                    label={translations.BIRTHDATE}
                    value={this.state.birthDate}
                    type="date"
                    onChange={(event) => this.handleChange(event, "birthDate")}
                    error={this.state.birthDateError !== ""}
                    helperText={this.state.birthDateError}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <FormControlLabel
                    style={{ verticalAlign: "bottom" }}
                    label="Posee acceso a COOPVIRTUAL"
                    control={
                      <CheckBox
                        color="primary"
                        checked={this.state.hasAccessToCoopvirtual}
                        onChange={(event) =>
                          this.handleCheckBoxChange(
                            event,
                            "hasAccessToCoopvirtual"
                          )
                        }
                      />
                    }
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{
                    display: this.state.hasAccessToCoopvirtual
                      ? "block"
                      : "none",
                  }}
                >
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Pin de acceso"
                    value={this.state.password}
                    onChange={(event) => this.handleChange(event, "password")}
                    required={this.state.password}
                    error={this.state.passwordError}
                    helperText={
                      this.state.passwordError ||
                      "Dejar en blanco sino desea actualizar."
                    }
                    InputLabelProps={{ style: styles.checkboxLabel }}
                    inputProps={{
                      id: "password",
                      name: "password",
                      maxLength: "4",
                      minLength: "4",
                    }}
                    hintText="Pin"
                    type="password"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{
                    display: this.state.hasAccessToCoopvirtual
                      ? "block"
                      : "none",
                  }}
                >
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="Repetir pin"
                    value={this.state.passwordConfirm}
                    onChange={(event) =>
                      this.handleChange(event, "passwordConfirm")
                    }
                    required={this.state.hasAccessToCoopvirtual}
                    error={
                      this.state.passwordConfirmError !== "" ||
                      this.state.password !== this.state.passwordConfirm
                    }
                    helperText={
                      this.state.password !== this.state.passwordConfirm
                        ? "Las contraseñas no coinciden."
                        : this.state.passwordConfirmError
                    }
                    InputLabelProps={{ style: styles.checkboxLabel }}
                    inputProps={{
                      id: "passwordConfirm",
                      name: "passwordConfirm",
                      maxLength: "4",
                      minLength: "4",
                    }}
                    hintText="Repetir contraseña"
                    type="password"
                    disabled={this.state.password.length < 1}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <FormControlLabel
                    style={{ verticalAlign: "bottom" }}
                    label={translations.DEBTOR_VEHICLE}
                    control={
                      <Checkbox
                        color="primary"
                        checked={this.state.hasDebtorAVehicle}
                        onChange={(event) =>
                          this.handleCheckBoxChange(event, "hasDebtorAVehicle")
                        }
                      />
                    }
                  />
                </Grid>
              </Grid>

              <div
                style={{
                  display: this.state.hasDebtorAVehicle ? "block" : "none",
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="model"
                      label={translations.VEHICLE_MODEL}
                      value={titleCaseHandler(this.state.vehicleModel)}
                      onChange={(event) =>
                        this.handleChange(event, "vehicleModel")
                      }
                      required={this.state.hasDebtorAVehicle ? true : false}
                      error={this.state.vehicleModelError !== ""}
                      helperText={this.state.vehicleModelError}
                      InputLabelProps={{ style: styles.checkboxLabel }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      name="brand"
                      variant="outlined"
                      label={translations.VEHICLE_BRAND}
                      value={titleCaseHandler(this.state.vehicleBrand)}
                      onChange={(event) =>
                        this.handleChange(event, "vehicleBrand")
                      }
                      required={this.state.hasDebtorAVehicle ? true : false}
                      error={this.state.vehicleBrandError !== ""}
                      helperText={this.state.vehicleBrandError}
                      InputLabelProps={{ style: styles.checkboxLabel }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="year"
                      label={translations.VEHICLE_YEAR}
                      value={this.state.vehicleYear}
                      onChange={(event) =>
                        this.handleChange(event, "vehicleYear")
                      }
                      required={this.state.hasDebtorAVehicle ? true : false}
                      error={this.state.vehicleYearError !== ""}
                      helperText={this.state.vehicleYearError}
                      InputProps={{
                        inputComponent: attorneyNumberMaskInput,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: styles.checkboxLabel,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      name="color"
                      label={translations.VEHICLE_COLOR}
                      value={titleCaseHandler(this.state.vehicleColor)}
                      onChange={(event) =>
                        this.handleChange(event, "vehicleColor")
                      }
                      required={this.state.hasDebtorAVehicle ? true : false}
                      error={this.state.vehicleColorError !== ""}
                      helperText={this.state.vehicleColorError}
                      InputLabelProps={{ style: styles.checkboxLabel }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      select
                      fullWidth
                      error={this.state.vehicleFinancialStatusError !== ""}
                      variant="outlined"
                      label={translations.VEHICLE_FINANCIAL_STATUS}
                      value={this.state.vehicleFinancialStatus}
                      name="fin_status"
                      onChange={(event) =>
                        this.handleSelectChange(event, "vehicleFinancialStatus")
                      }
                      required={this.state.hasDebtorAVehicle ? true : false}
                      inputProps={{
                        id: "vehicleFinancialStatus",
                        name: "vehicleFinancialStatus",
                      }}
                      InputLabelProps={{ style: styles.checkboxLabel }}
                    >
                      <MenuItem value="financed">
                        {translations.FINANCED}
                      </MenuItem>
                      <MenuItem value="paid">{translations.PAID}</MenuItem>
                      <MenuItem value="not own">
                        {translations.NOT_OWN}
                      </MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
              </div>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    select
                    fullWidth
                    variant="outlined"
                    label={translations.DWELLING_TYPE}
                    value={this.state.dwellingType}
                    onChange={(event) =>
                      this.handleSelectChange(event, "dwellingType")
                    }
                    inputProps={{
                      name: "dwellingType",
                    }}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  >
                    {dwellingTypes.map((opt, indx) => (
                      <MenuItem value={opt.value} key={indx}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {/* <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={translations.HOUSE_MONTHLY_PAYMENT}
                    value={this.state.houseMonthlyPayment}
                    onChange={(event)=>this.handleCurrencyInputChange(event, "houseMonthlyPayment")}
                    error={this.state.houseMonthlyPaymentError !== ""}
                    helperText={this.state.houseMonthlyPaymentError}
                    InputProps={{
                      inputComponent: MoneyInput,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    select
                    fullWidth
                    variant="outlined"
                    label="Moneda"
                    error={this.state.houseMonthlyPaymentCurrencyError !== ""}
                    value={this.state.houseMonthlyPaymentCurrency}
                    onChange={(event)=>this.handleSelectChange(event, "houseMonthlyPaymentCurrency")}
                    inputProps={{
                      id: "houseMonthlyPaymentCurrency",
                      name: "houseMonthlyPaymentCurrency",
                    }}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                    >
                      <MenuItem value="DOP">{translations.DOP}</MenuItem>
                      <MenuItem value="USD">{translations.USD}</MenuItem>
                    </TextField>
                </Grid> */}
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    label={translations.CELL_PHONE}
                    value={this.state.cellPhone}
                    onChange={(event) => this.handleChange(event, "cellPhone")}
                    type="phone"
                    placeholder="+1(809) 000-0000"
                    error={this.state.cellPhoneError !== ""}
                    helperText={this.state.cellPhoneError}
                    InputProps={{
                      inputComponent: phoneMaskInput,
                    }}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                    disabled={!this.state.is_administration}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={translations.HOME_PHONE}
                    value={this.state.homePhone}
                    onChange={(event) => this.handleChange(event, "homePhone")}
                    type="phone"
                    placeholder="+1(809) 000-0000"
                    error={this.state.homePhoneError !== ""}
                    helperText={this.state.homePhoneError}
                    InputProps={{
                      inputComponent: phoneMaskInput,
                    }}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={translations.ADDITIONAL_PHONE}
                    value={this.state.additionalPhone}
                    onChange={(event) =>
                      this.handleChange(event, "additionalPhone")
                    }
                    type="phone"
                    placeholder="+1(809) 000-0000"
                    error={this.state.additionalPhoneError !== ""}
                    helperText={this.state.additionalPhoneError}
                    InputProps={{
                      inputComponent: phoneMaskInput,
                    }}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    label={translations.WORK_PHONE}
                    value={this.state.workPhone}
                    onChange={(event) => this.handleChange(event, "workPhone")}
                    type="phone"
                    placeholder="+1(809) 000-0000"
                    error={this.state.workPhoneError !== ""}
                    helperText={this.state.workPhoneError}
                    InputProps={{
                      inputComponent: phoneMaskInput,
                    }}
                    InputLabelProps={{ style: styles.checkboxLabel }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={translations.WORK_PHONE_EXTENSION}
                    value={this.state.workPhoneExtension}
                    onChange={(event) =>
                      this.handleChange(event, "workPhoneExtension")
                    }
                    error={this.state.workPhoneExtensionError !== ""}
                    helperText={this.state.workPhoneExtensionError}
                    InputProps={{
                      inputComponent: attorneyNumberMaskInput,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: styles.checkboxLabel,
                    }}
                  />
                </Grid>
              </Grid>

              <Address
                city={this.state.city}
                cityError={this.state.cityError}
                country={this.state.country}
                countryError={this.state.countryError}
                handleChange={this.handleChange}
                handleSelect={this.handleSelect}
                reference={this.state.reference}
                referenceError={this.state.referenceError}
                region={this.state.region}
                regionError={this.state.regionError}
                sector={this.state.sector}
                sectorError={this.state.sectorError}
                state={this.state.state}
                stateError={this.state.stateError}
                title={translations.ADDRESS}
              />
              <ProfessionSelectInput
                selectedProfessionId={this.state.profession}
                addProfession={this.handleAddProfession}
                deleteProfession={this.handleDeleteProfession}
                title={translations.PROFESSION}
              />
              <div className={classes.controls}>
                <Button
                  variant="contained"
                  onClick={this.handleDelete}
                  className={classes.deleteButton}
                >
                  <DeleteIcon />
                  &nbsp;
                  {translations.DELETE}
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.greenButton}
                  style={{ marginLeft: 10 }}
                >
                  <SaveIcon />
                  &nbsp;
                  {translations.SAVE}
                </Button>
                <Button
                  onClick={this.handleResetQuestions}
                  variant="contained"
                  className={classes.yellowButton}
                  style={{ marginLeft: 10 }}
                >
                  <Restore />
                  &nbsp;
                  REINICIAR PREGUNTAS DE SEGURIDAD
                </Button>
              </div>
            </div>
          )}
        </Paper>
        {!this.state.loading && (
          <div>
            <ClientDocuments
              debtorId={this.state.uniqueId}
              openLoading={this.openLoading}
              closeLoading={this.handleDialogClose}
              fullName={this.state.fullName}
            />
            <FinancialProfile ownerId={this.state.uniqueId} />
            <WorkProfile ownerId={this.state.uniqueId} />
          </div>
        )}
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    companyRepresentatives: state.companyRepresentatives,
    user: state.user,
  };
}
export default withStyles(styles)(
  connect(mapStateToProps, {
    deleteCompanyRepresentative,
    fetchCompanyRepresentative,
    updateCompanyRepresentative,
  })(CompanyRepresentativeView)
);
