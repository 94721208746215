import React, { useState, useEffect } from 'react';
import {
    Grid, Typography, Button, Divider,Table,TableBody,TableCell,TableContainer, 
    TableHead, TableRow, Paper, Skeleton
} from '@material-ui/core';
import { 
    RadioGroupComponent, AutocompleteField, CheckboxField, DateField
} from '../../../components/SharedComponents/Fields';
import { CheckCircle, RemoveCircle, Info } from '@material-ui/icons';
import PcpTable from '../../../components/Calculators/AmortizationTable/PcpTable';
import {
    fetchTicket, fetchBankAccounts, fetchDepositBankAccounts,
    fetchCreditors, createCalculatedPayment, deleteCalculatedPayment, 
    fetchCalculatedPayments, fetchRohiTransactions, getRohiDmsQuotations,
    postRohiLoanRecalculation, fetchPaymentReceipt, printBalanceLetter,
    // createRohiParticipant, fetchCalculatedPayment
} from '../../../utils/axios';
import { termReductionOptions, headersPCP, headersLoan } from '../../../utils/';
import { 
    StepStatus, TabsStyled, TabStyled, 
    handleNextStep, validateCreditMethodTablesEmpty, validateSettlementTotal, 
    handleManageLendingChecks, handleState, handleModify, handleDelete, 
    ticketHistoryGeneral, FormWrapper, ticketHistory, settlementInitData,
    useCheckStepStatus
} from './../TicketGeneralMethods';

import  { settlementInit } from './../TicketGeneralMethods/consts';
import { 
    PaymentNegotiationTable, FundsVerificationTable
} from './../Tables';
import SettlementFormsTable from '../../../components/TicketWorkflows/Outlay/Form';
import NumberFormat from './../../../components/SharedComponents/NumberFormat';
import NotarialPayContractForm from '../../NotarialPayContract/NotarialPayContractForm';
import NotarialPayContractDetail from '../../NotarialPayContract/NotarialPayContractView';
import { PaymentReceiptContent } from './../../PayReceipt/Dialogs';
import { errorHandling, camelToSnakeCase, dateFormat } from '../../../components/SharedComponents/CustomHooks';

const getCreditorBarcelona = async (token, setUpdate) => {
    const options = {
        token: token,
        searchQuery: '430298557',
        limit: 1,
    }
    const creditor = await fetchCreditors(options).then(response =>  response.data.results);
    const cleanData = creditor.map(item => {
        return {
            type: item.type,
            uniqueId: item.uniqueId,
            document: 
                item.type === 'legal' ? 
                (item.institutionDetail.registry || '--') : 
                item.participantDetail.identificationNumber,
            fullName: 
                item.type === 'legal' ?
                (item.abbreviation || '--') :
                item.participantDetail.fullName,
        }
    });
    const data = cleanData.map(item => {return {name: `${item.fullName} | ${item.document}`, value: item.uniqueId} });
    setUpdate(data[0]);
    return data[0];
}

const getBankAccounts = async (props, setUpdate, setCreditorUpdate) => {
    const theCreditor = await getCreditorBarcelona(props.token, setCreditorUpdate);
    const endpoint = fetchBankAccounts({token: props.token, owner: theCreditor.uniqueId, accountType: 'checking'});
    endpoint.then(response => {
        setUpdate(response.data);
    });
}

const getClientBankAccounts = (props, setUpdate) => {
    const endpoint = fetchDepositBankAccounts({token: props.token, owner: props.ticket.customer});
    endpoint.then(response => {
        setUpdate(response.data);
    });
}

const lowerKeyComparer = (keys, id) => {
    return Object.keys(keys)
    .find(item => item.toLowerCase() === id.toLowerCase());
}

function RecalcPreview(props) {

    const [quotationData, setQuotationData] = useState([]);
    const [editIdx, setEditIdx] = useState(-1);
    const [pcpData, setPcpData] = useState([]);
    const [tableContent, setTableContent] = useState([]);

    const handleRemove = (i) => {
        const data = pcpData.filter((row, j) => j !== i);
        setPcpData(data);
    };

    const handleStartEdit = (i) => {
        setEditIdx(i);
    };
    
    const handleStopEdit = () => {
        setEditIdx(-1);
    };
    
    const handleChangeTD = (i, x) => {
        const data = pcpData.map((row, j) => (j === i ? x : row));
        setPcpData(data);
        handleStopEdit();
    };

    const handleSave = (data) => {
        setPcpData([...data]);
    }

    const handleRecalc = () => {
        if(!props.recalcDate) {
            props.handleToggleSimpleDialog('simple-dialog', `Debe seleccionar una fecha.`);
            return;
        }
        // if(!pcpData.length) {
        //     props.handleToggleSimpleDialog('simple-dialog', `Debe seleccionar al menos 1 PCP.`);
        //     return;
        // }
        const dbsInfo = {
            pcpData: pcpData,
            idDbsRohi: props.quotationData.idDbsRohi,
            appliedAmount: props.quotationData.appliedAmount,
            calcMethodology: props.quotationData.calcMethodology,
            remainingTerm: props.quotationData.remainingTerm
        };
        const recalcData = {
            token: props.token,
            uniqueId: props.ticket.uniqueId,
            recalculationDate: 
                typeof props.recalcDate === 'string' ? 
                props.recalcDate.substring(0, props.recalcDate.indexOf('T')) :
                dateFormat(props.recalcDate),
            confirmRecreation: false,
            dbsInfo: [{...dbsInfo}],
        }
        props.setLoading(true);
        const endpoint = postRohiLoanRecalculation(recalcData);
        endpoint.then(response => {
            const data = response.data[quotationData.uniqueId] || [];
            setTableContent(data);
            // set preview data to store it on ticket
            const previewInfo = {
                tableData: data,
                pcpData: pcpData,
                dbsInfo,
            };
            props.setRecalcPreview((prevState) => ({
                ...prevState,
                [quotationData.uniqueId]: previewInfo,
            }));
            props.handleToggleSimpleDialog('success-dialog');
            props.setLoading(false);
        }).catch(errors => {
            errorHandling(errors, props.handleToggleSimpleDialog);
            props.setLoading(false);
        });
    }

    useEffect(() => {
        const data = props.quotationData ? props.quotationData.quotation : [];
        setQuotationData(data);
        const recalcKey = lowerKeyComparer(props.recalcPreviewData, data.uniqueId);
        const previewData = props.recalcPreviewData[recalcKey];
        if(previewData) {
            setPcpData(previewData.pcpData || []);
            setTableContent(previewData.tableData || []);
        }else {
            setPcpData(data.pcpData || []);
        }
    }, [props.quotationData]);

    return(
        <Grid container direction='column'>
            <Typography
                variant={"subtitle1"}
                className={"section-header"}
                style={{fontWeight: 'bold', color:'#424242', marginLeft:5, marginTop:'2rem'}}
            >
                TABLA DE PCP
            </Typography>
            <PcpTable
                create={props.completed ? false : true}
                actions={props.completed ? false : true}
                tableLimit={10}
                data={pcpData}
                editIdx={editIdx}
                handleChange={handleChangeTD}
                handleRemove={handleRemove}
                handleSave={handleSave}
                handleStartEdit={handleStartEdit}
                handleStopEdit={handleStopEdit}
                header={
                    quotationData.productType !== "constant_fees" ? headersPCP : 
                    [...headersPCP, ...termReductionOptions]
                }
            />
            <Grid container justifyContent="flex-end" style={{marginTop:'2rem'}}>
                <Button
                    variant="contained"
                    className={
                        props.completed ?
                        "action-method-btn disabled" : "action-method-btn--yellow"
                    }
                    disabled={props.completed}
                    disableElevation
                    style={{height:35}}
                    onClick={handleRecalc}
                >
                    RECALCULAR
                </Button>
            </Grid>
            <Typography
                variant={"subtitle1"}
                className={"section-header"}
                style={{fontWeight: 'bold', color:'#424242', marginLeft:5, marginTop:'2rem'}}
            >
                RESULTADO RECALCULO
            </Typography>
            <TableContainer component={Paper} className="amort-table">
                <Table size="small" stickyHeader={true}>
                    <TableHead>
                        <TableRow>
                            {headersLoan.map((opt, indx) => {
                            return (
                                <TableCell
                                style={{ color: "#fff", background: "#61bf60" }}
                                key={indx}
                                >
                                {opt.name}
                                </TableCell>
                            );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableContent.map((opt, indx) => {
                            if (indx > 0) {
                            return (
                                <TableRow key={indx}>
                                {opt.map((campo, key) => (
                                    <TableCell key={key}>{campo}</TableCell>
                                ))}
                                </TableRow>
                            );
                            }
                            return false;
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid>
    )
}

function NegotiationProcess(props) {

    const stepStatus = useCheckStepStatus(props.ticket.steps, props.currentStep);
    const [settlementData, setSettlementData] = useState(null);
    const [paymentNegotiation, setPaymentNegotiation] = useState([]);
    const [paymentType, setPaymentType] = useState(null);
    const [creditor, setCreditor] = useState({});
    const [creditorAccounts, setCreditorAccounts] = useState([]);
    const [clientBankAccounts, setClientBankAccounts] = useState([]);
    const [payAssistance, setPayAssistance] = useState('');
    const [expand, setExpand] = useState(false);
    const [quotationData, setQuotationData] = useState([]);
    const [recalculationDate, setRecalculationDate] = useState(null);
    const [recalcPreviewData, setRecalcPreview] = useState({});
    const participant = props.ticket.participantDetail;

    const handleClickExpand = (form) => {
        const expandAction = form === expand ? false : form
        setExpand(expandAction);
    }

    const getPaymentInfo = () => {
        props.setLoading(true);
        const endpoint = fetchCalculatedPayments({token: props.token, ticket: props.ticket.uniqueId});
        endpoint.then(response => {
            const info = typeof response.data.length === 'undefined' ? [response.data] : response.data;
            // itarate in every key to change from camelCase to snake_case
            info.forEach(item => {
                item.expirationDetails.forEach(d => {
                    for(const property of Object.keys(d)) {
                        const newKey = camelToSnakeCase(property);
                        if(newKey !== property) {
                            delete Object.assign(d, {[newKey]: d[property]})[property];
                        }
                    }
                })
            });
            setPaymentNegotiation([...info]);
            if(paymentType?.value === 'extraordinary') {
                getQuotationsData([...info]);
            }
            props.setLoading(false);
        }).catch(errors => {
            errorHandling(errors, props.handleToggleSimpleDialog);
            props.setLoading(false);
        });
    }

    const getSettlementData = (step) => {
        const creditMethodTableData =
        props.ticket.steps.find(item => item.step === step).requirements
        .find(item => item.name === 'creditMethodTables').value;
        return creditMethodTableData || settlementInit;
    }
    const getTicketRequirements = () => {
        props.ticket.steps.find(item => item.step === props.currentStep)
        .requirements.forEach(item => {
            switch(item.name){
                case 'paymentAssistanceRequired':
                    setPayAssistance(item.value);
                    break;
                case 'paymentType':
                    setPaymentType(item.value || null);
                    break;
                case 'paymentDate':
                    setRecalculationDate(item.value);
                    break;
                case 'recalcPreview':
                    setRecalcPreview(item.value);
                    break;
                default:
                    break;
            }
        });
        const data = getSettlementData(props.currentStep);
        data.creditorAccounts = creditorAccounts;
        setSettlementData(data);
        getPaymentInfo();
    }

    const getTicket = () => {
        props.setLoading(true);
        const endpoint = fetchTicket(props.token, props.ticket.uniqueId);
        endpoint.then(() => {
            props.setLoading(false);
        }).catch(errors => {
            errorHandling(errors, props.handleToggleSimpleDialog);
            props.setLoading(false);
        });
    }

    const handleChangeDate = (event) => {
        setRecalculationDate(event);
    }

    const getQuotationsData = (paymentData) => {
        if(!paymentData.length) {
            setQuotationData([]);
            return;
        }
        const dbsInfo = paymentData.map(item => item.expirationDetails).map(eds => {
            return eds.map(ed => {
                return {
                    id_dbs_rohi: ed.id_dbs_rohi,
                    amount: Number(ed.monto_aplicado),
                    calcMethodology: ed.metodologia_pago,
                    remainingTerm: ed.periodos_restantes
                }
            })
        }).reduce((acc, val) => {
            return acc.concat(val);
        }, []);

        const data = {
            token: props.token,
            id_dbs_rohi: dbsInfo.map(item => item.id_dbs_rohi).join(','),
            unique_id: props.ticket.uniqueId,
        }
        props.setLoading(true);
        const endpoint = getRohiDmsQuotations(data);
        endpoint.then(response => {
            response.data.forEach(item => {
                const foundDbsInfo = dbsInfo.find(dbs => dbs.id_dbs_rohi === item.idDbsRohi);
                item.appliedAmount = foundDbsInfo ? foundDbsInfo.amount : 0;
                item.calcMethodology = foundDbsInfo ? foundDbsInfo.calcMethodology : "";
                item.remainingTerm = foundDbsInfo ? foundDbsInfo.remainingTerm : "";
            })
            setQuotationData(response.data);
            props.setLoading(false);
        }).catch(errors => {
            errorHandling(errors, props.handleToggleSimpleDialog);
            props.setLoading(false);
        });
    }

    const handlePaymentType = async (event, value) => {
        props.ticket.steps.find(item => item.step === props.currentStep).requirements
        .find(item => item.name === 'paymentType').value = value;
        await ticketHistoryGeneral(props, '').then( async (response) => {
            const history = response;
            await props.modifyTicketStep(props.ticket.steps, props.ticket, history, undefined);
        });
        if(!paymentType) {
            props.handleToggleSimpleDialog('simple-dialog', `Al seleccionar un tipo de pago, sólo podrá crear negociaciones de este tipo.`);
        }
        setPaymentType(value);
    }

    const postPaymentInfo = (paymentData) => {
        const delayedAmount = paymentData.expirationDetails.find(item => Number(item.monto_atrasado) > 0);
        if(delayedAmount) {
            props.handleToggleSimpleDialog(
                'simple-dialog', 
                `Uno de los prestamos de la calculadora esta en atraso, 
                eliminar el record de la calculadora o crear otro ticket para un pago convencional`
            );
            return;
        }
        const currentPaymentsIds = paymentData.expirationDetails.map(pd => pd.id_dbs_rohi);
        let foundId = false;
        for(let i = 0; paymentNegotiation.length > i; i++) {
            const ids = paymentNegotiation[i].expirationDetails.map(ed => ed.id_dbs_rohi);
            const found = currentPaymentsIds.find(cpi => ids.includes(cpi));
            if(found) {
                foundId = true;
                break;
            }
        }
        if(foundId) {
            props.handleToggleSimpleDialog(
                'simple-dialog', 
                `Uno de los desembolsos seleccionados ya se encuentra en una negociación de pago.`
            );
            return;
        }
        // paymentNegotiation.forEach(pn => {
        //     const ids = pn.expirationDetails.map(ed => ed.id_dbs_rohi);
        //     const found = currentPaymentsIds.find(cpi => ids.includes(cpi));
        //     if(found) {
        //         foundId = true; 
        //         return;
        //     }
        // });
        const data = {...paymentData, owner: props.ticket.customer, ticket: props.ticket.uniqueId};
        Object.keys(data).forEach(item => {
            if(!data[item] || !data[item].length) {
                delete data[item];
            }
        });
        props.setLoading(true);
        const endpoint = createCalculatedPayment(props.token, data);
        endpoint.then(response => {
            const info = response.data;
            info.expirationDetails.forEach(d => {
                for(const property of Object.keys(d)) {
                    const newKey = camelToSnakeCase(property);
                    if(newKey !== property) {
                        delete Object.assign(d, {[newKey]: d[property]})[property];
                    }
                }
            });
            const newData = paymentNegotiation.concat(info);
            setPaymentNegotiation(newData);
            if(paymentType?.value === 'extraordinary') {
                getQuotationsData(newData);
                // setRecalcPreview({});
            }
            props.handleToggleDialog(undefined);
            props.setLoading(false);
        }).catch(errors => {
            errorHandling(errors, props.handleToggleSimpleDialog);
            props.setLoading(false);
        });
    }
    const deletePayment = (uniqueId) => {
        props.setLoading(true);
        const endpoint = deleteCalculatedPayment(props.token, uniqueId);
        endpoint.then(() => {
            getPaymentInfo();
            props.handleToggleSimpleDialog('success-dialog');
            props.setLoading(false);
        }).catch(errors => {
            errorHandling(errors, props.handleToggleSimpleDialog);
            props.setLoading(false);
        });
    }

    const handleChangeAssistance = (event) => {
        setPayAssistance(event.target.value);
    }

    const handleCompleteStep = async () => {
        if(!paymentType) {
            props.handleToggleSimpleDialog('simple-dialog', `Debe seleccionar un tipo de pago.`);
            return;
        }
        const tableEmpty = await validateCreditMethodTablesEmpty(settlementData);
        if(tableEmpty) {
            props.handleToggleSimpleDialog('simple-dialog', `Todas las tablas de formas de liquidación seleccionadas deben tener al menos un registro.`);
            return;
        }
        const negotiationTotal = paymentNegotiation.reduce((acc, val) => {
            const formattedNumber = (acc + parseFloat(val.totalToPay)).toFixed(3);
            return Number(formattedNumber);
        }, 0);

        const differenceText = validateSettlementTotal(negotiationTotal, settlementData).text;

        await handleManageLendingChecks(props, settlementData.checks);

        if(paymentNegotiation.length < 1) {
            props.handleToggleSimpleDialog('simple-dialog', `Debe agregar al menos una negociación de pago.`);
            return;
        }
        
        if(paymentType === 'extraordinary') {
            if(!recalculationDate) {
                props.handleToggleSimpleDialog('simple-dialog', `Debe seleccionar una fecha de pago.`);
                return;
            }
            const recalcKeys = Object.keys(recalcPreviewData).map(item => item.toLowerCase());
            const quotationKeys = quotationData.map(item => item.quotation.uniqueId.toLowerCase());
            const diffKeys = quotationKeys.find(item => !recalcKeys.includes(item));
            const incompleteRecalc = Object.keys(recalcPreviewData).find(
                item => 
                (recalcPreviewData[item].tableData && !recalcPreviewData[item].tableData.length)
            );
            if(diffKeys || incompleteRecalc) {
                props.handleToggleSimpleDialog('simple-dialog', `Debe completar todas las vistas previas de recalculo.`);
                return;
            }
        }

        if(differenceText === 'no_data') {
            props.handleToggleSimpleDialog('simple-dialog', `Debe seleccionar al menos un método de pago.`);
            return;
        }
        if(differenceText && differenceText !== 'no_data') {
            props.handleToggleSimpleDialog('simple-dialog', `El total de formas de liquidación ${differenceText} al monto de negociación de pago.`);
            return;
        }
        if(!payAssistance) {
            props.handleToggleSimpleDialog('simple-dialog', `Seleccione una asistencia requerida para pago.`);
            return;
        }
        const approval = props.ticket.steps.find(item => item.approvalObj).approvalObj;
        approval.negotiationTotal = negotiationTotal;

        props.ticket.steps.find(item => item.step === props.currentStep).requirements
        .forEach(item => {
            switch(item.name) {
                case 'paymentType':
                    item.value = paymentType;
                    break;
                case 'creditMethodTables':
                    item.value = settlementData;
                    break;
                case 'paymentNegotiation':
                    const ids = paymentNegotiation.reduce((acc, val) => {
                        return acc.concat(val.uniqueId);
                    }, []);
                    item.value = ids;
                    break;
                case 'paymentAssistanceRequired':
                    item.value = payAssistance;
                    break;
                case 'paymentDate':
                    item.value = recalculationDate;
                    break;
                case 'recalcPreview':
                    item.value = recalcPreviewData;
                    break;
                default:
                    break;
            }
        });
        handleNextStep(true, props, props.ticket.steps, getTicket);
        props.handleToggleSimpleDialog(undefined);
    }

    useEffect(() => {
        getTicketRequirements();
        getBankAccounts(props, setCreditorAccounts, setCreditor, creditor);
        getClientBankAccounts(props, setClientBankAccounts);
    }, [props.ticket, props.reloadTicket]);

    useEffect(() => {
        const previewQuotations = Object.keys(recalcPreviewData);
        const newPreview = {...recalcPreviewData}
        if(previewQuotations.length) {
            const currentQuotations = quotationData.map(item => item.quotation.uniqueId);
            previewQuotations.forEach(key => {
                if(!currentQuotations.includes(key)) {
                    delete newPreview[key];
                }
            });
            setRecalcPreview({...newPreview});
        }
    }, [quotationData]);

    return(
        <div style={{padding: '16px'}}>
            <Grid container item xs={6} justifyContent="space-between" direction="column" style={{margin: '1rem 0'}}>
                <StepStatus 
                    completed={stepStatus}
                    finalStep={true}
                    handleNextStep={
                        (paymentType && paymentType.value === 'extraordinary') ?
                        props.handleToggleSimpleDialog
                        .bind(this, 'confirm-dialog', 'proceed-complete-negotiation', {callback: handleCompleteStep})
                        :
                        handleCompleteStep
                    }
                    ticketCompleted={props.ticketCompleted}
                    roles={props.roles}
                />
            </Grid>

            <Grid container item xs={12} justifyContent="space-between" direction="column" style={{margin: '1rem 0'}}>
                <Typography
                    variant={"subtitle1"}
                    className={"section-header"}
                    style={{fontWeight: 'bold', color:'#424242', marginLeft:5, marginTop:'2rem'}}
                >
                    NEGOCIACIÓN DE PAGO
                </Typography>

                <Divider style={{marginBottom:'2rem'}}/>

                <AutocompleteField
                    xs={6}
                    containerClass="field-container"
                    fieldClass="fields"
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel="Tipo de pago"
                    fieldID="payment-type-field"
                    fieldInnerLabel="Seleccione..."
                    fieldVariant="outlined"
                    value={paymentType}
                    handleChange={handlePaymentType}
                    options={[
                        {name: 'Convencional', value: 'conventional'},
                        {name: 'Extraordinario', value: 'extraordinary'}
                    ]}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) => option.value === value.value}
                    disabled={stepStatus || props.ticketCompleted || paymentNegotiation.length > 0}
                />
                <div style={{margin:'1rem 0'}} />
                <PaymentNegotiationTable
                    data={paymentNegotiation}
                    participant={participant}
                    handleToggleDialog={props.handleToggleDialog}
                    handleToggleSimpleDialog={props.handleToggleSimpleDialog}
                    setPaymentInfo={postPaymentInfo}
                    deletePayment={deletePayment}
                    paymentType={paymentType}
                    disabled={stepStatus || props.ticketCompleted}
                />
                {
                    (paymentType && paymentType.value === 'extraordinary') &&
                    <Grid container direction="column" style={{marginTop:'1rem'}}>
                        <DateField 
                            xs={4}
                            containerClass="field-container pd-y-2"
                            typeVariant="subtitle1"
                            typeClass="field-label"
                            fieldLabel="Fecha de pago"
                            dateFieldID="rohi-recalculation-date"
                            fullWidth={true}
                            dateFieldClass="date-field"
                            inputVariant="outlined"
                            dateFieldLabel="Fecha del pago"
                            format="dd/MM/yyyy"
                            value={recalculationDate || null}
                            handleChange={handleChangeDate}
                            disabled={stepStatus || props.ticketCompleted}
                        />
                
                        <Typography
                            variant={"subtitle1"}
                            className={"section-header"}
                            style={{fontWeight: 'bold', color:'#424242', marginLeft:5, marginTop:'2rem'}}
                        >
                            VISTA PREVIA RECALCULO
                        </Typography>
                        <Divider style={{marginBottom:'2rem'}}/>
                        {
                            quotationData.length ?
                                quotationData.map((item, index) => {
                                    const qData = item.quotation;
                                    const qId = qData && qData.uniqueId;
                                    const id = lowerKeyComparer(recalcPreviewData, qId);
                                    return(
                                        <Grid key={`quotation-recalculate-view-${index}`} container item xs={12}>
                                            <FormWrapper
                                                title={qData.referenceCode}
                                                name={`quotation-recalculate-view-${index}`}
                                                contentType={'Formulario vista previa'}
                                                headerAction={
                                                    (recalcPreviewData[id] && recalcPreviewData[id].tableData.length) ?
                                                        <CheckCircle style={{fontSize:20, color: '#44c94d'}} />
                                                    :
                                                        <RemoveCircle style={{fontSize:20, color: '#edc71c'}} />
                                                }
                                                form=
                                                {
                                                    <RecalcPreview
                                                        {...props}
                                                        quotationData={item}
                                                        recalcDate={recalculationDate}
                                                        setRecalcPreview={setRecalcPreview}
                                                        recalcPreviewData={recalcPreviewData}
                                                        completed={stepStatus || props.ticketCompleted}
                                                    />
                                                }
                                                handleExpand={handleClickExpand.bind(this, qData.uniqueId)}
                                                expand={expand === qData.uniqueId}
                                            />
                                        </Grid>
                                    )
                                })
                            :
                                [1,2].map((item, index) => {
                                    return(
                                        <div key={index} style={{width:'100%', height:65, backgroundColor:'#f7f7f7', borderRadius:4, margin:'1rem 0'}} />
                                    )
                                })
                        }
                    </Grid>
                }

                <Typography
                    variant={"subtitle1"}
                    className={"section-header"}
                    style={{fontWeight: 'bold', color:'#424242', marginLeft:5, marginTop:'2rem'}}
                >
                    FORMAS DE LIQUIDACIÓN
                </Typography>
                <Divider style={{marginBottom:'2rem'}}/>
                {
                    settlementData &&
                    <SettlementFormsTable
                        token={props.token}
                        handleToggleSimpleDialog={props.handleToggleSimpleDialog}
                        rohiId={participant.rohiId || null}
                        state={settlementData}
                        clientData={`${participant.fullName || '---'} | ${participant.identificationNumber || '---' }`}
                        clientId={[props.ticket.customer]}
                        originAccounts={
                            creditorAccounts.length > 0 ? creditorAccounts.map(item => {
                                return {
                                    label: item.name,
                                    value: item.number,
                                    type: item.accountType,
                                }
                            }) : []
                        }
                        clientAccounts={clientBankAccounts}
                        destinyAccounts={
                            clientBankAccounts?.length > 0 ? clientBankAccounts.map(item => {
                                return {
                                    name: item.name,
                                    value: item.number,
                                }
                            }) : []
                        }
                        readOnly={stepStatus || props.ticketCompleted}
                        fromTicket={true}
                        paymentTicket={true}
                        ticketCompleted={props.ticketCompleted}
                        handleState={handleState.bind(this, setSettlementData, settlementData)}
                        handleAdd={handleModify.bind(this, setSettlementData, settlementData)}
                        handleReplace={handleModify.bind(this, setSettlementData, settlementData)}
                        handleEdit={handleModify.bind(this, setSettlementData, settlementData)}
                        handleDelete={handleDelete.bind(this, setSettlementData, settlementData)}
                    />

                }
                <Typography
                    variant={"subtitle1"}
                    className={"section-header"}
                    style={{fontWeight: 'bold', color:'#424242', marginLeft:5, marginTop:'2rem'}}
                >
                    ASISTENCIA REQUERIDA PARA PAGO
                </Typography>
                <Divider style={{marginBottom:'2rem'}}/>
                <RadioGroupComponent
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldLabel="Seleccione una opción"
                    fieldID="radio-field-payment-assistance"
                    fieldClass="fields"
                    row={true}
                    value={payAssistance}
                    options={[{text: 'COBRAR', value: 'charge'}, {text: 'MENSAJERÍA', value: 'messaging'}, {text: 'CONCILIAR', value: 'conciliate'}]}
                    onChange={handleChangeAssistance}
                    disabled={props.ticketCompleted}
                />
            </Grid>
        </div>
    )
}

function PaymentReconciliation(props) {

    const stepStatus = useCheckStepStatus(props.ticket.steps, props.currentStep);
    const [settlementData, setSettlementData] = useState(null);
    const [, setCreditor] = useState({});
    const [paymentType, setPaymentType] = useState(null);
    const [creditorAccounts, setCreditorAccounts] = useState([]);
    const [clientBankAccounts, setClientBankAccounts] = useState([]);
    const [selected, setSelected] = useState([]);
    const [fundsVerificationData, setFundsVerificationData] = useState([]);
    const [reconciliedPayment, setReconciliedPayment] = useState(false);
    const [recalculationDate, setRecalculationDate] = useState(null);
    const [recalcPreviewData, setRecalcPreview] = useState({});
    const [approvalObj, setApprovalObj] = useState(null);
    const [remainingAmount, setRemainingAmount] = useState(null);
    const participant = props.ticket.participantDetail;

    const getTicket = () => {
        props.setLoading(true);
        const endpoint = fetchTicket(props.token, props.ticket.uniqueId);
        endpoint.then(() => {
            props.setLoading(false);
        }).catch(errors => {
            errorHandling(errors, props.handleToggleSimpleDialog);
            props.setLoading(false);
        });
    }

    const getSettlementData = (step) => {
        const creditMethodTableData =
        props.ticket.steps.find(item => item.step === step).requirements
        .find(item => item.name === 'creditMethodTables').value;
        return creditMethodTableData;
    }
    const getRohiTransactions = (recalcData) => {
        const dbsInfo = Object.keys(recalcData).map(item => recalcData[item].dbsInfo.idDbsRohi);
        const data = {
            token: props.token,
            outlay_id: dbsInfo.length ? dbsInfo.join(',') : "",
        }
        props.setLoading(true);
        const endpoint = fetchRohiTransactions(data);
        endpoint.then(response => {
            if(response.data.length) {
                response.data.forEach((item, index) => {
                    item.id = item.codigoDbs + '-' + index;
                });
            }
            setFundsVerificationData(response.data);
            props.setLoading(false);
        }).catch(errors => {
            errorHandling(errors, props.handleToggleSimpleDialog);
            props.setLoading(false);
        });
    }
    const getTicketRequirements = () => {
        props.ticket.steps.find(item => item.step === 'negotiation_process')
        .requirements.forEach(item => {
            switch(item.name){
                case 'paymentType':
                    setPaymentType(item.value);
                    break;
                case 'paymentDate':
                    setRecalculationDate(item.value || null);
                    break;
                case 'recalcPreview':
                    setRecalcPreview(item.value);
                    getRohiTransactions(item.value);
                    break;
                default:
                    break;
            }
        });
        const data = getSettlementData(props.currentStep) || getSettlementData('negotiation_process');
        setSettlementData(data);
        setReconciliedPayment(props.ticket.status === 'payment_reconciled' ? true : false);
    }

    const handleSelected = (selection) => {
        // console.log(selection);
    }

    const validateReference = () => {
        if(!reconciliedPayment) {
            const checksNoReference = settlementData.checks?.find(item => !item.referenceNumber) && 'cheques';
            const banksNoReference = settlementData.bankTransfers?.find(item => !item.reference) && 'transferencias bancarias';
            const cashNoReference = settlementData.cash?.find(item => !item.reference) && 'efectivos';
            const validateReference = checksNoReference || banksNoReference || cashNoReference;
            if(validateReference) {
                props.handleToggleSimpleDialog('simple-dialog', `Existen ${validateReference} que no tienen referencia colocada.`);
                return true;
            }
        }
        return false;
    }

    const handleReconciliedPayment = () => {
        if(validateReference()) {
            return;
        }
        props.ticket.steps.find(item => item.step === props.currentStep).requirements
        .find(item => item.name === 'creditMethodTables').value = settlementData;
        const dataToModify = {
            status: reconciliedPayment ? 'new_request' : 'payment_reconciled',
            steps: props.ticket.steps,
        }
        const historyDescription = `Se ha modificado el estado del ticket a: ${reconciliedPayment ? 'Nuevo' : 'Pago conciliado'}`;
        ticketHistoryGeneral(props, historyDescription).then(response => {
            const history = response;
            props.modifyTicket(dataToModify, history);
        });
        setReconciliedPayment(!reconciliedPayment);
    }

    const updateRecalcStatus = async () => {
        approvalObj.recalcStatus = 'completed';
        await ticketHistoryGeneral(props, '').then( async (response) => {
            const history = response;
            await props.modifyTicketStep(props.ticket.steps, props.ticket, history, undefined);
        });
    }

    const handleRecalculate = () => {
        if(!selected.length) {
            props.handleToggleSimpleDialog('simple-dialog', `Debe seleccionar al menos 1 desembolso.`);
            return true;
        }
        const selectionTotal = selected.reduce((acc, val) => {
            const formattedNumber = (acc + parseFloat(val.montoPagado)).toFixed(3);
            return Number(formattedNumber);
        }, 0);
        const paymentNegTotal = Number(approvalObj.negotiationTotal);
        const totalIsEqual = (paymentNegTotal && selectionTotal) ? paymentNegTotal === selectionTotal ? true : false : false;
        if(!totalIsEqual) {
            props.handleToggleSimpleDialog(
                'simple-dialog', 
                `El total de negociación de pago no coincide con el total de transacciones seleccionado`
            );
            return;
        }
        const selectedIds = selected.map(item => item.codigoDbs);
        const dbsData = 
            Object.keys(recalcPreviewData)
            .map(
                item => 
                selectedIds.includes(recalcPreviewData[item].dbsInfo.idDbsRohi) && 
                recalcPreviewData[item].dbsInfo
            ).filter(item => item);

        const recalcData = {
            token: props.token,
            uniqueId: props.ticket.uniqueId,
            recalculationDate: recalculationDate.substring(0, recalculationDate.indexOf('T')),
            confirmRecreation: true,
            dbsInfo: dbsData,
        }
        props.setLoading(true);
        const endpoint = postRohiLoanRecalculation(recalcData);
        endpoint.then(() => {
            updateRecalcStatus();
            props.handleToggleSimpleDialog('success-dialog');
            props.setLoading(false);
        }).catch(errors => {
            errorHandling(errors, props.handleToggleSimpleDialog);
            props.setLoading(false);
        });
    }

    const handleCompleteStep = async () => {
        if(!reconciliedPayment) {
            props.handleToggleSimpleDialog('simple-dialog', `Debe marcar la opción de pago conciliado para proceder.`);
            return;
        }
        if(approvalObj && !approvalObj.recalcStatus && (paymentType?.value === 'extraordinary')) {
            props.handleToggleSimpleDialog('simple-dialog', `Debe completar el recalculo para proceder.`);
            return;
        }
        props.ticket.steps.find(item => item.step === props.currentStep).requirements
        .forEach(item => {
            switch(item.name) {
                case 'creditMethodTables':
                    item.value = settlementData;
                    break;
                default:
                    break;
            }
        });
        handleNextStep(true, props, props.ticket.steps, getTicket);
        // if(paymentType && paymentType.value === 'extraordinary') {
        //     handleNextStep(true, props, props.ticket.steps, getTicket);
        // }else {
        //     props.ticket.steps.find(item => item.step === 'last_confirm').status = 'omitted';
        //     await handleNextStep(true, props, props.ticket.steps, getTicket, 'finalStep');
        //     props.handleToggleSimpleDialog();
        // }
    }

    useEffect(() => {
        getBankAccounts(props, setCreditorAccounts, setCreditor);
        getClientBankAccounts(props, setClientBankAccounts);
        getTicketRequirements();
    }, [props.ticket, props.reloadTicket]);

    useEffect(() => {
        const aObj = props.ticket.steps.find(item => item.approvalObj).approvalObj
        setApprovalObj(aObj);
        setRemainingAmount(aObj?.negotiationTotal || 0);
    }, [approvalObj]);

    useEffect(() => {
        if(approvalObj && approvalObj.negotiationTotal) {
            const selectionTotal = selected.reduce((acc, val) => {
                const formattedNumber = (acc + parseFloat(val.montoPagado)).toFixed(3);
                return Number(formattedNumber);
            }, 0);
            const remaining = approvalObj.negotiationTotal - selectionTotal;
            setRemainingAmount(remaining);
        }
    }, [selected]);

    return(
        <div style={{padding: '16px'}}>
            <Grid container item xs={6} justifyContent="space-between" direction="column" style={{margin: '1rem 0'}}>
                <StepStatus 
                    completed={stepStatus}
                    finalStep={true}
                    handleNextStep={handleCompleteStep}
                    ticketCompleted={props.ticketCompleted}
                    roles={props.roles}
                />
            </Grid>
            
            {
                (paymentType && paymentType.value === 'extraordinary') &&
                <Paper style={{padding:'.5rem 1rem', marginRight:'1rem', maxWidth:400}}>
                    <Typography
                        variant="subtitle1"
                        style={{color:'#424242'}}
                    >
                        <div style={{display:'flex', alignItems:'center', justifyContent:'flex-start'}}>
                            <span style={{color: '#424242', fontSize:15, fontWeight:'bold'}}>
                                <span style={{fontWeight:'normal'}}>ESTADO RECALCULO:&nbsp;</span>
                                {(approvalObj && approvalObj.recalcStatus) ? 'COMPLETADO' : 'NO REALIZADO'}
                            </span>
                        </div>
                    </Typography>
                </Paper> 
            }


            <Grid container item xs={12} justifyContent="space-between" direction="column" style={{margin: '1rem 0'}}>
                <Grid item container xs={12} justifyContent="space-between" alignContent="center">
                    <Typography
                        variant={"subtitle1"}
                        className={"section-header"}
                        style={{fontWeight: 'bold', color:'#424242', marginLeft:5, marginTop:'2rem'}}
                    >
                        VERIFICACIÓN DE FONDOS
                    </Typography>
                    <CheckboxField
                        xs={6}
                        alignItems="flex-end"
                        check={reconciliedPayment}
                        handleChange={handleReconciliedPayment}
                        color="primary"
                        textColor="#43A047"
                        label="PAGO CONCILIADO"
                        disabled={props.ticketCompleted}
                        style={{justifyContent:'flex-end'}}
                    />
                </Grid>
                <Divider style={{marginBottom:'2rem'}}/>
                {
                    settlementData &&
                    <SettlementFormsTable
                        token={props.token}
                        handleToggleSimpleDialog={props.handleToggleSimpleDialog}
                        rohiId={participant.rohiId || null}
                        state={settlementData}
                        clientData={`${participant.fullName || '---'} | ${participant.identificationNumber || '---' }`}
                        clientId={[props.ticket.customer]}
                        originAccounts={
                            creditorAccounts.length > 0 ? creditorAccounts.map(item => {
                                return {
                                    label: item.name,
                                    value: item.number,
                                    type: item.accountType,
                                }
                            }) : []
                        }
                        clientAccounts={clientBankAccounts}
                        destinyAccounts={
                            clientBankAccounts?.length > 0 ? clientBankAccounts.map(item => {
                                return {
                                    name: item.name,
                                    value: item.number,
                                }
                            }) : []
                        }
                        fromTicket={true}
                        readOnly={true}
                        paymentTicket={true}
                        enableReference={true}
                        ticketCompleted={reconciliedPayment || props.ticketCompleted}
                        handleState={handleState.bind(this, setSettlementData, settlementData)}
                        handleAdd={handleModify.bind(this, setSettlementData, settlementData)}
                        handleReplace={handleModify.bind(this, setSettlementData, settlementData)}
                        handleEdit={handleModify.bind(this, setSettlementData, settlementData)}
                        handleDelete={handleDelete.bind(this, setSettlementData, settlementData)}
                    />
                }
                {
                    paymentType?.value === 'extraordinary' &&
                    <>
                        <Typography
                            variant={"subtitle1"}
                            className={"section-header"}
                            style={{fontWeight: 'bold', color:'#424242', marginLeft:5, marginTop:'2rem'}}
                        >
                            VERIFICACIÓN DE REGISTROS
                        </Typography>
                        <Divider style={{marginBottom:'2rem'}}/>
                        <div>
                        <Paper style={{padding:'.5rem 1rem', margin:'1rem 0', maxWidth:400}}>
                            <Typography
                                variant="subtitle1"
                                style={{color:'#424242'}}
                            >
                                <div style={{display:'flex', alignItems:'center', justifyContent:'flex-start'}}>
                                    <span style={{color: '#424242', fontSize:15, fontWeight:'bold'}}>
                                        <span style={{fontWeight:'normal'}}>TOTAL NEGOCIACIÓN:&nbsp;</span>
                                        {
                                            approvalObj && approvalObj.negotiationTotal ?
                                                <NumberFormat prefix={"$"} decimalScale={3} value={approvalObj.negotiationTotal} />
                                            :
                                                <span>{"- - -"}</span>
                                        }
                                    </span>
                                </div>
                            </Typography>
                        </Paper> 
                        <Paper style={{padding:'.5rem 1rem', margin:'1rem 0', maxWidth:400}}>
                            <Typography
                                variant="subtitle1"
                                style={{color:'#424242'}}
                            >
                                <div style={{display:'flex', alignItems:'center', justifyContent:'flex-start'}}>
                                    <span style={{color: '#424242', fontSize:15, fontWeight:'bold'}}>
                                        <span style={{fontWeight:'normal'}}>TOTAL RESTANTE:&nbsp;</span>
                                        {
                                            remainingAmount ?
                                                <NumberFormat prefix={"$"} decimalScale={3} value={remainingAmount} />
                                            :
                                                <span>{"- - -"}</span>
                                        }
                                    </span>
                                </div>
                            </Typography>
                        </Paper> 
                        </div> 

                        <FundsVerificationTable
                            data={fundsVerificationData}
                            selected={(paymentType && paymentType.value === 'extraordinary') && selected}
                            setSelected={(paymentType && paymentType.value === 'extraordinary') && setSelected}
                            handleSelected={(paymentType && paymentType.value === 'extraordinary') && handleSelected}
                        />
                        {
                            (paymentType && paymentType.value === 'extraordinary') &&
                            <Grid item container xs={12} justifyContent="flex-end" style={{margin:'2rem 0'}}>
                                <Button
                                    variant="contained"
                                    className={
                                        (approvalObj.recalcStatus || props.ticketCompleted) ?
                                        "action-method-btn disabled" : "action-method-btn"
                                    }
                                    disabled={(Boolean(approvalObj.recalcStatus) || props.ticketCompleted)}
                                    disableElevation
                                    style={{height:35}}
                                    onClick={handleRecalculate}
                                >
                                    REALIZAR RECÁLCULO
                                </Button>
                            </Grid>
                        }
                    </>
                }
            </Grid>
        </div>
    )
}

function LastConfirm(props) {
    const stepStatus = useCheckStepStatus(props.ticket.steps, props.currentStep);
    const [paymentReceiptData, setPaymentReceiptData] = useState({});
    const [expand, setExpand] = useState('');
    const [formIds, setFormIds] = useState({});

    const paymentType = props.ticket.steps.find(item => item.step === 'negotiation_process')
        .requirements.find(item => item.name === 'paymentType').value;
    const ticketNegProcess = props.ticket.steps.find(item => item.step === 'negotiation_process').requirements;
    const paymentDate = ticketNegProcess.find(item => item.name === 'paymentDate').value || '';
    const defaultReceipt = {
        participantName: props.ticket.participantDetail?.fullName || '--',
        participantIdentification: props.ticket.participantDetail?.identificationNumber || '--',
        participant: props.ticket.customer,
        concept: '',
        receiptDate: paymentDate,
        receiptAmountCurrency: 'DOP',
        receiptAmount: '',
        ticket: props.ticket.uniqueId,
        ticketTitle: props.ticket.title,
        ticketCode: props.ticket.referenceCode,
    };

    const getPaymentReceiptData = () => {
        let data = {}
        const ticketGeneralObj = props.ticket.steps.find(item => item.approvalObj).approvalObj;
        if(ticketGeneralObj.paymentReceiptId) {
            getPaymentReceipt(ticketGeneralObj.paymentReceiptId);
        }else {
            data = {...defaultReceipt};
            setPaymentReceiptData({...data});
        }
    }

    const getTicketGeneralData = () => {
        let forms = {};
        props.ticket.steps.find(item => item.step === props.currentStep)
        .requirements.filter(item => item.type === 'form').forEach(item => {
            forms = {...forms, [item.name]: item.value}
        });
        setFormIds(forms);
        getPaymentReceiptData();
    }

    const handleClickExpand = (form, event, isExpanded) => {
        setExpand(isExpanded ? form : false);
    }

    const getPaymentReceipt = (receiptId) => {
        props.setLoading(true);
        const endpoint = fetchPaymentReceipt({token: props.token, uniqueId: receiptId});
        endpoint.then(response => {
            setPaymentReceiptData({...response.data});
            props.setLoading(false);
        }).catch(errors => {
            if(errors?.response?.status === 404) { // in case the payment receipt is deleted.
                setPaymentReceiptData({...defaultReceipt});
            }
            errorHandling(errors, props.handleToggleSimpleDialog);
            props.setLoading(false);
        });
    }

    const getBalanceLetter = () => {
        props.setLoading(true);
        const endpoint = printBalanceLetter({
            token: props.token,
            uniqueId: props.ticket.customer,
            paymentDate: paymentReceiptData.receiptDate,
        });
        endpoint.then(response => {
            const file = new Blob([response.data], { type: "text/html" });
            const fileURL = URL.createObjectURL(file);
            const NewWindow = window.open();
            NewWindow.location.href = fileURL;
            props.setLoading(false);
        }).catch(errors => {
            props.setLoading(false);
            errorHandling(errors, props.handleToggleSimpleDialog);
        });
    }

    const handleTicketHistory = async (steps) => {
        await ticketHistory(steps, props).then(async (response) => {
            const history = response;
            await props.modifyTicketStep(steps, props.ticket, history, undefined);
        });
    }

    const handleSaveForm = (formName, id) => {
        const uniqueId = typeof id === 'string' ? id : id.uniqueId;
        props.ticket.steps.find(item => item.step === props.currentStep)
        .requirements.find(item => item.name === formName).value = uniqueId;

        setFormIds({...formIds, [formName]: uniqueId});
        handleTicketHistory(props.ticket.steps);
    }

    const handleCompleteStep = async () => {
        // for the moment only payment receipt is required, change logic to multiple forms
        // when asked.
        const ticketGeneralObj = props.ticket.steps.find(item => item.approvalObj).approvalObj;
        if(!ticketGeneralObj?.paymentReceiptId) {
            props.handleToggleSimpleDialog('simple-dialog', `El formulario de recibo de pago no está completado.`);
            return;
        }
        await handleNextStep(true, props, props.ticket.steps, null, 'finalStep');
        props.handleToggleSimpleDialog();
    }

    const handlePaymentReceipt = (data) => {
        setPaymentReceiptData({...data});
        const ticketGeneralObj = props.ticket.steps.find(item => item.approvalObj).approvalObj;
        ticketGeneralObj.paymentReceiptId = data.uniqueId;

        const description = 'Se ha modificado el recibo de pago.';
        ticketHistoryGeneral(props, description).then( async (response) => {
            const history = response;
            await props.modifyTicketStep(props.ticket.steps, props.ticket, history, undefined);
        });
    }

    useEffect(() => {
        getTicketGeneralData();
    }, [props.reloadTicket]);

    return(
        <div style={{padding: '16px'}}>
            <Grid container item xs={6} justifyContent="space-between" direction="column" style={{margin: '1rem 0'}}>
                <StepStatus 
                    completed={stepStatus}
                    finalStep={true}
                    handleNextStep={
                        props.handleToggleSimpleDialog
                        .bind(this, 'confirm-dialog', 'proceed-complete-ticket', {callback: handleCompleteStep})
                    }
                    ticketCompleted={props.ticketCompleted}
                    roles={props.roles}
                />
                <Grid container style={{margin: '1rem 0'}}>
                    <Button 
                        variant="contained" 
                        className={"action-method-btn save"}
                        disableElevation
                        size="small"
                        style={{width:''}}
                        onClick={getBalanceLetter}
                    >
                        imprimir carta de saldo
                    </Button> 
                </Grid>
            </Grid>
            <FormWrapper 
                title={"Recibos de pago"}
                name={'paymentReceipt'}
                contentType={'Formulario'}
                ticketCompleted={props.ticketCompleted}
                hideAction={true}
                form={
                    <div style={{marginTop:'1rem'}}>
                        <PaymentReceiptContent
                            handleToggleDialog={props.handleToggleDialog}
                            handleSimpleDialog={props.handleToggleSimpleDialog}
                            modify={true}
                            data={paymentReceiptData}
                            negotiationParams={{ticket: props.ticket.uniqueId}}
                            showAttachmentBtn={true}
                            handlePayment={{onCreate: handlePaymentReceipt, onUpdate: (data) => {setPaymentReceiptData({...data})}}}
                            setLoading={props.setLoading}
                            token={props.token}
                        />
                    </div>
                }
                handleExpand={handleClickExpand}
                expand={expand === 'paymentReceipt'}
            />
            {
                paymentType?.value === 'extraordinary' &&
                <FormWrapper 
                    title={"Pagaré"}
                    name={`promissoryNote`}
                    contentType={'Formulario'}
                    ticketCompleted={props.ticketCompleted}
                    form=
                    {
                        <>
                            {
                                formIds.promissoryNote &&
                                <NotarialPayContractDetail 
                                    notarialPayContractId={formIds.promissoryNote}
                                />
                            }
                            <div style={{display:formIds.promissoryNote?'none':'block'}}>
                                <NotarialPayContractForm
                                    handleSaveCallBack={handleSaveForm.bind(this, 'promissoryNote')}
                                />
                            </div>
                        </>
                    }
                    handleExpand={handleClickExpand}
                    expand={expand === `promissoryNote`}
                />
            }
        </div>
    )
}

function PaymentDetail(props) {
    // const [paymentType, setPaymentType] = useState('');
    // useEffect(() => {
    //     const type = props.ticket.steps.find(item => item.step === 'negotiation_process').requirements
    //     .find(item => item.name === 'paymentType').value;
    //     setPaymentType(type);
    // }, [props.ticket]);

    return(
        <div>
            <TabsStyled
                value={props.currentTab}
                onChange={props.handleTabChange}
                variant="scrollable"
                scrollButtons="on"
                aria-label="ticket-step-tabs"
            >
                <TabStyled label="Proceso de negociación" />
                <TabStyled label="Conciliación de pago" disabled={ props.disabledTabs.length > 0 && props.findStep('payment_reconciliation')} />
                <TabStyled label="Confirmación Final" disabled={ props.disabledTabs.length > 0 && props.findStep('last_confirm')} />
                {/* {
                    paymentType.value === 'extraordinary' &&
                    <TabStyled label="Confirmación Final" disabled={ props.disabledTabs.length > 0 && props.findStep('last_confirm')} />
                } */}
            </TabsStyled>
            {
                props.currentTab === 0 &&
                <NegotiationProcess
                    {...props}
                    currentStep={'negotiation_process'}
                />
            }
            {
                props.currentTab === 1 &&
                <PaymentReconciliation
                    {...props}
                    currentStep={'payment_reconciliation'}
                />
            }
            {/* (props.currentTab === 2 && paymentType.value === 'extraordinary') && */}
            {
                props.currentTab === 2 &&
                <LastConfirm
                    {...props}
                    currentStep={'last_confirm'}
                />
            }
        </div>
    )
}

export { PaymentDetail }
