import React, { useState } from 'react';
import {
    Grid, Typography, IconButton, Button, Switch, FormControlLabel
} from '@material-ui/core';
import { 
    Close, Tune, ClearAll
} from '@material-ui/icons';

import { 
    AutocompleteField, DateField, SearchComponent
} from './../../../components/SharedComponents/Fields';
import { ticketStepsLabels } from '../TicketGeneralMethods';
import { dateFormat } from './../../../components/SharedComponents/CustomHooks';
import green from '@material-ui/core/colors/green';
import { withStyles } from '@material-ui/core/styles';

function FiltersHeader(props) {

    return (
        <Grid container justifyContent='space-between' alignItems="center">

            <Typography
                variant={props.typeVariant}
                className={props.typeClass}
                style={{fontWeight: 'bold'}}
            >
                {props.filtersTitle}
            </Typography>

            <IconButton 
                className={props.btnClass}
                onClick={props.toggleFilter}
                disabled={props.disableClose}
            >
                {
                    props.openFilter ? 
                    <Close
                        className={props.iconClass}     
                    />
                    :
                    <Tune
                        className={props.iconClass}
                    />
                }
                
            </IconButton>
        </Grid>
    )
}

const GreenSwitch = withStyles({
    switchBase: {
      color: green[300],
      '&$checked': {
        color: green[500],
      },
      '&$checked + $track': {
        backgroundColor: green[500],
      },
    },
    checked: {},
    track: {},
})(Switch);

function Filters(props) {
    const [openFilter, setOpenFilter] = useState(true);
    const defaultTicketInfo = {
        priority: [],
        ticketNumber: [],
        participant: [],
        ticketType: [],
        status: [],
        steps: [],
        startDateType: null,
        endDateType: null,
        web_ticket: false,
    };

    const stepsOptions = ticketStepsLabels().map(item => {
        return {
            name: `${item.ticketName} - ${item.name}`,
            value: item.value,
        }
    })

    const [filterInfo, setFilterInfo] = useState(defaultTicketInfo);
    const [date, setDate] = useState({start: null, end: null, startVal: null, endVal: null});
    // const [, setTypedPerson] = useState('');

    const clearFilters = () => {
        setFilterInfo(defaultTicketInfo);
        setDate({start: null, end: null, startVal: null, endVal: null});
    }

    const toggleFilter = () => {
        setOpenFilter(!openFilter);
    }
    const filterFieldHandler = (field, event, val) => {
        if (field==='web_ticket'){
            setFilterInfo({...filterInfo, [field]: !filterInfo.web_ticket});
        }else{
            let value = val ? val : (event && event.target) ? event.target.value : event;
            setFilterInfo({...filterInfo, [field]: value});
        }
    }

    const handleDateChange = (type, dateInfo, value) => {
        setDate({...date, [type]: dateInfo});
    }
    const dateTypes = () => {
        return [
            'Cancelado',
            'Completado',
            'Creado',
            'Vencido',
        ]
    }
    const getFiltersOptions = (option) => {
        return(
            (option ? option : [])
        );
    }

    // const handleTypedPersonChange = (value) => {
    //     setTypedPerson(value);
    // }

    const applyFilters = () => {
        const startType = filterInfo.startDateType || 'Creado';
        const endType = filterInfo.endDateType || 'Creado';
        const ticketNumber = filterInfo.ticketNumber && filterInfo.ticketNumber.name
        const fields = {
            priority: filterInfo.priority.map(item => {return item.value}).join(','),
            referenceCode: ticketNumber?.split('TIC').pop(),
            customer: filterInfo.participant && filterInfo.participant.value,
            requestType: filterInfo.ticketType.map(item => {return item.value}).join(','),
            status: filterInfo.status.map(item => {return item.state}).join(','),
            steps: filterInfo.steps.map(item => {return item.value}).join(','),
            creationDateFrom: dateFormat(date.start),
            creationDateTo: dateFormat(date.end),
            webTicket: filterInfo.web_ticket,
            institution: filterInfo.institution?.value,
        };
        Object.keys(fields).forEach(key => {
            if(!fields[key] || fields[key].length < 1) {
                delete fields[key];
            }
        });
        props.applyFilters(fields);
    }
    return(
        <Grid container className="filters-container">
            <FiltersHeader
                typeVariant="subtitle1"
                typeClass="section-header"
                filtersTitle="Filtros"
                btnClass={("filter-btn-close ") + props.hideClass}
                iconClass="filter-icon-close"
                toggleFilter={toggleFilter}
                openFilter={openFilter}
                disableClose={props.disableCloseBtn}
            />
            <Grid container className={openFilter ? "filter-content" : "filter-content collided"}>
                <Grid item container spacing={3} direction="row">
                    <SearchComponent 
                        xs={3} 
                        containerClass="field-container"
                        fieldLabel="Referencia del ticket"
                        fieldID="filter-field-ticket-number"
                        fieldInnerLabel="Ticket(s)"
                        handleChange={filterFieldHandler.bind(this, 'ticketNumber')}
                        optionType={'tickets'}
                        clearOnBlur={false}
                        limitTags={1}
                    />
                    <SearchComponent 
                        xs={3}
                        containerClass="field-container"
                        fieldLabel="Socios"
                        fieldID="filter-field-participant"
                        fieldInnerLabel="Seleccione..."
                        handleChange={filterFieldHandler.bind(this, 'participant')}
                        optionType={'participants'}
                        clearOnBlur={false}
                        limitTags={1}
                    />
                    <SearchComponent 
                        xs={3}
                        containerClass="field-container"
                        fieldLabel="Empresas"
                        fieldID="filter-field-enterprises"
                        fieldInnerLabel="Seleccione una empresa"
                        handleChange={filterFieldHandler.bind(this, 'institution')}
                        optionType={"institutions"}
                        limitTags={1} 
                    />

                    <AutocompleteField
                        xs={3}
                        containerClass="field-container"
                        fieldClass="fields"
                        typeVariant="subtitle1"
                        typeClass="field-label"
                        fieldLabel="Prioridades"
                        fieldID="filter-field-priority"
                        fieldInnerLabel="Seleccione..."
                        fieldVariant="outlined"
                        value={filterInfo.priority}
                        handleChange={filterFieldHandler.bind(this, 'priority')}
                        options={getFiltersOptions(props.listData.priority)}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) => option.value === value.value}
                        multiple={true}
                        limitTags={1}
                    />
                    <AutocompleteField
                        xs={3}
                        containerClass="field-container"
                        fieldClass="fields"
                        typeVariant="subtitle1"
                        typeClass="field-label"
                        fieldLabel="Tipos"
                        fieldID="filter-field-ticket-types"
                        fieldInnerLabel="Seleccione..."
                        fieldVariant="outlined"
                        value={filterInfo.ticketType}
                        handleChange={filterFieldHandler.bind(this, 'ticketType')}
                        options={getFiltersOptions(props.listData.ticketTypes)}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) => option.value === value.value}
                        multiple={true} 
                        limitTags={1}
                    />
                    <DateField 
                        xs={3}
                        typeVariant="subtitle1"
                        typeClass="field-label"
                        fieldLabel="Fecha inicio"
                        dateFieldID="filter-date-start"
                        fullWidth={true}
                        dateFieldClass="date-field"
                        inputVariant="outlined"
                        dateFieldLabel="Fecha inicio"
                        format="dd/MM/yyyy"
                        value={date.start}
                        handleChange={handleDateChange.bind(this, "start")}
                    />
                    <DateField 
                        xs={3}
                        typeVariant="subtitle1"
                        typeClass="field-label"
                        fieldLabel="Fecha corte"
                        dateFieldID="filter-date-end"
                        fullWidth={true}
                        dateFieldClass="date-field"
                        inputVariant="outlined"
                        dateFieldLabel="Fecha corte"
                        format="dd/MM/yyyy"
                        value={date.end}
                        handleChange={handleDateChange.bind(this, "end")}
                    />
                    <AutocompleteField
                        xs={3}
                        containerClass="field-container"
                        fieldClass="fields"
                        typeVariant="subtitle1"
                        typeClass="field-label"
                        fieldLabel="Estados"
                        fieldID="filter-field-ticket-status"
                        fieldInnerLabel="Seleccione..."
                        fieldVariant="outlined"
                        value={filterInfo.status}
                        handleChange={filterFieldHandler.bind(this, 'status')}
                        options={getFiltersOptions(props.listData.status)}
                        getOptionLabel={(option) => option.fullName}
                        getOptionSelected={(option, value) => option.state === value.state}
                        multiple={true} 
                        limitTags={1}
                    />
                    <AutocompleteField
                        xs={3}
                        containerClass="field-container"
                        fieldClass="fields"
                        typeVariant="subtitle1"
                        typeClass="field-label"
                        fieldLabel="Pasos"
                        fieldID="filter-field-ticket-step-status"
                        fieldInnerLabel="Seleccione..."
                        fieldVariant="outlined"
                        value={filterInfo.steps}
                        handleChange={filterFieldHandler.bind(this, 'steps')}
                        options={stepsOptions}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) => option.value === value.value}
                        multiple={true} 
                        limitTags={1}
                    />
                    <div style={{display: 'flex', alignItems:'center', margin: '2rem 0 0 1rem'}}>
                        <FormControlLabel
                            xs={3}
                            control={
                                <GreenSwitch 
                                    checked={filterInfo.web_ticket}
                                    onChange={filterFieldHandler.bind(this, 'web_ticket')}  
                                />
                            }
                            label="Solicitudes provenientes desde la web"
                        />
                    </div>
                </Grid>
                {/* FILTERS CONTENT APPLY BUTTON */}
                <Grid container direction="row" justifyContent='space-between' style={{marginTop:'35px'}}>

                    <Button
                        disableElevation
                        className="filter-clear-btn"
                        onClick={clearFilters.bind(this, false)}
                    >
                        limpiar
                        <ClearAll style={{marginLeft:8, fontSize: '19px'}}/>
                    </Button>

                    <Button
                        disableElevation
                        className="filter-apply-btn"
                        onClick={applyFilters}
                    >
                        aplicar filtros
                    </Button>

                </Grid>
            </Grid>
        </Grid>
    );

}
export { Filters }
