import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Loading from "../../../components/SharedComponents/LoadingScreen";

import {
    fetchPepsLists,
    updatePepsList,
    deletePepsList,
    uploadPEPs,
    deleteFileAttachment
} from "src/utils/axios";

import {
    Paper,
    Typography,
    IconButton,
    Button,
    Toolbar,
    InputBase
} from "@material-ui/core";

import { green } from "@material-ui/core/colors";
import {
    Visibility,
    AttachFile,
    Delete,
    Add,
    Replay,
} from '@material-ui/icons';

import { GeneralTable } from './../../../components/SharedComponents/Table';
import { AutocompleteField } from "./../../../components/SharedComponents/Fields";
import TableContainer from "@material-ui/core/TableContainer";
import { Filters } from '../Filters'
import { PreviewDialog, ConfirmDialogComponent, FileAttachmentDialog } from '../Dialogs/index'
import { SuccessDialog, ErrorDialog, SimpleConfirmDialog } from '../../../components/SharedComponents/Dialogs';
import { errorHandling } from '../../../components/SharedComponents/CustomHooks';

function TableToolbar(props) {
    return (
        <Toolbar>
            <div style={{
                display: 'flex',
                alignItems: 'center'
            }}>
                <Typography
                    variant="subtitle1"
                    className="section-header"
                    style={{ fontWeight: 'bold', paddingLeft: 16 }}
                >
                    {(props.title || "Peps list")}
                </Typography>
                <Button
                    onClick={props.refresh}
                    variant="contained"
                    style={{ backgroundColor: green[500], color: '#FFFFFF', fontSize: '12px', marginLeft: 10, display: 'inline-block' }}
                    disableElevation
                >
                    <Replay style={{ margin: ' 0 5 -4 -5', fontSize: '16px' }} />
                    Recargar tabla
                </Button>
            </div>
        </Toolbar>
    );
};

function PepsListView(props) {
    const [loading, setLoading] = useState(false);
    const [dialog, setDialog] = useState({ name: '', data: null });
    const [simpleDialog, setSimpleDialog] = useState({ name: '', data: null, callback: null });
    const [file, setFile] = useState(null);
    const [pepData, setPepData] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState({});

    const handleToggleDialog = (dialog, data) => {
        const selectedDialog = dialog ? dialog : '';
        const dialogData = (data && (data.uniqueId || data.id)) ? data : null;
        const obj = { name: selectedDialog, data: dialogData };
        setDialog({ ...obj });
    }
    const handleToggleSimpleDialog = (dialog, msg, data) => {
        const obj = { name: dialog ? dialog : '', msg: msg, data: data };
        setSimpleDialog({ ...obj });
    }

    const deleteFile = (row) => {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center'
            }}>
                <IconButton
                    disabled={row.fileAttachment ? false : true}
                    onClick={handleToggleDialog.bind(this, 'preview', row)}
                >
                    <Visibility style={{
                        fontSize: 22,
                        color: row.fileAttachment ? green[500] : '#616161'
                    }} />
                </IconButton>

                <IconButton
                    disabled={row.fileAttachment ? false : true}
                    onClick={
                        handleToggleSimpleDialog
                            .bind(this, 'confirm-dialog', 'proceed-delete', { callback: deletePepFileAttachment.bind(this, row), row: row })
                    }                >
                    <Delete style={{
                        fontSize: 22,
                        color: row.fileAttachment ? green[500] : '#616161'
                    }} />
                </IconButton>

                <IconButton
                    disabled={row.fileAttachment ? true : false}
                    onClick={handleToggleDialog.bind(this, 'add-file', row)}
                >
                    <Add style={{
                        fontSize: 22,
                        color: row.fileAttachment ? '#616161' : green[500]
                    }} />
                </IconButton>
            </div>
        );
    }

    const removeFromList = (row) => {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center'
            }}>
                <IconButton
                    onClick={
                        handleToggleSimpleDialog
                            .bind(this, 'confirm-dialog', 'delete', { callback: deletePep.bind(this, row), row: row })
                    }                >
                    <Delete style={{
                        fontSize: 22,
                        color: green[500]
                    }} />
                </IconButton>
            </div>
        );
    }

    const handleAutoCompleteChange = (newVal, currRow, row) => {
        const pep = {
            status: currRow.value,
        };

        const endpoint = updatePepsList(props.user.token, row.id, pep);
        endpoint
            .then((response) => {
                getPepsListData(selectedFilters);
                handleToggleSimpleDialog("success-dialog");
                setLoading(false);
            })
            .catch((errors) => {
                setLoading(false);
                errorHandling(errors, handleToggleSimpleDialog);
            });
    };

    const statusSelector = (column, row) => {
        const options = [
            { value: "passed", label:"Aprobado" },
            { value: "rejected", label:"Rechazado" },
            { value: "undefined", label:"Sin definir" },
        ]
        return (
            <div style={{ marginTop: 10 }}>
                <AutocompleteField
                    xs={12}
                    containerClass="field-container"
                    fieldClass="fields"
                    typeVariant="subtitle1"
                    typeClass="field-label"
                    fieldID="filter-field-status"
                    fieldVariant="outlined"
                    value={options.find((x) => x.label === row.status)}
                    handleChange={(e, value) => handleAutoCompleteChange(e, value, row)}
                    options={options}
                    getOptionLabel={(option) => option.label || ""}
                    limitTags={1}
                />
            </div>
        );
    };

    const columns = [
        {
            id: 'fullName',
            label: 'NOMBRE COMPLETO',
            minWidth: 200,
            align: 'center'
        },
        {
            id: 'identificationType',
            label: 'TIPO DE ID',
            minWidth: 160,
            align: 'center'
        },
        {
            id: 'identificationNumber',
            label: 'IDENTIFICACION',
            type: 'number',
            minWidth: 160,
            align: 'center'
        },
        {
            id: "status",
            label: "Estado",
            minWidth: 200,
            align: "center",
            element: statusSelector.bind(this, "status")
        },
        {
            id: 'creationDate',
            label: 'FECHA DE CREACION',
            minWidth: 160,
            align: 'center'
        },
        {
            id: 'employmentLength',
            label: 'Longitud de empleo (años)',
            minWidth: 160,
            align: 'center'
        },
        {
            id: 'position',
            label: 'Posicion o cargo',
            minWidth: 160,
            align: 'center'
        },
        {
            id: 'institution',
            label: 'Institucion',
            minWidth: 160,
            align: 'center'
        },
        {
            id: 'fileAttachment',
            label: 'Archivo adjunto',
            minWidth: 160,
            align: 'center',
            element: deleteFile
        },
        {
            id: 'fileAttachment',
            label: 'Acciones',
            minWidth: 100,
            align: 'center',
            element: removeFromList
        },
    ];

    const getPepsListData = (data) => {
        setLoading(true);
        const endpoint = fetchPepsLists({ ...data, token: props.user.token })
        endpoint.then(response => {
            const pepData = response.data.map(item => {
                return {
                    id: item.uniqueId,
                    fullName: item.fullName,
                    identificationNumber: item.identificationNumber ? item.identificationNumber: '---',
                    identificationType: item.identificationType === "national" ? 'Cedula'
                        : item.identificationType === "passport" ? 'Pasaporte' 
                        : item.identificationType ? item.identificationType: '---',
                    status: item.status === 'passed' ? 'Aprobado' 
                    : item.status === 'rejected' ? 'Rechazado' : "Sin definir",
                    employmentLength: item.employmentLength,
                    creationDate: item.creationDate,
                    fileAttachment: item.fileAttachment,
                    position: item.position,
                    institution: item.institution
                }
            });
            setPepData(pepData)
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
            console.error(err);
        });
    }
    
    const deletePep = (PEP) => {
        setLoading(true);
   
        const endpoint = deletePepsList(props.user.token, PEP.id);
        endpoint.then(() => {
            if (PEP.fileAttachment) {
                handleFileDelete(PEP.fileAttachment)
            }
            getPepsListData(selectedFilters);
            setLoading(false);
            handleToggleSimpleDialog('success-dialog');
        }).catch(errors => {
            setLoading(false);
            errorHandling(errors, handleToggleSimpleDialog);
        });
    }

    const handleFileDelete = (fileId) => {
        const endpoint = deleteFileAttachment(props.user.token, fileId);
        endpoint.then(() => {
            handleToggleSimpleDialog('success-dialog');
        }).catch(errors => {
            setLoading(false);
            errorHandling(errors, handleToggleSimpleDialog);
        });
    }

    const deletePepFileAttachment = (PEP) => {
        setLoading(true);
        const newData = {
            fileAttachment: null
        }
        const endpoint = updatePepsList(props.user.token, PEP.id, newData);
        endpoint.then(() => {
            if (PEP.fileAttachment) {
                handleFileDelete(PEP.fileAttachment)
            }
            getPepsListData(selectedFilters);
            setLoading(false);
            handleToggleSimpleDialog('success-dialog');
        }).catch(errors => {
            setLoading(false);
            errorHandling(errors, handleToggleSimpleDialog);
        });
    }

    const uploadExcel = (data) => {
        setLoading(true);
        let formData = new FormData();
        formData.append("file", data);
        const endpoint = uploadPEPs
        endpoint(props.user.token, formData).then((response) => {
            getPepsListData(selectedFilters);
            setLoading(false);
            if (response.data.warning) {
                handleToggleSimpleDialog('simple-dialog', "El proceso fue ejecutado correctamente, pero: " + response.data.warning);
            } else {
                handleToggleSimpleDialog('success-dialog');
            }
        }).catch(errors => {
            setLoading(false);
            errorHandling(errors, handleToggleSimpleDialog);
        });
    }
    useEffect(() => {
        getPepsListData()
    }, [])

    const applyFilters = (fields) => {
        let data = fields;
        getPepsListData(data);
        setSelectedFilters({ ...data });
    }

    const handleSaveBtn = () => {
        uploadExcel(file)
    }


    return (
        <div style={{ padding: '20ps, 20px,10px 20px' }}>
            <Paper style={{ padding: '20px' }}>
                <Typography variant="h3">Peps list</Typography>
                <Loading open={loading} />
                <br />
                <br />
                <>
                    <Filters
                        applyFilters={applyFilters}
                    />
                    <div style={{ width: '55%', margin: 8 }}>
                        <input
                            type="file"
                            id='file'
                            style={{ display: 'none' }}
                            accept=".csv,.xlsx"
                            onChange={(event) => {
                                const file = event.target.files[0];
                                setFile(file)
                            }}
                        />
                        <div style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                            <div style={{ maxWidth: '65%', display: 'flex' }}>
                                <label htmlFor='file'>
                                    <Button
                                        variant="contained"
                                        style={{ backgroundColor: '#43A047', color: '#FFFFFF', fontSize: '12px', display: 'inline-block' }}
                                        component="span"
                                        disableElevation
                                    >
                                        <AttachFile style={{ margin: ' 0 5 -4 -5', fontSize: '16px' }} />
                                        Elegir
                                    </Button>
                                </label>

                                <InputBase
                                    value={file ? file.name : 'Seleccione un archivo'}
                                    style={{ color: '#616161', paddingLeft: 5, display: 'inline-block', width: '100%' }}
                                />
                                <div style={{ maxWidth: '35%', float: 'right' }}>
                                    <Button
                                        onClick={handleSaveBtn}
                                        variant="contained"
                                        style={{ backgroundColor: file ? '#43A047' : '#bdbdbd', color: '#FFFFFF', fontSize: '12px', marginLeft: 10, display: 'inline-block' }}
                                        disableElevation
                                        disabled={!file}
                                    >
                                        <Add style={{ margin: ' 0 5 -4 -5', fontSize: '16px' }} />
                                        cargar
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div style={{ height: 700, width: '100%', marginTop: 60 }}>
                        <TableContainer style={{ width: '100%' }}>

                            <GeneralTable
                                columns={columns}
                                data={pepData}
                                toolbar={
                                    <TableToolbar
                                        title={"Pep list"}
                                        refresh={getPepsListData}
                                    />
                                }
                            />
                        </TableContainer>
                    </div>
                    {
                        dialog.name === 'preview' ?
                            <PreviewDialog
                                open={true}
                                handleToggleDialog={handleToggleDialog}
                                data={dialog.data}
                                token={props.user.token}
                            /> : null
                    }
                    {
                        dialog.name === 'add-file' ?
                            <FileAttachmentDialog
                                open={true}
                                handleToggleDialog={handleToggleDialog}
                                handleSimpleDialog={handleToggleSimpleDialog}
                                data={dialog.data}
                                token={props.user.token}
                                refresh={getPepsListData}
                            /> : null
                    }
                    {
                        simpleDialog.name === 'confirm-dialog' ?
                            <ConfirmDialogComponent
                                open={true}
                                data={simpleDialog}
                                handleToggle={handleToggleSimpleDialog}
                            /> : null
                    }
                    {
                        simpleDialog.name === 'simple-dialog' ?
                            <SimpleConfirmDialog
                                open={true}
                                handleToggle={handleToggleSimpleDialog}
                                data={simpleDialog.msg}
                            /> : null
                    }
                    {
                        simpleDialog.name === 'success-dialog' ?
                            <SuccessDialog
                                open={true}
                                handleToggle={handleToggleSimpleDialog}
                            /> : null
                    }
                    {
                        simpleDialog.name === 'error-dialog' ?
                            <ErrorDialog
                                open={true}
                                handleToggle={handleToggleSimpleDialog}
                                data={simpleDialog.msg}
                            /> : null
                    }
                </>
            </Paper>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps)(PepsListView)
